import { AnyAction } from "redux"
// import { Company } from 'models/company'

export const ACTION_SHOW_ERROR = "ACTION_SHOW_ERROR"
export const ACTION_GO_BACK = "ACTION_GO_BACK"
export const ACTION_LOAD_APP_DATA = "ACTION_LOAD_APP_DATA"
export const ACTION_LOAD_APP_DATA_SUCCESS = "ACTION_LOAD_APP_DATA_SUCCESS"

export const ACTION_LOAD_ROLES = "ACTION_LOAD_ROLES"
export const ACTION_LOAD_ROLES_SUCCESS = "ACTION_LOAD_ROLES_SUCCESS"

export const actionShowError = (message?: string): AnyAction => ({
    type: ACTION_SHOW_ERROR,
    message,
})

export const actionGoBack = (): AnyAction => ({
    type: ACTION_GO_BACK,
})

export const actionLoadAppData = (): AnyAction => ({
    type: ACTION_LOAD_APP_DATA,
})

export const actionLoadAppDataSuccess = (data: any): AnyAction => ({
    type: ACTION_LOAD_APP_DATA_SUCCESS,
    data,
})

export const actionLoadRoles = (): AnyAction => ({
    type: ACTION_LOAD_ROLES,
})

export const actionLoadRolesSuccess = (roles: string[]): AnyAction => ({
    type: ACTION_LOAD_ROLES_SUCCESS,
    roles,
})
