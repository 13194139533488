import { AnyAction } from 'redux'
import { VatCode } from 'models/vatCode'

export const ACTION_VATCODE_GET = 'ACTION_VATCODE_GET'
export const ACTION_VATCODE_GET_SUCCESS = 'ACTION_VATCODE_GET_SUCCESS'
export const ACTION_LOAD_VATCODES = 'ACTION_LOAD_VATCODES'
export const ACTION_LOAD_VATCODES_SUCCESS = 'ACTION_LOAD_VATCODES_SUCCESS'

export const actionVatCodeGet = (): AnyAction => {
    return {
        type: ACTION_VATCODE_GET,
    }
}

export const actionVatCodeGetSuccess = (vatCode: VatCode): AnyAction => {
    return {
        type: ACTION_VATCODE_GET_SUCCESS,
        vatCode,
    }
}

export const actionLoadVatCodes = (): AnyAction => ({
    type: ACTION_LOAD_VATCODES,
})

export const actionLoadVatCodesSuccess = (vatCodes: VatCode[]): AnyAction => ({
    type: ACTION_LOAD_VATCODES_SUCCESS,
    vatCodes,
})