import { combineEpics } from "redux-observable"
import { Observable } from "rxjs"
import { filter, flatMap, map, withLatestFrom } from "rxjs/operators"
import { AnyAction } from "redux"
import { actionLoadInvoiceFilesSuccess, ActionLoadInvoiceFilesType } from "./invoice-file-table-actions"
import DashboardClient from "clients/dashboard-client"
import { ActionLoadRefundsType, ActionRefundPublishSuccessType } from "../../redux/refund/refund-actions"
import { defaultCatchError } from "../../redux/app/app-epics"

export const loadInvoiceFileEpic = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        filter(
            x =>
                x.type === ActionLoadRefundsType ||
                x.type === ActionRefundPublishSuccessType ||
                x.type === ActionLoadInvoiceFilesType
        ),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                DashboardClient.getInvoiceFiles().pipe(
                    map(invoiceFiles => actionLoadInvoiceFilesSuccess(invoiceFiles)),
                    defaultCatchError()
                )
        )
    )
}

export const invoiceFileEpics = combineEpics(loadInvoiceFileEpic)
