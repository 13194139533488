import * as keys from "./keys"
export const en: { [index: string]: string } = {}

en[keys.TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TITLE] = "Changes made"
en[keys.TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TEXT] =
    "Changes have been made that require invoices to be updated. Changes have been made to"
en[keys.TEXT_UPDATE_INVOICES] = "Update invoices"
en[keys.TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_1] =
    "Changes have been made to {0} products. This means that all invoices must be updated."
en[keys.TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_2] =
    "The process takes approx. 5-10 minutes and will run in the background. Press the 'Update invoices' button when you have finished updating products."
en[keys.TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_1] =
    "Changes have been made. This means that all invoices must be updated."
en[keys.TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_2] =
    "The process takes approx. 5-10 minutes and will run in the background. Press the 'Update invoices' button when you have made all the necessary changes."
en[keys.TEXT_INVOICES_UPDATING] = "Updating invoices..."
