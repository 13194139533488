import React, { FunctionComponent } from "react";
import { DashboardPage } from "pages/dashboard-page/dashboard-page";
import { LoginPage } from "../pages/auth/login-page";
import { NotFoundPage } from "pages/not-found-page/not-found-page";
import { RouteComponentProps } from "@reach/router";
import { includes } from "lodash";
import { Permission } from "common/enums/permission";
import { RedirectPage } from "pages/redirect-page/redirect-page";
import { UnauthorizedPage } from "unauthorized-page/unauthorized-page";
import { ProductPage } from "pages/product-page/product-page";
import { ProductDetailsPage } from "pages/product-page/product-details";
import { ContractPage } from "pages/contract-page/contract-page";
import { ContractDetailsPage } from "pages/contract-page/contract-details";
import { RefundPage } from "pages/refund-page/refund-page";
import { ConfigPage } from "pages/config-page/config-page";
import { PeriodConfigPage } from "pages/config-page/period-config-page";

type ApplicationRouteProps = {
  key: number;
  userTags?: string[];
};

export type ApplicationRoute = (props: ApplicationRouteProps) => JSX.Element;

type ProtectedRouteProps = {
  props: ApplicationRouteProps;
  routePermission: Permission;
  path: string;
  Component: FunctionComponent<RouteComponentProps>;
};

export type UnprotectedRouteProps = {
  props: ApplicationRouteProps;
  path: string;
  Component: FunctionComponent<RouteComponentProps>;
};

const ApplicationRoutes: ApplicationRoute[] = [
  (props) =>
    get404RouteComponent({
      props,
      path: "/404",
      Component: NotFoundPage,
    }),

  (props) =>
    getRouteComponent({
      props,
      path: "/unauthorized",
      Component: UnauthorizedPage,
    }),

  (props) =>
    getRouteComponent({
      props,
      path: "/login",
      Component: LoginPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/",
      routePermission: Permission.ProductBilling,
      Component: DashboardPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/products",
      routePermission: Permission.ProductBilling,
      Component: ProductPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/products/:productId",
      routePermission: Permission.ProductBilling,
      Component: ProductDetailsPage,
    }),

    (props) =>
    getProtectedRouteComponent({
      props,
      path: "/products/add",
      routePermission: Permission.ProductBilling,
      Component: ProductDetailsPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/contracts",
      routePermission: Permission.ProductBilling,
      Component: ContractPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/contracts/:contractId",
      routePermission: Permission.ProductBilling,
      Component: ContractDetailsPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/refunds",
      routePermission: Permission.ProductBilling,
      Component: RefundPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/config",
      routePermission: Permission.ProductBilling,
      Component: ConfigPage,
    }),

  (props) =>
    getProtectedRouteComponent({
      props,
      path: "/period-config/:periodYear",
      routePermission: Permission.ProductBilling,
      Component: PeriodConfigPage,
    })
];

export const getProtectedRouteComponent = (
  props: ProtectedRouteProps
): JSX.Element => {
  const {
    props: { key, userTags },
    routePermission: routeAccessPoint,
    Component,
    path,
  } = props;
  return includes(userTags, routeAccessPoint) ? (
    <Component key={key} path={path} />
  ) : (
    <RedirectPage key={key} path={path} to="/unauthorized" />
  );
};

export const getRouteComponent = (
  props: UnprotectedRouteProps
): JSX.Element => {
  const {
    props: { key },
    path,
    Component,
  } = props;
  return <Component key={key} path={path} />;
};

const get404RouteComponent = (props: UnprotectedRouteProps): JSX.Element => {
  const {
    props: { key },
    path,
    Component,
  } = props;
  return <Component default key={key} path={path} />;
};

export { ApplicationRoutes };
