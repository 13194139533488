import { makeReducer } from "utils"
import { ActionReducer, Reducers } from "shared-components/src/utils/make-reducer"
import {
    ACTION_VATCODE_GET,
    ACTION_VATCODE_GET_SUCCESS,
    ACTION_LOAD_VATCODES,
    ACTION_LOAD_VATCODES_SUCCESS,
} from "./vatCode-actions"
import { VatCode } from "models/vatCode"
import { findIndex } from "lodash"

export type VatCodeState = {
    items: VatCode[]
    filter: string
}

type VatCodeReducer = ActionReducer<VatCodeState>

const getVatCode: VatCodeReducer = state => state

const getVatCodeSuccess: VatCodeReducer = (state, action) => {
    const index = findIndex(state.items, g => g.id === action.vatCode.id)
    index < 0 ? state.items.push(action.vatCode) : (state.items[index] = action.vatCode)
    return state
}

const loadVatCode: VatCodeReducer = state => state

const loadVatCodeSuccess: VatCodeReducer = (state, action) => ({
    ...state,
    items: action.vatCodes,
})

const reducers: Reducers<VatCodeState> = {
    [ACTION_VATCODE_GET]: getVatCode,
    [ACTION_VATCODE_GET_SUCCESS]: getVatCodeSuccess,
    [ACTION_LOAD_VATCODES]: loadVatCode,
    [ACTION_LOAD_VATCODES_SUCCESS]: loadVatCodeSuccess,
}

const initialState = { items: [], filter: "" }

export const vatCodeReducer = makeReducer<VatCodeState>(reducers, initialState)
