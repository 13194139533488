import IconButton from "@material-ui/core/IconButton"
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined"
import { actionLoadVatCodes } from "../../redux/vatCode/vatCode-actions"
import { AddVatDialog } from "../vat/add-vat-dialog"
import Spinner from "../../common/spinner"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { InjectedFormProps, reduxForm, Field, reset, isPristine, isInvalid, isDirty, getFormValues } from "redux-form"
import { Product, ProductPeriodSelection, ProductPerTimeValues, ProductPriceType } from "models/product"
import { CubitTextFieldAdapter } from "shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter"
import { AppState } from "../../redux/app/app-store"
import { tr } from "../../utils/translations/translate"

import {
    makeStyles,
    Theme,
    Grid,
    Button,
    Select,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
    Chip,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    FormGroup,
    FormHelperText,
} from "@material-ui/core"

import {
    TEXT_NAME,
    TEXT_DESCRIPTION,
    TEXT_SAVE,
    TEXT_CANCEL,
    TEXT_PRICE,
    TEXT_MVA,
    TEXT_PRODUCT_NUMBER,
    TEXT_PRODUCT_BILLING_TYPE,
    TEXT_REQUIRED,
    TEXT_VAT_CODE,
    TEXT_ADD_VAT_CODE,
    TEXT_PRODUCT_PERIODIC,
    TEXT_PRODUCT_ACTIVITY_BASED,
    TEXT_VALIDITY,
    TEXT_FROM,
    TEXT_TO,
    TEXT_PRODUCT_INVOICE_PERIODS,
    TEXT_PRODUCT_EACH_PERIOD,
    TEXT_PRODUCT_EACH_MAIN_PERIOD,
    TEXT_TERMIN,
    TEXT_VALIDATION_DATE_FROM_SMALLER,
    TEXT_VALIDATION_DATE_INVALID,
    TEXT_PRODUCT_SELECT_PERIOD,
} from "../../utils/translations/keys"

import {
    EACH_MAIN_PERIOD,
    SELECTED_PERIODS,
    EACH_PERIOD,
    PRODUCT_PERIODIC_BILLING_TYPE,
    PRODUCT_ACTIVITY_BASED_BILLING_TYPE,
} from "./product-constants"
import BillingDatePickerAdapter from "common/datePicker/billingDatePicker"
import {
    TEXT_10_MINUTES,
    TEXT_15_MINUTES,
    TEXT_30_MINUTES,
    TEXT_FIXED_PRICE,
    TEXT_ONE_HOUR,
    TEXT_OTHER_PRICE_TYPE_VALUE,
    TEXT_PER_KM,
    TEXT_PER_OTHER,
    TEXT_PER_TIME,
    TEXT_PRICE_PER_TIME_RESOLUTION,
    TEXT_PRICE_TYPE,
} from "./translations/keys"

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
        },
    },
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        formContainer: {
            padding: "30px",
        },
        buttons: {
            marginTop: theme.spacing(5),
        },
        auditGrid: {
            padding: theme.spacing(1),
            height: "1000px",
            overflow: "auto",
        },
        textField: {
            display: "flex",
            alignItems: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        periodSelection: {},
        dateField: {
            minWidth: 150
        },
        headerAtTop: {
            alignSelf: 'flex-start',
        }
    }
})

const ValidatorRequired = (value: any) => {
    const valueExists = value !== undefined && value !== null && value !== ""
    return valueExists ? undefined : tr(TEXT_REQUIRED)
}

const ValidatorDateValidFrom = (value: any, allValues: any) => {
    const valueExists = value !== undefined && value !== null && value !== ""

    if (!valueExists || !allValues.validTo) return undefined

    return value >= new Date(allValues.validTo) ? tr(TEXT_VALIDATION_DATE_FROM_SMALLER) : undefined
}

const ValidatorIsValidDate = (value: any) => {
    return value.invalid ? tr(TEXT_VALIDATION_DATE_INVALID) : undefined
}

const Form: React.FC<InjectedFormProps<Product>> = props => {
    const { handleSubmit, form, initialValues, array } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const formValues = useSelector((state: AppState) => getFormValues(form)(state)) as Product
    const [isAddVatLoading, setIsAddVatLoading] = useState(false)
    const [addVatDialogOpen, setAddVatDialogOpen] = useState(false)
    const isFormPristine = useSelector(isPristine(form))
    const isFormInvalid = useSelector(isInvalid(form))
    const isFormDirty = useSelector(isDirty(form))
    const [selectedPeriodType, setSelectedPeriodType] = useState(EACH_PERIOD)
    const init: ProductPeriodSelection[] = []
    const [periods, setPeriods] = useState(init)
    const [selectedBillingType, setSelectedBillingType] = useState(PRODUCT_PERIODIC_BILLING_TYPE)
    const vatCodes = useSelector((state: AppState) => state.vatCodes)
    const [selectedPriceType, setSelectedPriceType] = useState("")

    useEffect(() => {
        initPeriods()

        if (initialValues) {
            setSelectedPeriodType(
                initialValues.periodType !== undefined ? initialValues.periodType.toString() : EACH_PERIOD
            )
            setSelectedBillingType(
                initialValues.productBillingType !== undefined
                    ? initialValues.productBillingType.toString()
                    : PRODUCT_PERIODIC_BILLING_TYPE
            )
            setSelectedPriceType(
                initialValues.priceType !== undefined ? initialValues.priceType.toString() : ProductPriceType.FixedPrice
            )
        }

        return () => {}
    }, [])

    const addNewVat = () => {
        setAddVatDialogOpen(true)
    }
    const handleAddVatDialogClose = () => {
        setAddVatDialogOpen(false)
    }
    const handleAddVatOk = async (id: string) => {
        setIsAddVatLoading(true)
        dispatch(actionLoadVatCodes())
        props.change("vatCodeId", id)
        setIsAddVatLoading(false)
        setAddVatDialogOpen(false)
    }

    const getSelectedVat = () => {
        if (!formValues) return ""

        const selected = (vatCodes?.items || []).filter(x => x.id === formValues.vatCodeId)
        return selected.length === 1 ? selected[0].vatCode : ""
    }

    const handleCancelEdit = () => {
        dispatch(reset(form))
    }

    const initPeriods = () => {
        let result: ProductPeriodSelection[] = []
        const periodCount = 12

        for (let index = 1; index <= periodCount; index++) {
            result.push({ label: `${index.toString()} ${tr(TEXT_TERMIN).toLowerCase()}`, value: index })
        }

        setPeriods(result)
    }

    const onPeriodTypeSelect = value => {
        setSelectedPeriodType(value)

        //clear form selected periods when switching period type
        if(value !== SELECTED_PERIODS){
            array.removeAll('selectedPeriods')
        }
    }

    const onBillingTypeChange = value => {
        setSelectedBillingType(value)
    }

    const priceTypes = [
        { name: tr(TEXT_FIXED_PRICE), value: ProductPriceType.FixedPrice },
        { name: tr(TEXT_PER_KM), value: ProductPriceType.PerKm },
        { name: tr(TEXT_PER_TIME), value: ProductPriceType.PerTime },
        { name: tr(TEXT_PER_OTHER), value: ProductPriceType.PerOther },
    ]

    const pricePerTimeValues = [
        { name: tr(TEXT_10_MINUTES), value: ProductPerTimeValues.TenMinutes },
        { name: tr(TEXT_15_MINUTES), value: ProductPerTimeValues.FifteenMinutes },
        { name: tr(TEXT_30_MINUTES), value: ProductPerTimeValues.HalfAnHourMinutes },
        { name: tr(TEXT_ONE_HOUR), value: ProductPerTimeValues.OneHourMinutes },
    ]

    const onPriceTypeChange = (e: any) => {
        console.log(e.target.value)
        setSelectedPriceType(e.target.value)
    }

    const renderPeriodSelection = (field: any) => (
        <Select
            id="periodSelect"
            multiple
            disabled={selectedPeriodType !== SELECTED_PERIODS}
            value={field.input.value}
            onChange={field.input.onChange}
            input={<Input />}
            renderValue={(selected: any) => (
                <div className={styles.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={periods.find(x => x.value === value)?.label} className={styles.chip} />
                    ))}
                </div>
            )}
            MenuProps={MenuProps}
        >
            {periods.map(period => (
                <MenuItem key={period.label} value={period.value}>
                    <Checkbox checked={field.input.value.findIndex((x: number) => period.value === x) > -1} />
                    <ListItemText primary={period.label} />
                </MenuItem>
            ))}
        </Select>
    )

    const renderSelect = ( field: any, items: any, id: string) => (
        <FormControl error={field.meta.error ? true : false} >
          <FormGroup>
                <Select id={id} value={field.input.value} onChange={field.input.onChange} input={<Input />}>
                    {items.map((x, i) => (
                        <MenuItem key={i} value={x.value}>
                            {x.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormGroup>
            {(field.meta.error && <FormHelperText>{tr(TEXT_REQUIRED)}</FormHelperText>)}
        </FormControl>
    )

    const renderBillingTypeRadioGroup = ({ input, ...rest }) => (
        <RadioGroup {...input} {...rest} value={input.value} onChange={(event, value) => input.onChange(value)} />
    )

    return !initialValues ? null : (
        <form onSubmit={handleSubmit} id="productForm">
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_PRODUCT_BILLING_TYPE)}
                </Grid>
                <Grid item xs={7}>
                    <Field
                        name="productBillingType"
                        component={renderBillingTypeRadioGroup}
                        onChange={onBillingTypeChange}
                    >
                        <Grid container spacing={5} alignItems="center">
                            <Grid item>
                                <FormControlLabel
                                    label={tr(TEXT_PRODUCT_PERIODIC)}
                                    control={<Radio />}
                                    value={PRODUCT_PERIODIC_BILLING_TYPE}
                                ></FormControlLabel>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    label={tr(TEXT_PRODUCT_ACTIVITY_BASED)}
                                    control={<Radio />}
                                    value={PRODUCT_ACTIVITY_BASED_BILLING_TYPE}
                                ></FormControlLabel>
                            </Grid>
                        </Grid>
                    </Field>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_NAME)}
                </Grid>
                <Grid item xs={7}>
                    <Field name="name" component={CubitTextFieldAdapter} validate={ValidatorRequired} />
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_DESCRIPTION)}
                </Grid>
                <Grid item xs={7}>
                    <Field name="description" component={CubitTextFieldAdapter} validate={ValidatorRequired} />
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_VALIDITY)}
                </Grid>
                <Grid item xs={1} className="bold">
                    {tr(TEXT_FROM)}
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="validFrom"
                        validate={[ValidatorRequired, ValidatorIsValidDate, ValidatorDateValidFrom]}
                        component={BillingDatePickerAdapter}
                        className={styles.dateField}
                    />
                </Grid>
                <Grid item xs={1} className="bold">
                    {tr(TEXT_TO)}
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="validTo"
                        validate={[ValidatorRequired, ValidatorIsValidDate]}
                        component={BillingDatePickerAdapter}
                        className={styles.dateField}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_MVA)}
                </Grid>
                <Grid item xs={5} className={styles.textField}>
                   <Field name="vatCodeId" Validate={[ValidatorRequired]} 
                    component={field => renderSelect(field, vatCodes.items.map((x) => ({ value: x.id, name: `${x.vatPercentage}%`})), "vatCodeId")} ></Field>
                    <IconButton onClick={addNewVat}>
                        <AddBoxOutlinedIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={3} className={styles.textField}>
                    {tr(TEXT_VAT_CODE)} {getSelectedVat()}
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_PRODUCT_NUMBER)}
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="productId"
                        type="number"
                        parse={(value: any) => (isNaN(parseInt(value, 10)) ? null : parseInt(value, 10))}
                        component={CubitTextFieldAdapter}
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>
            {selectedBillingType === PRODUCT_ACTIVITY_BASED_BILLING_TYPE && (
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={2} className="bold">
                        {tr(TEXT_PRICE_TYPE)}
                    </Grid>
                    <Grid item xs={4} className={styles.textField}>
                        <Field
                            name="priceType"
                            onChange={onPriceTypeChange}
                            component={field => renderSelect(field, priceTypes, "priceType")}
                        />
                    </Grid>
                </Grid>
            )}
            {selectedBillingType === PRODUCT_ACTIVITY_BASED_BILLING_TYPE &&
                selectedPriceType === ProductPriceType.PerTime && (
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={2} className="bold">
                            {tr(TEXT_PRICE_PER_TIME_RESOLUTION)}
                        </Grid>
                        <Grid item xs={4} className={styles.textField}>
                            <Field
                                name="perTimePriceTypeValue"
                                component={field => renderSelect(field, pricePerTimeValues, "perTimePriceTypeValue")}
                            ></Field>
                        </Grid>
                    </Grid>
                )}
            {selectedBillingType === PRODUCT_ACTIVITY_BASED_BILLING_TYPE &&
                selectedPriceType === ProductPriceType.PerOther && (
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={2} className="bold">
                            {tr(TEXT_OTHER_PRICE_TYPE_VALUE)}
                        </Grid>
                        <Grid item xs={4} className={styles.textField}>
                            <Field
                                name="otherPriceTypeValue"
                                component={CubitTextFieldAdapter}
                                validate={ValidatorRequired}
                            />
                        </Grid>
                    </Grid>
                )}
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={2} className="bold">
                    {tr(TEXT_PRICE)}
                </Grid>
                <Grid item xs={4} className={styles.textField}>
                    <Field
                        name="price"
                        type="number"
                        parse={(value: any) => (isNaN(parseFloat(value)) ? null : parseFloat(value))}
                        component={CubitTextFieldAdapter}
                        validate={ValidatorRequired}
                    />
                    &nbsp;kr
                </Grid>
            </Grid>
            {selectedBillingType === PRODUCT_PERIODIC_BILLING_TYPE && (
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={2} className={`${styles.headerAtTop} bold`}>
                        {tr(TEXT_PRODUCT_INVOICE_PERIODS)}
                    </Grid>
                    <Grid item xs={5}>
                        <Field name="periodType" component={renderBillingTypeRadioGroup} onChange={onPeriodTypeSelect}>
                            <Grid container spacing={5} alignItems="center">
                                <Grid item>
                                    <FormControlLabel
                                        label={tr(TEXT_PRODUCT_EACH_PERIOD)}
                                        control={<Radio />}
                                        value={EACH_PERIOD}
                                    ></FormControlLabel>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={tr(TEXT_PRODUCT_EACH_MAIN_PERIOD)}
                                        control={<Radio />}
                                        value={EACH_MAIN_PERIOD}
                                    ></FormControlLabel>
                                </Grid>
                            </Grid>
                        </Field>
                    </Grid>
                </Grid>
            )}
            {selectedBillingType === PRODUCT_PERIODIC_BILLING_TYPE && (
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={2} className="bold"></Grid>
                    <Grid item xs={2}>
                        <Field name="periodType" component={renderBillingTypeRadioGroup} onChange={onPeriodTypeSelect}>
                            <FormControlLabel
                                label={tr(TEXT_PRODUCT_SELECT_PERIOD)}
                                control={<Radio />}
                                value={SELECTED_PERIODS}
                            ></FormControlLabel>
                        </Field>
                    </Grid>
                    <Grid item xs={4}>
                        <Field name="selectedPeriods" component={renderPeriodSelection} type="select-multiple"></Field>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={5} justify="flex-start" className={styles.buttons}>
                <Grid item>
                    <Button onClick={handleCancelEdit} disabled={!isFormDirty}>
                        {tr(TEXT_CANCEL)}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isFormPristine || isFormInvalid}
                    >
                        {tr(TEXT_SAVE)}
                    </Button>
                </Grid>
            </Grid>

            <AddVatDialog
                title={tr(TEXT_ADD_VAT_CODE)}
                open={addVatDialogOpen}
                handleClose={handleAddVatDialogClose}
                handleOk={handleAddVatOk}
                okText={tr(TEXT_ADD_VAT_CODE)}
            >
                {isAddVatLoading && <Spinner />}
            </AddVatDialog>
        </form>
    )
}

const createReduxForm = reduxForm<Product>({
    form: "productForm",
    enableReinitialize: true,
})

export const ProductForm = createReduxForm(Form)
