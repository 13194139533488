export type TenantConfig = {
    isInvoiceProcessingUponImportEnabled: boolean
    invoiceSystem: InvoicingSystemType
    isRefundSingleInvoiceLineEnabled: boolean
    isEditProductsInContractAllowed: boolean
    alwaysRecreateInvoicesForCurrentYearTerms: boolean
    isInvoiceResendEnabled: boolean
    isContractBasedInvoicing: boolean
    isInvoiceUpdateJobEnabled: boolean
    vismaConfiguration: VismaConfigurationEditModel
    isyPaConfiguration: IsyPaConfiguration
    clientNumberConfiguration: ClientNumberConfiguration
    isOldTenant: boolean
    komtekFileConfiguration: KomtekFileConfiguration
    vismaFileConfiguration: VismaFileConfiguration
    agressoFileConfiguration: AgressoFileConfiguration
}

export type KomtekFileConfiguration = {
    fieldConfigurations: KomtekFileFieldConfiguration[]
}

export enum KomtekFieldConfigType {
    Default = "Default",
    SerialNumber = "SerialNumber",
    BuildingNumber = "BuildingNumber",
    CustomValue = "CustomValue",
}

export enum BuildingNumberType {
    Default = "Default",
    Short = "Short",
}

export type KomtekFileFieldConfiguration = {
    fieldName: string
    customValue: string
    fieldConfigType: KomtekFieldConfigType
    defaultOverrideValue: string
    overrideDefault: boolean
}

export type IsyPaConfiguration = {
    serviceUrl: string
    userName: string
    password: string
    applicationId: string
    domain: string
    clientId: number
    productGroup: number
}

export type VismaConfigurationEditModel = {
    serviceUrl: string
    userName: string
    password: string
    companyId: number
    externalSystem: string
    usePeriodicFeeTextFormat: boolean
}

export type ClientNumberConfiguration = {
    defaultNumber: number | null
    clientNumbers: ClientNumberSetting[]
}

export type ClientNumberSetting = {
    periods: number
    number: number
}

export type VismaFileConfiguration = {
    useBuildingNumber: boolean
    buildingNumberType: BuildingNumberType
}

export type AgressoFileConfiguration = {
    responsible: string
    salesman: string
    referenceCodeDr: string
    voucherType: string
    unitCode: string
}

export enum InvoicingSystemType {
    None = 0,
    Visma,
    Agresso,
    IsyPa,
    AzureFileShareRingerike,
    AzureFileShareBergen,
    AzureFileShareIsyPa,
    KomTekFile,
    VismaFile,
    DuettFile,
    //KomTekDiffFile,
}
