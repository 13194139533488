import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Theme,
    Typography,
} from "@material-ui/core"
import { BuildingNumberType, InvoicingSystemType, TenantConfig } from "models/tenant-config"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Field,
    FieldArray,
    getFormValues,
    InjectedFormProps,
    isDirty,
    isInvalid,
    isPristine,
    reduxForm,
} from "redux-form"
import { AppState } from "redux/app/app-store"
import { actionTenantConfigSave } from "../../redux/config/config-action"
import { FormField } from "shared-components/src/cubit-inputs/cubit-form-field.types"
import { InputType } from "shared-components/src/cubit-inputs/input-type.enum"
import { CubitSelectAdapter } from "shared-components/src/cubit-inputs/react-form-adapters/cubit-select-adapter"
import {
    TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS,
    TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS,
    TEXT_CANCEL,
    TEXT_ERP_KOMTEK_FILE,
    TEXT_GENERAL_CONTRACT_CONFIG,
    TEXT_SAVE,
    TEXT_GENRAL_REFUNDS_CONFIG,
    TEXT_SINGLE_INVOICELINE_REFUND_ENABLED,
    TEXT_INVOICE_PROCESSING_UPON_IMPORT,
    TEXT_INVOICES_AND_CONTRACTS_CONFIG,
    TEXT_ERP_RESEND_ENABLED,
    TEXT_INVOICE_SYSTEM_CONFIG,
    TEXT_INVOICE_SYSTEM,
    TEXT_CLIENT_NUMBER_SECTION_NAME,
    TEXT_DEFAULT_CLIENT_NUMBER,
    TEXT_CLIENT_NUMBER,
    TEXT_PERIODCONFIG_TERMS,
    TEXT_TERMIN,
    TEXT_ADD_CLIENT_NUMBER_PERIOD,
    TEXT_SELECT_PERIOD_COUNT,
    TEXT_INVOICE_PROCESSING_UPON_IMPORT_2,
    TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_3,
    TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_2,
    TEXT_OBS,
    TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_2,
    TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_3,
    TEXT_SINGLE_INVOICELINE_REFUND_ENABLED_2,
    TEXT_VISMA_FILE,
    TEXT_DUETT_FILE,
    TEXT_USE_BUILDING_NUMBER,
    TEXT_USE_WHOLE_BUILDING_NUMBER,
    TEXT_USE_SHORT_BUILDING_NUMBER,
    TEXT_AGRESSO_MIDT,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { formatString } from "utils/general-utils"
import { Add } from "@material-ui/icons"
import CreateIcon from "@material-ui/icons/Create"
import Close from "@material-ui/icons/Close"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"
import { submit } from "redux-form"
import { VismaConfigControls } from "./visma-config-controls"
import { IsyPaConfigControls } from "./isyPa-config-controls"
import { KomtekFileUpload } from "./komtek-file-upload"
import { KomtekFieldConfiguration } from "./komtek-field-configuration"
import { AgressoConfigControls } from "./agresso-file-configuration"

const useStyles = makeStyles((theme: Theme) => {
    return {
        paper: {
            padding: "30px",
            marginBottom: "1rem",
        },
        title: {
            marginTop: 0,
            marginBottom: theme.spacing(5),
        },
        buttons: {
            marginTop: theme.spacing(5),
        },
        formContainer: {
            marginBottom: "1rem",
        },
        checkboxDescription: {
            display: "inline-flex",
            alignItems: "center",
        },
        checkbox: {
            padding: "0 10px 0 0",
            alignSelf: "flex-start",
        },
        bottonContainer: {
            display: "flex",
            gap: "20px",
        },
        calendarIcon: {
            color: "#999",
            fontSize: "1.3rem",
        },
        input: {
            display: "none",
        },
        buildingNumberTypeItem:{
            paddingLeft: 35
        }
    }
})

const invoiceSystemTypes = [
    {
        label: tr("TEXT_NONE"),
        value: InvoicingSystemType[InvoicingSystemType.None],
    },
    {
        label: tr("TEXT_ERP_VISMA"),
        value: InvoicingSystemType[InvoicingSystemType.Visma],
    },
    {
        label: tr("TEXT_ERP_ISYPA"),
        value: InvoicingSystemType[InvoicingSystemType.IsyPa],
    },
    {
        label: tr("TEXT_ERP_AZUREFILE_RINGERIKE"),
        value: InvoicingSystemType[InvoicingSystemType.AzureFileShareRingerike],
    },
    {
        label: tr("TEXT_ERP_AZUREFILE_BERGEN"),
        value: InvoicingSystemType[InvoicingSystemType.AzureFileShareBergen],
    },
    {
        label: tr("TEXT_ERP_AZUREFILE_ISYPA"),
        value: InvoicingSystemType[InvoicingSystemType.AzureFileShareIsyPa],
    },
    {
        label: tr(TEXT_ERP_KOMTEK_FILE),
        value: InvoicingSystemType[InvoicingSystemType.KomTekFile],
    },
    {
        label: tr(TEXT_VISMA_FILE),
        value: InvoicingSystemType[InvoicingSystemType.VismaFile],
    },
    {
        label: tr(TEXT_DUETT_FILE),
        value: InvoicingSystemType[InvoicingSystemType.DuettFile],
    },
    {
        label: tr(TEXT_AGRESSO_MIDT),
        value: InvoicingSystemType[InvoicingSystemType.Agresso],
    },
    // {
    //     label: tr(TEXT_KOMTEK_CHANGE_FILE),
    //     value: InvoicingSystemType[InvoicingSystemType.KomTekDiffFile],
    // },
]

const TenantConfigForm: React.FC<InjectedFormProps<TenantConfig>> = props => {
    const getPeriods = () => {
        return [...Array(12)].map((_, index) => {
            return { value: index + 1, periodNumber: index + 1 }
        })
    }

    const styles = useStyles()
    const dispatch = useDispatch()
    const isFormPristine = useSelector(isPristine(props.form))
    const isFormInvalid = useSelector(isInvalid(props.form))
    const isFormDirty = useSelector(isDirty(props.form))
    const formValues = useSelector((state: AppState) => getFormValues(props.form)(state)) as TenantConfig
    const [invoicingSystem, setInvoicingSystem] = useState(
        props.initialValues.invoiceSystem ?? InvoicingSystemType[InvoicingSystemType.None]
    )
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
    const [editMode, setEditMode] = useState(false)

    const invoiceSystemField = (): FormField<InputType.Select> => ({
        type: InputType.Select,
        props: {
            name: "invoiceSystem",
            label: tr(TEXT_INVOICE_SYSTEM),
            valueIsObject: false,
            component: CubitSelectAdapter,
            options: invoiceSystemTypes.map(x => ({ ...x, selected: props.initialValues.invoiceSystem ? 1 : 0 })),
        },
    })

    const renderPeriodSelectionFields = inputData => {
        return (
            <Grid container spacing={4}>
                {inputData.fields.map((clientNumber, index) => (
                    <React.Fragment>
                        <Grid item xs={6}>
                            <Field name={`${clientNumber}.periods`} component={renderPeriodSelection}></Field>
                        </Grid>
                        <Grid item xs={5}>
                            <Field
                                editMode={editMode}
                                name={`${clientNumber}.number`}
                                component={BillingTextFieldAdapter}
                                label={tr(TEXT_CLIENT_NUMBER)}
                                type="number"
                            ></Field>
                        </Grid>
                        <Grid item xs={1}>
                            {editMode && (
                                <IconButton aria-label="delete" onClick={() => inputData.fields.remove(index)}>
                                    <Close />
                                </IconButton>
                            )}
                        </Grid>
                    </React.Fragment>
                ))}
                {editMode && (
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => inputData.fields.push({ number: 0, periods: 1 })}
                            startIcon={<Add />}
                        >
                            {tr(TEXT_ADD_CLIENT_NUMBER_PERIOD)}
                        </Button>
                    </Grid>
                )}
            </Grid>
        )
    }

    const renderPeriodSelection = (field: any) => (
        <>
            {editMode ? (
                <>
                    <InputLabel id="select-period-label">{tr(TEXT_SELECT_PERIOD_COUNT)}</InputLabel>
                    <Select labelId="select-period-label" onChange={field.input.onChange} value={field.input.value}>
                        {getPeriods().map(period => (
                            <MenuItem key={period.value} value={period.value}>
                                <span>
                                    {period.value > 1
                                        ? formatString(tr(TEXT_PERIODCONFIG_TERMS), period.periodNumber)
                                        : `${period.periodNumber.toString()} ${tr(TEXT_TERMIN).toLowerCase()}`}
                                </span>
                            </MenuItem>
                        ))}
                    </Select>
                </>
            ) : (
                <>
                    <label className={`MuiFormLabel-root`}>{tr(TEXT_TERMIN)}</label>
                    <Typography>{field.input.value}</Typography>
                </>
            )}
        </>
    )

    const renderCheckbox = ({ input, label, editMode }) => (
        <div className={styles.checkboxDescription}>
            <Checkbox
                className={styles.checkbox}
                disabled={!editMode}
                checked={input.value ? true : false}
                onChange={input.onChange}
            />
            <div>
                <Typography>
                    <strong>{label}</strong>
                </Typography>
            </div>
        </div>
    )

    const changeSystem = event => {
        setInvoicingSystem(event.target.value)
    }

    const handleSettingsChanged = (e: any, fieldName: string) => {
        if (tenantConfig) {
            const data = { ...tenantConfig }
            data[fieldName] = e.target.checked

            dispatch(actionTenantConfigSave(data as TenantConfig))
        }
    }

    const handleSubmit = () => {
        setEditMode(false)
        dispatch(submit("tenantConfigForm"))
    }

    const handleCancel = () => {
        setEditMode(false)
        props.reset()
        setInvoicingSystem(props.initialValues.invoiceSystem ?? InvoicingSystemType[InvoicingSystemType.None])
    }

    const isVisma = invoicingSystem === InvoicingSystemType[InvoicingSystemType.Visma]
    const isIsyPa = invoicingSystem === InvoicingSystemType[InvoicingSystemType.IsyPa]
    const isKomtek = invoicingSystem === InvoicingSystemType[InvoicingSystemType.KomTekFile]
    const isVismaFile = invoicingSystem === InvoicingSystemType[InvoicingSystemType.VismaFile]
    const isAgressoFile = invoicingSystem === InvoicingSystemType[InvoicingSystemType.Agresso]
    const fieldWidth = 4

    const renderRadioGroup = ({ input, ...rest }) => (
        <RadioGroup {...input} {...rest} value={input.value} onChange={(event, value) => input.onChange(value)} />
    )

    return (
        <div>
            <form onSubmit={props.handleSubmit} className={styles.formContainer}>
                <Paper className={styles.paper}>
                    <h2>{tr(TEXT_INVOICE_SYSTEM_CONFIG)}</h2>
                    <Grid container spacing={5} alignItems="flex-start">
                        <Grid item xs={fieldWidth}>
                            <Field {...invoiceSystemField().props} disabled={!editMode} onChange={changeSystem} />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={1}>
                            {!editMode && (
                                <IconButton aria-label="edit" onClick={() => setEditMode(true)}>
                                    <CreateIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    {isVisma && (
                        <VismaConfigControls
                            fieldWidth={fieldWidth}
                            editMode={editMode}
                            renderCheckbox={renderCheckbox}
                        ></VismaConfigControls>
                    )}
                    {isIsyPa && <IsyPaConfigControls fieldWidth={fieldWidth} editMode={editMode}></IsyPaConfigControls>}

                    {isKomtek && (
                        <KomtekFieldConfiguration
                            fieldWidth={fieldWidth}
                            editMode={editMode}
                            renderRadioGroup={renderRadioGroup}
                            renderCheckbox={renderCheckbox}
                            formValues={formValues}
                        ></KomtekFieldConfiguration>
                    )}

                    {(isKomtek || isVisma) && (
                        <>
                            <h3>{tr(TEXT_CLIENT_NUMBER_SECTION_NAME)}</h3>
                            <Grid container spacing={4} direction="column">
                                <Grid item xs={4}>
                                    <Field
                                        editMode={editMode}
                                        name="clientNumberConfiguration.defaultNumber"
                                        label={tr(TEXT_DEFAULT_CLIENT_NUMBER)}
                                        component={BillingTextFieldAdapter}
                                        type="number"
                                        parse={(value: any) => (isNaN(parseInt(value)) ? null : parseInt(value))}
                                    />
                                </Grid>

                                <Grid item xs={fieldWidth}>
                                    <FieldArray
                                        name="clientNumberConfiguration.clientNumbers"
                                        component={renderPeriodSelectionFields}
                                    ></FieldArray>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {isVismaFile && (
                        <Grid container spacing={4} direction="row">
                            <Grid item xs={fieldWidth}>
                                <Field
                                    editMode={editMode}
                                    name="vismaFileConfiguration.useBuildingNumber"
                                    label={tr(TEXT_USE_BUILDING_NUMBER)}
                                    component={renderCheckbox}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            {formValues.vismaFileConfiguration.useBuildingNumber && (
                                <>
                                    <Grid item xs={6} >
                                        <Field
                                            name="vismaFileConfiguration.buildingNumberType"
                                            component={renderRadioGroup}
                                        >
                                            <FormControlLabel
                                                className={styles.buildingNumberTypeItem}
                                                label={tr(TEXT_USE_WHOLE_BUILDING_NUMBER)}
                                                control={<Radio />}
                                                value={BuildingNumberType.Default}
                                                disabled={!editMode}
                                            ></FormControlLabel>
                                            <FormControlLabel
                                                className={styles.buildingNumberTypeItem}
                                                label={tr(TEXT_USE_SHORT_BUILDING_NUMBER)}
                                                control={<Radio />}
                                                value={BuildingNumberType.Short}
                                                disabled={!editMode}
                                            ></FormControlLabel>
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                </>
                            )}
                        </Grid>
                    )}

                    {isAgressoFile && (
                        <AgressoConfigControls fieldWidth={fieldWidth}
                        editMode={editMode}
                        ></AgressoConfigControls>
                    )}
                    
                    <Grid container>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <div className={styles.bottonContainer}>
                                {editMode && (
                                    <Button onClick={handleCancel} variant="outlined" color="primary">
                                        {tr(TEXT_CANCEL)}
                                    </Button>
                                )}
                                {editMode && (
                                    <Button
                                        onClick={() => handleSubmit()}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isFormPristine || isFormInvalid}
                                    >
                                        {tr(TEXT_SAVE)}
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
            {isKomtek && <KomtekFileUpload styles={styles}></KomtekFileUpload>}
            <Paper className={styles.paper}>
                <h2>{tr(TEXT_INVOICES_AND_CONTRACTS_CONFIG)}</h2>
                <h3>{tr(TEXT_GENERAL_CONTRACT_CONFIG)}</h3>
                <Grid container spacing={5} direction="column">
                    <Grid item>
                        <div className={styles.checkboxDescription}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={tenantConfig?.isInvoiceProcessingUponImportEnabled}
                                onChange={e => handleSettingsChanged(e, "isInvoiceProcessingUponImportEnabled")}
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <div>
                                <Typography>
                                    <strong>{tr(TEXT_INVOICE_PROCESSING_UPON_IMPORT)}</strong>
                                </Typography>
                                <Typography>{tr(TEXT_INVOICE_PROCESSING_UPON_IMPORT_2)}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={styles.checkboxDescription}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={tenantConfig?.isEditProductsInContractAllowed}
                                onChange={e => handleSettingsChanged(e, "isEditProductsInContractAllowed")}
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <div>
                                <Typography>
                                    <strong>{tr(TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS)}</strong>
                                </Typography>
                                <Typography>{tr(TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_2)}</Typography>
                                <Typography>
                                    <strong>{tr(TEXT_OBS)}</strong> {tr(TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_3)}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={styles.checkboxDescription}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={tenantConfig?.alwaysRecreateInvoicesForCurrentYearTerms}
                                onChange={e => handleSettingsChanged(e, "alwaysRecreateInvoicesForCurrentYearTerms")}
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <div>
                                <Typography>
                                    <strong>{tr(TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS)}</strong>
                                </Typography>
                                <Typography>{tr(TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_2)}</Typography>
                                <Typography>
                                    <strong>{tr(TEXT_OBS)}</strong>{" "}
                                    {tr(TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_3)}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={styles.checkboxDescription}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={tenantConfig?.isInvoiceResendEnabled}
                                onChange={e => handleSettingsChanged(e, "isInvoiceResendEnabled")}
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <Typography>
                                <strong>{tr(TEXT_ERP_RESEND_ENABLED)}</strong>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <h3>{tr(TEXT_GENRAL_REFUNDS_CONFIG)}</h3>
                <Grid container spacing={5} alignItems="center">
                    <Grid item>
                        <div className={styles.checkboxDescription}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={tenantConfig?.isRefundSingleInvoiceLineEnabled}
                                onChange={e => handleSettingsChanged(e, "isRefundSingleInvoiceLineEnabled")}
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <div>
                                <Typography>
                                    <strong>{tr(TEXT_SINGLE_INVOICELINE_REFUND_ENABLED)}</strong>
                                </Typography>
                                <Typography>{tr(TEXT_SINGLE_INVOICELINE_REFUND_ENABLED_2)}</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export const TenantConfigBlock = reduxForm<TenantConfig>({
    form: "tenantConfigForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    updateUnregisteredFields: false,
})(TenantConfigForm)
