import { store } from "../index"
import { RequestResult } from "models/request-result"
import { from } from "rxjs"
import superagent, { agent, Response, SuperAgentRequest } from "superagent"
import { getSessionKey } from "utils"
import config from "../config"
import { actionCubitSnackbarShow } from "../redux/cubit-snackbar/cubit-snackbar-actions"
import { getMunicipality } from "utils/municipality/municipalityUtil"

const addHeaders = (agent: SuperAgentRequest) => {
    agent = addSessionHeader(agent)
    agent = addMunicipalityHeader(agent)

    return agent
}

const selectBody = (response: Response) => response.body

const addSessionHeader = (agent: SuperAgentRequest) => {
    const sessionKey = getSessionKey()

    if (sessionKey === undefined) {
        return agent
    }

    return agent.set("Authorization", `Bearer ${sessionKey}`)
}

const addMunicipalityHeader = (agent: superagent.SuperAgentRequest) => {
    const municipality = getMunicipality()

    if (municipality !== undefined && municipality) {
        agent = agent.set("Municipality", municipality)
    }
    return agent
}

function selectRequestResult<T>(response: Response): T {
    var result = response.body as RequestResult<T>
    if (result && !result.success) {
        store.dispatch(
            actionCubitSnackbarShow(
                result.messages.map(x => (x.description ? x.description : x.header)),
                "error"
            )
        )
    }
    return result?.data
}

export function httpGet<T>(url: string) {
    return from(addHeaders(superagent.get(url)).then(response => selectRequestResult<T>(response)))
}

export function httpGetWithoutMunicipalityHeader<T>(url: string) {
    return from(addSessionHeader(superagent.get(url)).then(response => selectRequestResult<T>(response)))
}

export function httpDelete<T>(url: string) {
    return from(addHeaders(superagent.delete(url)).then(response => selectRequestResult<T>(response)))
}

export function httpPost<T>(url: string, data: any) {
    return from(
        addHeaders(superagent.post(url))
            .send(data)
            .then(response => selectRequestResult<T>(response))
    )
}

export function httpPut2<T>(url: string, data: any) {
    return from(
        addHeaders(superagent.put(url))
            .send(data)
            .then(response => selectRequestResult<T>(response))
    )
}

export function httpGetRaw<T>(url: string, data: any) {
    return from(addHeaders(superagent.post(url)).send(data).then(selectBody))
}

export function httpPostRaw<T>(url: string, data: any) {
    return from(addHeaders(superagent.post(url)).send(data).then(selectBody))
}

export const httpPostAndFileResSimple = (url: string, data: any) =>
    addHeaders(superagent.post(url)).responseType("blob").send(data).then(selectBody)

export const httpGetFile = (url: string) => addHeaders(superagent.get(url)).responseType("blob").then(selectBody)

export const httpUpload = (url: string, files: any) => {
    const agent = superagent.post(url)

    files.map((file: any) => agent.attach("documents", file))

    const request = addHeaders(agent).then(selectBody)

    return from(request)
}

export const apiUrl = (url: string): string => config.apiUrl + url
export const lanternUrl = (url: string): string => config.lanternUrl + url
