import React from "react"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import TextField from "@material-ui/core/TextField"
import { InputLabel, Typography } from "@material-ui/core"

export type BillingTextFieldAdapterProps = { type: "textField" }

const BillingTextFieldAdapter: React.FC<any> = (props: any) => {
    const {
        editMode,
        label,
        className,
        input: { name, value, onChange, onBlur, onFocus },
        options,
        formcontrolprops,
        multiline,
        customOnBlur,
        meta: { touched, error },
        ...rest
    } = props

    return (
        <FormControl>
            <FormGroup row>
                {editMode || editMode === undefined ? (
                    <TextField
                        label={label}
                        className={className}
                        multiline={multiline}
                        error={touched && error ? true : false}
                        helperText={touched && error}
                        {...rest}
                        name={name}
                        onChange={(event: any) => onChange(event.target.value)}
                        onFocus={onFocus}
                        onBlur={(e: any) => {
                            if (customOnBlur) {
                                customOnBlur()
                            }
                            onBlur(e)
                        }}
                        value={value}
                    />
                ) : (
                    <div>
                        <label className={`MuiFormLabel-root`}>{label}</label>
                        <Typography>{value}</Typography>
                    </div>
                )}
            </FormGroup>
        </FormControl>
    )
}

export { BillingTextFieldAdapter }
