import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Theme } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import Spinner from 'common/spinner';
import { TEXT_CANCEL } from "../../utils/translations/keys";
import { ValidationResult } from "../../models/validation-result";
import { AddVatForm } from "./vat-add-form";
import { VatCode } from "../../models/vatCode";
import VatCodeClient from "../../clients/vatcode-client";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  tableHead: {
    fontWeight: 600,
    borderBottom: "none",
    paddingLeft: 0
  },
  tableColumn: {
    borderBottom: "none",
    paddingLeft: 0
  },
  dialogTitle: {
    paddingBottom: "0"
  },
  titleCase: {
    textTransform: "capitalize"
  },
  invoiceReceiverSelect: {
    display: "inline-block",
    width: "300px",
    verticalAlign: "middle"
  },
  infoField: {
    lineHeight: "32px",
    verticalAlign: "middle",
    display: "inline-block",
    width: "128px"
  },
  addNewProduct: {
    width: "400px",
    borderRadius: 0
  },
  alignRight: {
    textAlign: "right"
  }
}))

type AddVatDialogProps = {
  title: string,
  okText: string,
  open: boolean,
  handleOk: (string) => void,
  handleClose: () => void
}
export const AddVatDialog: React.FC<AddVatDialogProps> = props => {
  const { title, open, handleClose, handleOk, okText } = props;

  const styles = useStyles();

  const [ vat, setVat ] = useState<VatCode>({} as VatCode);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ validationResult, setValidationResult ] = useState({} as ValidationResult);

  const updateVat = (updatedVat: VatCode) => {
    setVat(updatedVat);
  }

  const cancel = () => {
    handleClose();
    setVat({} as VatCode);
    setValidationResult({} as ValidationResult);
  }

  const addNewVatOk = async () => {
    setIsLoading(true);
    const saveResponse = await VatCodeClient.addVatCode(vat).toPromise();
    setIsLoading(false);
    const hasMessages = (saveResponse.validationMessages || []).length > 0;
    const isValid = !hasMessages && !!saveResponse.vat?.id;
    if (isValid) {
      handleOk(saveResponse.vat.id);
      return;
    }
    const messages = hasMessages ? saveResponse.validationMessages : [];
    const validationResult = {
      messages: messages
    } as ValidationResult;
    setValidationResult(validationResult);
  }

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle className={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent style={{ width: '20pc', minHeight: '15pc' }}>
        {isLoading && <Spinner />}
        {!isLoading &&
          <>
            <AddVatForm validationResult={validationResult} vat={vat} onVatUpdated={updateVat}></AddVatForm>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">{tr("TEXT_CANCEL")}</Button>
        <Button onClick={addNewVatOk} variant="contained" color="primary" autoFocus>{okText}</Button>
      </DialogActions>
    </Dialog>
  )
}
