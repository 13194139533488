import React from 'react'

import { LoginPage } from '../pages/auth/login-page'
import { ApplicationRoute } from './app-routes'

const ApplicationRoutesPublic: ApplicationRoute[] = [
    props => <LoginPage default key={props.key} path="/login" />,
]

export { ApplicationRoutesPublic }
