import * as keys from "./keys"
export const nb: { [index: string]: string } = {}

nb[keys.TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TITLE] = "Endringer utført"
nb[keys.TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TEXT] =
    "Det har blitt utført endringer som krever at fakturaer må oppdateres. Endringer har blitt utført på"
nb[keys.TEXT_UPDATE_INVOICES] = "Oppdater fakturaer"

nb[keys.TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_1] =
    "Det har blitt gjort endringer på {0} produkter. Det betyr at alle fakturaer må oppdateres."
nb[keys.TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_2] =
    "Prosessen tar ca. 5-10 minutter og vil kjøre i bakgrunnen. Trykk på knappen 'Oppdater fakturaer' når du er ferdig med å oppdatere produkter."

nb[keys.TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_1] =
    "Det har blitt gjort endringer. Det betyr at alle fakturaer må oppdateres."
nb[keys.TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_2] =
    "Prosessen tar ca. 5-10 minutter og vil kjøre i bakgrunnen. Trykk på knappen 'Oppdater fakturaer' når du har gjort alle nødvendige endringer."
nb[keys.TEXT_INVOICES_UPDATING] = "Oppdaterer faktura..."
