import { InvoiceStatistic } from "models/invoice-statistic"
import { AnyAction } from "redux"

export const ACTION_LOAD_INVOICE_STATISTICS = "ACTION_LOAD_INVOICE_STATISTICS"
export const ACTION_LOAD_INVOICE_STATISTICS_SUCCESS = "ACTION_LOAD_INVOICE_STATISTICS_SUCCESS"

export const actionLoadInvoiceStatisticsSuccess = (statistics: InvoiceStatistic): AnyAction => ({
    type: ACTION_LOAD_INVOICE_STATISTICS_SUCCESS,
    statistics,
})

export const actionLoadInvoiceStatistics = (): AnyAction => ({
    type: ACTION_LOAD_INVOICE_STATISTICS,
})
