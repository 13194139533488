import React from 'react'

import { AppState } from '../../redux/app/app-store'
import { useSelector } from 'react-redux'
import { CubitSnackbarWrapper } from './cubit-snackbar-wrapper'

export const CubitSnackbar: React.FC = () => {
    const snackbar = useSelector((state: AppState) => state.snackBar)

    return <CubitSnackbarWrapper snackbar={snackbar}></CubitSnackbarWrapper>
}
