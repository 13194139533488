import React, { useEffect, useState } from "react"
import { tr } from "../../utils/translations/translate"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../redux/app/app-store"
import {
    TEXT_GNR_BNR,
    TEXT_SERIALNUMBER,
    TEXT_INVOICE_RECEIVER,
    TEXT_OWNER,
    TEXT_COUNT_UTILITY_UNITS,
    TEXT_CONTRACTTYPE,
    TEXT_LAST_INVOICING,
    TEXT_CONTRACT_USE_CUSTOM_TERM,
    TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP,
    TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR,
} from "../../utils/translations/keys"
import {
    makeStyles,
    Theme,
    Grid,
    Paper,
    FormControlLabel,
    Typography,
    Tooltip,
    TextField,
    IconButton,
} from "@material-ui/core"
import { Contract } from "models/contract"
import { getContractMatrikkelNumberLong } from "utils/contract-utils"
import { Address } from "models/contract"
import { toShortDate } from "utils/time/timeUtil"
import Switch from "@material-ui/core/Switch"
import { ProductPeriodSelectionComponent } from "pages/product-page/product-period-selection"
import HelpIcon from "@material-ui/icons/Help"
import CreateIcon from "@material-ui/icons/Create"
import DoneIcon from "@material-ui/icons/Done"
import ClearIcon from "@material-ui/icons/Clear"
import ContractClient from "clients/contract-client"

const useStyles = makeStyles((theme: Theme) => {
    return {
        headerWrapper: {
            padding: "18px 31px",
            justifyContent: "space-between",
        },
        formContainer: {
            padding: "30px",
        },
        titleCase: {
            textTransform: "capitalize",
            overflowWrap: "break-word"
        },
        title: {
            fontWeight: "bold",
        },
        customTermsHeaderWrapper: {
            padding: "0px 31px",
            justifyContent: "space-between",
        },
        helpIcon: {
            color: "rgba(0, 0, 0, 0.54)",
        },
        customTermsControlWrapper: {
            alignItems: "center",
            display: "flex",
        },
        serialNumberEdit: {
            width: 120,
            display: "flex",
            alignItems: "center",
        },
        editIcon: {
            padding: "0",
            marginLeft: "12px",
        },
        doneIcon: {
            padding: "0",
        },
        errorText: {
            color: "#f44336",
            margin: 0,
            fontSize: "0.75rem",
            marginTop: "8px",
            minHeight: "1em",
            textAlign: "left",
            fontWeight: 400,
            lineHeight: "1em",
            letterSpacing: "0.03333em",
        },
    }
})

export const ContractMainInfo: React.FC<{
    handleUseCustomTermChanged: any
    handleSelectedPeriodTypeChanged: any
    handleSelectedPeriodsChanged: any
    handleSerialNumberChanged: any
}> = ({
    handleUseCustomTermChanged,
    handleSelectedPeriodTypeChanged,
    handleSelectedPeriodsChanged,
    handleSerialNumberChanged,
}) => {
    const styles = useStyles()
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
    const contract = useSelector((state: AppState) => state.contracts.selectedContract)
    const [serialNumberEditModeOn, setSerialNumberEditModeOn] = useState(false)
    const [serialNumber, setSerialNumber] = useState(contract.serialNumber)
    const [isSerialNumberError, setIsSerialNumberError] = useState(false)

    useEffect(() => {
        setSerialNumber(contract.serialNumber)
    }, [contract])

    const renderField = (fieldName: string, fieldValue: any, className?: string, index: number = 0) => {
        return (
            <Grid item md={4} key={index}>
                <div>
                    <b>{tr(fieldName) && tr(fieldName).length > 0 ? tr(fieldName) : fieldName}</b>
                </div>
                <div className={className}>{fieldValue}</div>
            </Grid>
        )
    }

    const getAddressFormatted = (emptyPlaceholder: string, address?: Address) => {
        var addressStr = address
            ? `${address?.streetAddress ? `${address?.streetAddress}, ` : ""}${address?.postalCode ?? "-"} ${
                  address?.city ?? ""
              }`
            : emptyPlaceholder
        return addressStr
    }

    const onHandleUseCustomTermChanged = (useCustomTerms: boolean) => {
        handleUseCustomTermChanged(useCustomTerms)
    }

    const selectedPeriodTypeChanged = (periodType: string) => {
        handleSelectedPeriodTypeChanged(periodType)
    }

    const selectedPeriodsValueOnChange = (selectedPeriods: number[]) => {
        handleSelectedPeriodsChanged(selectedPeriods)
    }

    const serialNumberChanged = e => {
        setSerialNumber(e.target.value.replace(/(\D|^[0])/g, ""))
    }

    const doneChangingSerialNumber = async e => {
        if (!serialNumber) {
            return
        }

        var result = await ContractClient.updateSerialNumber(contract.id, serialNumber).toPromise()

        if (result.contractExists) {
            setIsSerialNumberError(true)
            return
        }

        handleSerialNumberChanged(serialNumber)
        setSerialNumberEditModeOn(false)
        setIsSerialNumberError(false)
    }

    const resetSerialNumberChange = e => {
        setSerialNumber(contract.serialNumber)
        setIsSerialNumberError(false)
        setSerialNumberEditModeOn(false)
    }

    return (
        <Paper className={styles.formContainer}>
            <Grid container className={styles.headerWrapper} spacing={2}>
                {renderField(TEXT_GNR_BNR, `${getContractMatrikkelNumberLong(contract)}`, "", 0)}
                {
                    <Grid item md={4} key={1}>
                        <div>
                            <b>{tr(TEXT_SERIALNUMBER)}</b>
                            {!serialNumberEditModeOn && (
                                <IconButton className={styles.editIcon} onClick={e => setSerialNumberEditModeOn(true)}>
                                    <CreateIcon></CreateIcon>
                                </IconButton>
                            )}
                        </div>
                        <div>
                            {serialNumberEditModeOn ? (
                                <div>
                                    <div className={styles.serialNumberEdit}>
                                        <TextField
                                            id="serialNumber"
                                            inputProps={{ maxLength: 5 }}
                                            value={serialNumber}
                                            onChange={e => serialNumberChanged(e)}
                                            error={isSerialNumberError}
                                        ></TextField>
                                        <IconButton className={styles.doneIcon} onClick={doneChangingSerialNumber}>
                                            <DoneIcon></DoneIcon>
                                        </IconButton>
                                        <IconButton className={styles.doneIcon} onClick={resetSerialNumberChange}>
                                            <ClearIcon></ClearIcon>
                                        </IconButton>
                                    </div>
                                    {isSerialNumberError && (
                                        <Typography className={styles.errorText}>
                                            {tr(TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR)}
                                        </Typography>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <span>{contract.serialNumber}</span>
                                </div>
                            )}
                        </div>
                    </Grid>
                }

                {contract.type &&
                    contract.type.length > 0 &&
                    renderField(TEXT_CONTRACTTYPE, `${contract.type ?? "-"}`, "", 2)}
                {!contract.isProductBasedContract &&
                    renderField(
                        TEXT_COUNT_UTILITY_UNITS,
                        contract.residentialInfo ? contract.residentialInfo.length : 0,
                        "",
                        3
                    )}
                {renderField(
                    TEXT_OWNER,
                    `${contract.ownerFullName?.toLowerCase() || ""} - ${getAddressFormatted(
                        "",
                        contract.ownerAddress
                    )}`,
                    styles.titleCase,
                    4
                )}
                {renderField(
                    TEXT_INVOICE_RECEIVER,
                    `${
                        (contract.invoiceRecipientFullName ?? contract.ownerFullName)?.toLowerCase() || ""
                    } - ${getAddressFormatted("", contract.invoiceRecipientAddress ?? contract.ownerAddress)}`,
                    styles.titleCase,
                    5
                )}
                {tenantConfig?.isContractBasedInvoicing &&
                    renderField(
                        TEXT_LAST_INVOICING,
                        `${toShortDate(contract.lastSyncedDate) || "-"}`,
                        styles.titleCase,
                        6
                    )}
                {Object.entries(contract.metaData).length > 0 &&
                    Object.entries(contract.metaData).map(([key, value], index) => (
                        <>{renderField(key, value, styles.titleCase, index)}</>
                    ))}
                {!Object.entries(contract.metaData).length && renderField("", "", "", 7)}
                {[...Array((Object.entries(contract.metaData).keys.length + 1) % 3)].map((x, i) =>
                    renderField("", "", "", i)
                )}
            </Grid>

            {!tenantConfig?.isOldTenant && (
                <Grid container className={styles.customTermsHeaderWrapper} spacing={2}>
                    <Grid item md={12}>
                        <div className={styles.customTermsControlWrapper}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={contract.useCustomTerms}
                                        onChange={event => onHandleUseCustomTermChanged(event.target.checked)}
                                        name="useCustomTermSwitch"
                                    />
                                }
                                label={
                                    <Typography className={styles.title}>
                                        {tr(TEXT_CONTRACT_USE_CUSTOM_TERM)}
                                    </Typography>
                                }
                            />
                            <Tooltip title={tr(TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP)} placement="right-start">
                                <HelpIcon className={styles.helpIcon}></HelpIcon>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            )}
            {contract.useCustomTerms && (
                <ProductPeriodSelectionComponent
                    selectedPeriodsValue={contract.selectedPeriods}
                    periodType={contract.periodType}
                    selectedPeriodsValueOnChange={selectedPeriodsValueOnChange}
                    selectedPeriodTypeChanged={selectedPeriodTypeChanged}
                ></ProductPeriodSelectionComponent>
            )}
        </Paper>
    )
}
