import { AnyAction } from "redux";
import { InvoiceFile } from "models/invoice-file";

export const ActionLoadInvoiceFilesType = "ACTION_LOAD_INVOICE_FILES";
export const ActionLoadInvoiceFilesSuccessType = "ACTION_LOAD_INVOICE_FILES_SUCCESS";

export const actionLoadInvoiceFiles = (): AnyAction => {
    return {
        type: ActionLoadInvoiceFilesType,
    }
};

export const actionLoadInvoiceFilesSuccess = (files: InvoiceFile[]): AnyAction => {
    return {
        type: ActionLoadInvoiceFilesSuccessType,
        files: files
    }
};