import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import LayoutDefault from "common/layout/layout-default"
import TitleToolbar from "common/title-toolbar/title-toolbar"
import Navigation from "common/navigation/navigation"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_PRODUCT_DETAILS,
} from "../../utils/translations/keys"
import { RouteComponentProps } from "@reach/router"
import { AppState } from "../../redux/app/app-store"
import { Product } from "models/product"
import { makeStyles, Theme, Paper, Grid } from "@material-ui/core"
import { actionLoadProducts, actionNavigateToProduct, actionProductAdd, actionProductAddSuccess, actionProductUpdate, actionProductUpdateSuccess } from "../../redux/product/product-actions"
import { ProductHistory } from "./product-history"
import { ProductAudit } from "./product-audit"
import { ProductForm } from "./product-form"
import { FormErrors, SubmissionError } from "redux-form"
import ProductClient from "clients/product-client"
import mapProduct from "utils/product/map-product"
import { NewProductCreateResponse } from "models/new-product-create-response"
import { actionCubitSnackbarShow } from "../../redux/cubit-snackbar/cubit-snackbar-actions"
import { TEXT_CHANGES_SAVED_SUCCESSFULLY } from "./translations/keys"

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        formContainer: {
            padding: "30px",
        },
        buttons: {
            marginTop: theme.spacing(5),
        },
        auditGrid: {
            padding: theme.spacing(1),
            height: "1000px",
            overflow: "auto",
        },
        priceField: {
            display: "flex",
            alignItems: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }
})

const initialProduct: Partial<Product> = {
    name: "",
    description: "",
    price: 0,
    validFrom: "",
    validTo: "",
    vatCode: "",
    vatPercentage: 0,
    status: { label: "", value: 0 },
    productId: 0,
    vatCodeId: "",
    productBillingType: "1",
    id: "",
    periodType: "1",
    selectedPeriods: [],
}

export const ProductDetailsPage: React.FC<
    RouteComponentProps<{
        productId: string
        location: any
    }>
> = props => {
    const { productId } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const product = useSelector((state: AppState) => state.products.items.find(el => el.id === productId))

    useEffect(() => {
        dispatch(actionLoadProducts())
        
        return () => {}
    }, [])

    const submitForm = async (data: any) => {
        
        if (data.id === "") {
            const response = await ProductClient.addProduct(data).toPromise()
            throwErrorIfAny(response)
            dispatch(actionProductAddSuccess(mapProduct(response.product)))
            dispatch(actionNavigateToProduct(response.product.id));
            dispatch(actionCubitSnackbarShow([tr(TEXT_CHANGES_SAVED_SUCCESSFULLY)],"success"))
        } else {

            const response = await ProductClient.updateProduct(data.id, { ...data, needsInvoicesUpdate: true}).toPromise()
            throwErrorIfAny(response)
            dispatch(actionProductUpdateSuccess(mapProduct(response.product)))
            dispatch(actionCubitSnackbarShow([tr(TEXT_CHANGES_SAVED_SUCCESSFULLY)],"success"))
        }

        function throwErrorIfAny(response: NewProductCreateResponse) {
            if (response.validationMessages && response.validationMessages.length) {

                let errors = {}

                response.validationMessages.forEach(x => {
                    errors[x.fieldId] = tr(x.translationCode)
                })
                throw new SubmissionError(errors)
            }
        }
    }

    return (
        <LayoutDefault
            toolbar={<TitleToolbar title={tr(TEXT_PRODUCT_DETAILS)}></TitleToolbar>}
            navigation={<Navigation />}
        >
            {
                <Grid container className={styles.root} spacing={2}>
                    <Grid item xs={7}>
                        <Paper className={styles.formContainer}>
                            <ProductForm
                                initialValues={productId === undefined ? initialProduct : product}
                                onSubmit={submitForm}
                            />
                        </Paper>
                        {productId && <ProductHistory productId={productId!} />}
                    </Grid>
                    <Grid className={styles.auditGrid}>{productId && <ProductAudit productId={productId!} />}</Grid>
                </Grid>
            }
        </LayoutDefault>
    )
}


