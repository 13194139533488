import {
    Grid,
} from "@material-ui/core"
import React from "react"
import { Field } from "redux-form"
import {
    TEXT_SERVICE_URL,
    TEXT_USER_NAME,
    TEXT_USER_PASSWORD,
    TEXT_COMPANY_ID,
    TEXT_EXTERNAL_SYSTEM,
    TEXT_USE_PERIODIC_FEE_TEXT_FORMAT
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"

export const VismaConfigControls = props => {
const { fieldWidth, editMode, renderCheckbox } = props

return <Grid container spacing={4} direction="row">
                            <Grid item xs={fieldWidth}>
                                <Field
                                    editMode={editMode}
                                    name="vismaConfiguration.serviceUrl"
                                    component={BillingTextFieldAdapter}
                                    label={tr(TEXT_SERVICE_URL)}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={4}>
                                <Field
                                    editMode={editMode}
                                    name="vismaConfiguration.userName"
                                    component={BillingTextFieldAdapter}
                                    label={tr(TEXT_USER_NAME)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {editMode && (
                                    <Field
                                        name="vismaConfiguration.password"
                                        component={BillingTextFieldAdapter}
                                        label={tr(TEXT_USER_PASSWORD)}
                                        type="password"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={fieldWidth}>
                                <Field
                                    editMode={editMode}
                                    name="vismaConfiguration.companyId"
                                    component={BillingTextFieldAdapter}
                                    label={tr(TEXT_COMPANY_ID)}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={fieldWidth}>
                                <Field
                                    editMode={editMode}
                                    name="vismaConfiguration.externalSystem"
                                    component={BillingTextFieldAdapter}
                                    label={tr(TEXT_EXTERNAL_SYSTEM)}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={fieldWidth}>
                                <Field
                                    editMode={editMode}
                                    name="vismaConfiguration.usePeriodicFeeTextFormat"
                                    component={renderCheckbox}
                                    label={tr(TEXT_USE_PERIODIC_FEE_TEXT_FORMAT)}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                        </Grid>

}
