import { AnyAction } from 'redux'
import { LoginRequest } from '../../models/auth-types'

export const ACTION_AUTH_INIT = 'ACTION_AUTH_INIT'
export const ACTION_AUTH_INITIALIZED = 'ACTION_AUTH_INITIALIZED'
export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN'
export const ACTION_AUTH_LOGIN_SUCCESS = 'ACTION_AUTH_LOGIN_SUCCESS'
export const ACTION_AUTH_LOGIN_ERROR = 'ACTION_AUTH_LOGIN_ERROR'
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT'

export const actionAuthInit = (): AnyAction => ({
    type: ACTION_AUTH_INIT,
})

export const actionAuthInitialized = (): AnyAction => ({
    type: ACTION_AUTH_INITIALIZED,
})

export const actionAuthLogin = (request: LoginRequest): AnyAction => ({
    type: ACTION_AUTH_LOGIN,
    request,
})

export const actionAuthLoginSuccess = (data: any): AnyAction => ({
    type: ACTION_AUTH_LOGIN_SUCCESS,
    data,
})

export const actionAuthLoginError = (): AnyAction => ({
    type: ACTION_AUTH_LOGIN_ERROR,
})

export const actionAuthLogout = (): AnyAction => ({
    type: ACTION_AUTH_LOGOUT,
})
