import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { tr } from 'utils/translations/translate'
import { TEXT_CANCEL } from 'utils/translations/keys'

type ContractDialogProps = {
    title: string
    open: boolean
    okText: string
    handleClose: () => void
    handleOk: () => void
    showOk?: boolean
}
export const ContractDialog: React.FC<ContractDialogProps> = props => {
    const { title, open, handleClose, handleOk, okText, showOk=true } = props
    return (
        // <Dialog onClose={handleClose} open={open}>
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ width: '480px' }}>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                    {tr(TEXT_CANCEL)}
                </Button>
                {showOk && <Button onClick={handleOk} color="primary" autoFocus>
                    {okText}
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
