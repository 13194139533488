import { useSelector } from 'react-redux'
import { AuthState } from './auth-reducer'

type StateWithAuth = {
    auth: AuthState
}

export const useAuth = () => useSelector((state: StateWithAuth) => state.auth)

export const useUser = () => useSelector((state: StateWithAuth) => state.auth.user)

export const useUserId = () => useSelector((state: StateWithAuth) => (state.auth.user ? state.auth.user.id : ''))
