import { PeriodConfig } from "models/period-config"
import { TenantConfig } from "models/tenant-config"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import {
    ACTION_PERIODCONFIGURATIONS_GET,
    ACTION_PERIODCONFIGURATIONS_GET_SUCCESS,
    ACTION_TENANTCONFIG_GET,
    ACTION_TENANTCONFIG_GET_SUCCESS,
    ACTION_TENANTCONFIG_SAVE,
    ACTION_TENANTCONFIG_SAVE_SUCCESS,
} from "./config-action"

export type ConfigState = {
    tenantConfig?: TenantConfig
    periodConfigurations?: PeriodConfig[]
}

type ConfigReducer = ActionReducer<ConfigState>

const getTenantConfig: ConfigReducer = state => state

const getTenantConfigSuccess: ConfigReducer = (state, action) => {
    return { ...state, tenantConfig: action.tenantConfig }
}

const saveTenantConfig: ConfigReducer = state => state

const saveTenantConfigSuccess: ConfigReducer = (state, action) => {
    return { ...state, tenantConfig: action.tenantConfig }
}

const getPeriodConfigs: ConfigReducer = state => state

const getPeriodConfigsSuccess: ConfigReducer = (state, action) => {
    return { ...state, periodConfigurations: action.periodConfigs }
}

const reducers: Reducers<ConfigState> = {
    [ACTION_TENANTCONFIG_GET]: getTenantConfig,
    [ACTION_TENANTCONFIG_GET_SUCCESS]: getTenantConfigSuccess,
    [ACTION_TENANTCONFIG_SAVE]: saveTenantConfig,
    [ACTION_TENANTCONFIG_SAVE_SUCCESS]: saveTenantConfigSuccess,
    [ACTION_PERIODCONFIGURATIONS_GET]: getPeriodConfigs,
    [ACTION_PERIODCONFIGURATIONS_GET_SUCCESS]: getPeriodConfigsSuccess,
}

const initialState = {}

export const configReducer = makeReducer<ConfigState>(reducers, initialState)
