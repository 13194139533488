import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Theme, Select, MenuItem, FormControl, InputLabel, NativeSelect, StepIconClasskey, TextField } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { tr } from 'utils/translations/translate'
import { InvoiceLine } from 'models/invoice';
import { Contract } from 'models/contract';
import Spinner from 'common/spinner';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/app/app-store';
import RefundClient from 'clients/refund-client';
import { DateTime } from 'luxon';
import { Product } from 'models/product';
import InvoiceClient from 'clients/invoice-client';
import { toShortDate } from 'utils/time/timeUtil';
import { InvoiceCreatePeriod } from 'models/invoice-create-period';
import { CreateInvoiceLine } from 'models/create-invoice';
import classNames from 'classnames';
import { LibraryAddOutlined } from '@material-ui/icons';
import { getAddressFormatted } from './contract-helper';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
  tableHead: {
    fontWeight: 600,
    borderBottom: "none",
    paddingLeft: 0
  },
  tableColumn: {
    borderBottom: "none",
    paddingLeft: 0
  },
  dialogTitle: {
    paddingBottom: "0"
  },
  titleCase: {
    textTransform: "capitalize"
  },
  invoiceReceiverSelect: {
    display: "inline-block",
    width: "300px",
    verticalAlign: "middle"
  },
  infoField: {
    lineHeight: "32px",
    verticalAlign: "middle",
    display: "inline-block",
    width: "128px"
  },
  addNewProduct: {
    width: "400px",
    borderRadius: 0
  },
  alignRight: {
    textAlign: "right"
  }
}))

type CreateInvoiceDialogProps = {
  contract: Contract,
  open: boolean,
  handleOk: () => void,
  handleClose: () => void
}
export const CreateInvoiceDialog: React.FC<CreateInvoiceDialogProps> = props => {
  const { open, handleClose, handleOk, contract } = props;

  const styles = useStyles();
  
  const refundedLines = useSelector((state: AppState) => state.refunds.lastRefundedLines);
  const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig);
  const [periodInfo, setPeriodInfo] = useState<InvoiceCreatePeriod>();
  const [lines, setLines] = useState<CreateInvoiceLine[]>([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [createInvoiceDisabled, setCreateInvoiceDisabled] = useState(false)
  const products = useSelector((state: AppState) => state.products.items) || {};
    
  let activeProducts = products.filter(x => (x.status || {}).value === 1); 
  
  if(tenantConfig?.isContractBasedInvoicing && contract.residentialInfo && contract.residentialInfo.length && contract.residentialInfo[0].products){
    activeProducts = activeProducts.filter(x =>contract.residentialInfo[0].products.findIndex(cp => x.productId === cp.productId) !== -1);
  }

  useEffect(() => {
    if (open) {
      createLines();
      setCreateInvoiceDisabled(false)
    }
  }, [open])

  useEffect(() => {
    if (periodInfo && refundedLines) {
      
      let lines = refundedLines.map(x => ({
        invoiceDate: periodInfo.invoiceDate,
        period: tenantConfig ? x.period : periodInfo.period,
        periodYear: tenantConfig?.isContractBasedInvoicing ? x.periodYear : periodInfo.periodYear,
        numerator: x.numerator,
        denominator: x.denominator * (x.type === 1 ? periodInfo.periodCount : 1),
        productId: x.productId,
        description: x.description,
        lineNo: x.lineNo,
        invoiceId: x.invoiceId
      } as CreateInvoiceLine));

      lines = lines.map((element, index) => {
        element.lineNo = lines.indexOf(element);
        return element;
      });

      setLines(lines);
    }
  }, [periodInfo, refundedLines])

  const forceUpdate = () => {
    return setUpdateCount(updateCount => updateCount + 1);
  }

  const createLines = async () => {
    if (refundedLines?.length) {
      setIsLoading(true);
      setPeriodInfo(await InvoiceClient.getCreateInvoicePeriod(refundedLines[0].contractId, "").toPromise());
      setIsLoading(false);
    }
  }

  const addNewProduct = () => {
    if (!periodInfo) {
      return;
    }

    const lineNo = Math.max(...lines.map((p) => p.lineNo), 1) + 1;
     
    setLines([...lines, {
      invoiceDate: periodInfo.invoiceDate,
      period: periodInfo.period,
      periodYear: periodInfo.periodYear,
      numerator: 1,
      denominator: 1,
      description: '',
      lineNo: lineNo
    }]);
  }

  const calculateLinePrice = (line: CreateInvoiceLine, product: Product | undefined) => {
    if (!product || !periodInfo) {
      return null;
    }
    var lineTotal = product.price * (line.numerator / line.denominator);
    lineTotal += lineTotal * parseInt(product.vatCode) / 100;
    return lineTotal;
  }

  const chooseProduct = (line: CreateInvoiceLine, productId: string) => {
    line.productId = productId;
    forceUpdate();
  }

  const changeLineDenominator = (line: CreateInvoiceLine, denominator: number) => {
    line.denominator = denominator;
    forceUpdate();
  }

  const removeLine = (line: CreateInvoiceLine) => {
    const _lines = [...lines];
    _lines.splice(_lines.findIndex(x => x === line), 1);
    setLines(_lines);
  }

  const createInvoicesOk = async () => {

    setCreateInvoiceDisabled(true)

    if (!refundedLines || !refundedLines.length || lines.findIndex(x => !x.productId) !== -1) {
      return;
    }

    setIsLoading(true);

    var linesToRefund = refundedLines.map(x => ({
      InvoiceId: x.invoiceId,
      LineNumber: x.lineNo,
      Quantity: x.quantity
    }));
    
    await RefundClient.refund({
      linesToRefund
    });
  
    await InvoiceClient.createInvoiceLines({
      contractId: refundedLines[0].contractId,
      invoiceLines: lines
    }).toPromise();

    setIsLoading(false)

    handleOk();
  }

  const onChangeDescription = (line: CreateInvoiceLine, description: string) => {
    const _lines = [...lines];
    const updatedLine = { ...line, description: description};
    _lines.splice(_lines.findIndex(x => x === line), 1, updatedLine);
    setLines(_lines);
  }

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle className={styles.dialogTitle}>{tr("TEXT_CREATE_NEW_INVOICE")}</DialogTitle>
      <DialogContent style={{ width: '80vw', height: '60vh' }}>
        {isLoading && <Spinner />}
        {!isLoading &&
          <>
            <div>
              <strong className={styles.infoField}>{tr("TEXT_INVOICE_RECEIVER")}: </strong>
              <span className={`${styles.titleCase}`}>{contract.ownerFullName?.toLowerCase()}</span>
            </div>
            <div>
              <strong className={styles.infoField}>{tr("TEXT_INVOICE_ADDRESS")}: </strong>
              <span>{getAddressFormatted('-', contract.address)}</span> 
            </div>
            <Table className={styles.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_TERMIN")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_INVOICE_DATE")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_PRODUCT")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_DESCRIPTION")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_FRACTION")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_PRICE")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_MVA")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                    {tr("TEXT_SUM")}
                  </TableCell>
                  <TableCell className={styles.tableHead}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lines.map((line) => {
                  const product = activeProducts.find(x => x.id === line.productId);
                  return (
                    <TableRow key={line.lineNo}>
                      <TableCell className={styles.tableColumn}>
                        {line.period} {line.periodYear}
                      </TableCell>
                      <TableCell className={styles.tableColumn}>
                        {toShortDate(line.invoiceDate) || '-'}
                      </TableCell>
                      <TableCell className={styles.tableColumn}>
                        <FormControl variant="standard" style={{ minWidth: "300px" }}>
                          <Select
                            value={line.productId || ""}
                            onChange={item => chooseProduct(line, item.target.value as string)}
                          >
                            {activeProducts.map(product =>
                              <MenuItem value={product.id} key={product.id}>
                                <span>
                                  {product.name}
                                </span>
                              </MenuItem >
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={styles.tableColumn}>
                        <TextField value={line.description} onChange={text => onChangeDescription(line, text.target.value)}></TextField>
                      </TableCell>
                      <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                        <span>{line.numerator} / </span>
                        <FormControl variant="standard" style={{ maxWidth: "40px", verticalAlign: "middle" }}>
                          <Select
                            value={line.denominator}
                            onChange={item => changeLineDenominator(line, item.target.value as number)}
                          >
                            {[...Array(20)].map((_, index) =>
                              <MenuItem value={index + 1} key={index}>
                                <span>
                                  {index + 1}
                                </span>
                              </MenuItem >
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                        {product?.price.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                        {product?.vatCode ? product?.vatCode + " %" : "-"}
                      </TableCell>
                      <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                        {calculateLinePrice(line, product)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell className={styles.tableColumn}>
                        <DeleteOutlineIcon style={{ color: "#aaa", cursor: "pointer"}} onClick={() => removeLine(line)}></DeleteOutlineIcon>
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </>
        }
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button variant="outlined" className={styles.addNewProduct} onClick={addNewProduct}>{tr("TEXT_ADD_PRODUCT")}</Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {tr("TEXT_CANCEL")}
        </Button>
        <Button onClick={createInvoicesOk} disabled={createInvoiceDisabled} variant="contained" color="primary" autoFocus>
          {tr("TEXT_CREATE_NEW_INVOICE")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
