import { makeReducer } from "utils";
import {
    ActionReducer,
    Reducers,
} from "shared-components/src/utils/make-reducer";
import {
    ActionLoadInvoiceFilesType,
    ActionLoadInvoiceFilesSuccessType
} from "./invoice-file-table-actions";
import { InvoiceFile } from "models/invoice-file";

export type InvoiceFileState = {
    items: InvoiceFile[];
};

type InvoiceFileReducer = ActionReducer<InvoiceFileState>;

const loadInvoiceFiles: InvoiceFileReducer = (state) => {
    return state;
};
const loadInvoiceFilesSuccess: InvoiceFileReducer = (state, action) => {
    let s = {
        ...state,
        items: action.files
    };
    return s;
};

const reducers: Reducers<InvoiceFileState> = {
    [ActionLoadInvoiceFilesType]: loadInvoiceFiles,
    [ActionLoadInvoiceFilesSuccessType]: loadInvoiceFilesSuccess
};

const initialState = { items: [] };

export const invoiceFileReducer = makeReducer<InvoiceFileState>(reducers, initialState);