import * as keys from './keys'
export const en: { [index: string]: string } = {}

en[keys.TEXT_FILTER_REFUNDS_PLACEHOLDER] = 'Filter by customer, case number, address';
en[keys.TEXT_REFUNDS] = 'Refunds';
en[keys.TEXT_REFUND_LINE_SELECTED] = 'Refund line selected';
en[keys.TEXT_FILTER] = 'Filter';
en[keys.TEXT_REFUNDS_PUBLISH] = 'Send selected for payment';
en[keys.TEXT_REFUNDS_LINE_SELECTED] = 'selected';
en[keys.TEXT_PUBLISH_REFUNDS] = 'Send for payment';
en[keys.TEXT_PUBLISH_SELECTED_REFUNDS] = ' Send selected for payment';
en[keys.TEXT_REFUNDS_DELETE] = 'Delete selected';
en[keys.TEXT_REFUNDS_OK] = 'Ok'
en[keys.TEXT_REFUNDS_DATE_CREATED] = 'Date created'
en[keys.TEXT_REFUNDS_ORDERNUMBER] = 'Kreditnota nummer';
en[keys.TEXT_REFUNDS_IDENTIFICATION] = 'Gnr/Bnr/Fnr/Snr';
en[keys.TEXT_REFUNDS_ADDRESS] = 'Address';
en[keys.TEXT_REFUNDS_APPARTMENT_NUMBER] = 'Appartment number';
en[keys.TEXT_REFUNDS_AMOUNT] = 'Amount';
en[keys.TEXT_REFUNDS_CUSTOMER_NAME] = 'Customer';
en[keys.TEXT_REFUNDS_CASE_NUMBER] = 'Activity ID';
en[keys.TEXT_REFUNDS_DELETE_DIALOG_TITLE] = 'Delete payment' 
en[keys.TEXT_REFUNDS_TOTAL_AMOUNT] = 'Total amount'