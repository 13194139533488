import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { ApplicationRoutes } from "./app-routes";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { useSelector, useDispatch } from "react-redux";
import { actionAuthInit } from "../redux/auth/auth-actions";
import { CubitSnackbar } from "common/cubit-snackbar/cubit-snackbar";
import { ApplicationRoutesPublic } from "./app-routes-public";
import Spinner from "common/spinner";
import { AppState } from "../redux/app/app-store";
import { useAuth, useUser } from "../redux/auth/auth-selectors";
import { setSessionKey } from "utils";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#0c7282",
      main: "#1a7e8e",
      dark: "#0c7282",
      contrastText: "#fff"
    },
    secondary: {
      light: "#0c7282",
      main: "#0c7282",
      dark: "#0c7282",
      contrastText: "#fff"
    }
  }
});

const App: React.FC = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const user = useUser();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes("/sso-login/")) {
      const token = pathname.split('/')[2];
      setSessionKey(token)
      window.location.href = "/";
    }
    dispatch(actionAuthInit());
  }, [dispatch]);

  // set html element tag lang attribute
  const language = useSelector((state: AppState) => state.settings.language);
  document.documentElement.lang = language;  
  const userTags = user ? user.tags : [];

  return (
    <ThemeProvider theme={appTheme}>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale={language}>
        {auth.isInitialized ? (
          auth.sessionKey ? (
            <>
              <Router style={{ height: "100%" }}>
                {ApplicationRoutes.map((route, index) =>
                  route({
                    key: index,
                    userTags
                  })
                )}
              </Router>
              <CubitSnackbar></CubitSnackbar>
            </>
          ) : (
            <Router style={{ height: "100%" }}>
              {ApplicationRoutesPublic.map((route, index) =>
                route({
                  key: index
                })
              )}
            </Router>
          )
        ) : (
            <Spinner/>
        )}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
