import ConfigClient from "clients/config-client";
import { AnyAction } from "redux";
import { Observable } from "rxjs";
import { filter, flatMap, map } from "rxjs/operators";
import { defaultCatchError } from "../app/app-epics";
import { actionPeriodConfigurationsGetSuccess, actionTenantConfigGetSuccess, actionTenantConfigSaveSuccess, ACTION_PERIODCONFIGURATIONS_GET, ACTION_TENANTCONFIG_GET, ACTION_TENANTCONFIG_SAVE } from "./config-action";
import { combineEpics } from "redux-observable";

export const getTenantConfig = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    filter(x => x.type === ACTION_TENANTCONFIG_GET),
    flatMap(
      (action): Observable<AnyAction> =>
        ConfigClient.getTenantConfig().pipe(
          map(tenantConfig => actionTenantConfigGetSuccess(tenantConfig)),
          defaultCatchError()
        )
    )
  );

export const saveTenantConfig = (
    action$: Observable<AnyAction>
  ): Observable<AnyAction> =>
    action$.pipe(
      filter(x => x.type === ACTION_TENANTCONFIG_SAVE),
      flatMap(
        (action): Observable<AnyAction> =>
          ConfigClient.updateTenantConfig(action.tenantConfig).pipe(
            map(tenantConfig => actionTenantConfigSaveSuccess(tenantConfig)),
            defaultCatchError()
          )
      )
    );

export const getPeriodConfigurations = (
    action$: Observable<AnyAction>
  ): Observable<AnyAction> =>
    action$.pipe(
      filter(x => x.type === ACTION_PERIODCONFIGURATIONS_GET),
      flatMap(
        (action): Observable<AnyAction> =>
          ConfigClient.getPeriodConfigurations().pipe(
            map(periodConfigs => actionPeriodConfigurationsGetSuccess(periodConfigs)),
            defaultCatchError()
          )
      )
    );

export const configEpics = combineEpics(
  getTenantConfig,
  saveTenantConfig,
  getPeriodConfigurations
);