import {
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    Input,
    ListItemText,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Theme,
} from "@material-ui/core"

import { ProductPeriodSelection } from "models/product"
import React, { useEffect, useState } from "react"
import {
    TEXT_PRODUCT_EACH_PERIOD,
    TEXT_PRODUCT_EACH_MAIN_PERIOD,
    TEXT_PRODUCT_SELECT_PERIOD,
    TEXT_PRODUCT_INVOICE_PERIODS,
    TEXT_TERMIN,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"

const ITEM_HEIGHT = 200
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        formContainer: {
            padding: "30px",
        },
        buttons: {
            marginTop: theme.spacing(5),
        },
        auditGrid: {
            padding: theme.spacing(1),
            height: "1000px",
            overflow: "auto",
        },
        priceField: {
            display: "flex",
            alignItems: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        headerWrapper: {
            padding: "18px 31px",
            justifyContent: "space-between",
        },
        periodSelection:{
            //width: 400
            //height: 500
        }
    }
})

type ProductPeriodSelectionProps = {
    selectedPeriodsValue: any
    selectedPeriodsValueOnChange: any
    periodType: any
    selectedPeriodTypeChanged: any
}

const ProductPeriodSelectionComponent: React.FC<ProductPeriodSelectionProps> = props => {
    const styles = useStyles()

    const init: ProductPeriodSelection[] = []
    const [periods, setPeriods] = useState(init)
    const [selectedPeriodType, setSelectedPeriodType] = useState("1")

    const initPeriods = () => {
        let result: ProductPeriodSelection[] = []
        const periodCount = 12

        for (let index = 1; index <= periodCount; index++) {
            result.push({ label: index + " " + tr(TEXT_TERMIN), value: index })
        }

        setPeriods(result)
    }

    useEffect(() => {
        initPeriods()
        setSelectedPeriodType(props.periodType !== undefined ? props.periodType.toString() : "")

        return () => {}
    }, [])

    const onPeriodTypeSelect = (event) => {   
        setSelectedPeriodType(event.target.value)
        props.selectedPeriodTypeChanged(event.target.value)
    }

    return (
        <>
            <RadioGroup>
                <Grid container spacing={2} className={styles.headerWrapper}>
                    <Grid item xs={2} className="bold">
                        {tr(TEXT_PRODUCT_INVOICE_PERIODS)}
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            checked={selectedPeriodType === "1"}
                            name="periodTypeRadio"
                            value="1"
                            control={<Radio />}
                            label={tr(TEXT_PRODUCT_EACH_PERIOD)}
                            onChange={onPeriodTypeSelect}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            checked={selectedPeriodType === "2"}
                            name="periodTypeRadio"
                            value="2"
                            control={<Radio />}
                            label={tr(TEXT_PRODUCT_EACH_MAIN_PERIOD)}
                            onChange={onPeriodTypeSelect}
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2} className={styles.headerWrapper}>
                    <Grid item xs={2} className="bold"></Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            checked={selectedPeriodType === "3"}
                            name="periodTypeRadio"
                            value="3"
                            control={<Radio />}
                            label={tr(TEXT_PRODUCT_SELECT_PERIOD)}
                            onChange={onPeriodTypeSelect}
                        ></FormControlLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            className={styles.periodSelection}
                            id="periodSelect"
                            multiple
                            disabled={selectedPeriodType !== "3"}
                            value={props.selectedPeriodsValue}
                            onChange={(e) => props.selectedPeriodsValueOnChange(e.target.value)}
                            input={<Input />}
                            renderValue={(selected: any) => (
                                <div className={styles.chips}>
                                    {selected.map(value => (
                                        <Chip
                                            key={value}
                                            label={periods.find(x => x.value === value)?.label}
                                            className={styles.chip}
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {periods.map(period => (
                                <MenuItem key={period.label} value={period.value}>
                                    <Checkbox
                                        checked={
                                            props.selectedPeriodsValue.findIndex((x: number) => period.value === x) > -1
                                        }
                                    />
                                    <ListItemText primary={period.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </RadioGroup>
        </>
    )
}

export { ProductPeriodSelectionComponent }
