import React from "react";
import { CubitTableColumn } from "shared-components/src/cubit-table/cubit-table.types";
import CubitTable from "shared-components/src/cubit-table/cubit-table";
import { tr } from "utils/translations/translate";
import {
  TEXT_NAME,
  TEXT_DESCRIPTION,
  TEXT_TYPE,
  TEXT_VALID_FROM,
  TEXT_VALID_TO,
  TEXT_STATUS,
  TEXT_PRICE,
  TEXT_VAT_CODE,
  TEXT_PRODUCT_NUMBER,
  TEXT_PRODUCT_ACTIVITY_BASED,
  TEXT_PRODUCT_PERIODIC,
  TEXT_PRODUCT_UPDATED_ICON_TOOLTIP
} from "utils/translations/keys";
import { Product } from "models/product";
import { navigate } from "@reach/router";
import { DateTime } from "luxon";
import { ShortDateFormat } from "common/constants/dateFormats";
import { PRODUCT_ACTIVITY_BASED_BILLING_TYPE } from "./product-constants";
import ErrorIcon from "@material-ui/icons/Error"
import { makeStyles, Theme, Typography } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

type ProductTableProps = {
  products: Product[];
  showProductChanged?: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  productChangedIcon: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: "1.5em"
  },
  productChangedTooltip:{
    maxWidth: 400,
  },
  needsInvoicesUpdatePadding:{
    padding: 0
  }
}))

export const ProductTable: React.FC<ProductTableProps> = ({ products, showProductChanged }) => {
  const styles = useStyles()

  const productColumns: CubitTableColumn[] = [
    {
      headerLabel: tr(TEXT_PRODUCT_NUMBER),
      key: "productId",
    },
    {
      headerLabel: tr(TEXT_NAME),
      key: "name"
    },
    {
      headerLabel: tr(TEXT_DESCRIPTION),
      key: "description"
    },
    {
      headerLabel: tr(TEXT_PRICE),
      key: "price"
    },
    {
      headerLabel: tr(TEXT_TYPE),
      key: "productBillingType",
      getDisplayableElement: row => (
        <span>{tr(row.productBillingType === PRODUCT_ACTIVITY_BASED_BILLING_TYPE ? TEXT_PRODUCT_ACTIVITY_BASED : TEXT_PRODUCT_PERIODIC)}</span>
      )
    },
    {
      headerLabel: tr(TEXT_VALID_FROM),
      key: "validFrom",
      getDisplayableElement: row => (
        <span>{DateTime.fromISO(row.validFrom).toFormat(ShortDateFormat)}</span>
      )
    },
    {
      headerLabel: tr(TEXT_VALID_TO),
      key: "validTo",
      getDisplayableElement: row => (
        <span>{DateTime.fromISO(row.validTo).toFormat(ShortDateFormat)}</span>
      )
    },
    {
      headerLabel: tr(TEXT_VAT_CODE),
      key: "vatCode"
    },
    {
      headerLabel: tr(TEXT_STATUS),
      key: "status.label",
      getDisplayableElement: row => (
        <span>{tr(row.status.label)}</span>
      )
    }
  ];

  if(showProductChanged){ 
    productColumns.unshift({
      headerLabel: '',
      key: "needsInvoicesUpdate",
      align: "center",
      paddingClassName: "needsInvoicesUpdatePadding",
      getDisplayableElement: row => (row.needsInvoicesUpdate ? <Tooltip classes={{tooltip : styles.productChangedTooltip}} title={tr(TEXT_PRODUCT_UPDATED_ICON_TOOLTIP)} placement="top-end"><ErrorIcon className={styles.productChangedIcon} /></Tooltip> : <span></span>)
    })
  }
  
  const handleRowClick = (row: Product) => {
    navigate(`/products/${row.id}`);
  };

  return (
    <CubitTable
      name="products"
      columns={productColumns}
      data={products}
      sidePadding="24"
      sorting={{ direction: "asc", by: "name" }}
      onRowClick={handleRowClick}
    />
  );
};
