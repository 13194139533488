import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { makeStyles, Theme } from '@material-ui/core/styles'

import classNames from 'classnames'

import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'

import { useDispatch, useSelector } from 'react-redux'
import { actionNavigationToggle, actionMobileNavigationToggle } from '../../redux/navigation/navigation-actions'
import { Hidden, Grid } from '@material-ui/core'
import MunicipalityMenu from 'common/municipality/municipality-menu'
import { AppState } from 'redux/app/app-store'

const DRAWER_WIDTH = 200
const DEFAULT_CONTENT_PADDING = 3

type StylesProps = {
    padding: number | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    menuButton: {
        marginRight: '36px',
    },

    contextBar: {
        ...theme.mixins.toolbar,
        zIndex: theme.zIndex.drawer + 10,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('md')]: {
            marginLeft: DRAWER_WIDTH,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },

    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width: DRAWER_WIDTH,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    contentChildren: (props: StylesProps) => ({
        flex: 1,
        padding: theme.spacing(props.padding !== undefined ? props.padding : DEFAULT_CONTENT_PADDING),
    }),
    hide: {
        display: 'none',
    },
    municipalityMenu: {
        maxWidth: 300,
        color: theme.palette.common.white,
        paddingLeft: 10
    }
}))

type LayoutComponentProps = {
    navigation: React.ReactElement
    pageTitle?: string
    toolbar?: React.ReactElement
    contextbar?: React.ReactElement
    padding?: number
    headerBackgroundColor?: string,
    showMunicipalities?: boolean
}

const LayoutDefault: React.FC<LayoutComponentProps> = props => {
    const { navigation, toolbar, contextbar, padding, headerBackgroundColor, showMunicipalities = false } = props
    const styles = useStyles({ padding })

    const pageTitle = props.pageTitle ? <Typography variant="h6">{props.pageTitle}</Typography> : null

    const menuExpanded = useSelector((state: { navigation: any }) => state.navigation.menuExpanded)
    const mobileMenuExpanded = useSelector((state: { navigation: any }) => state.navigation.mobileMenuExpanded)
    const hasMunicipalities = useSelector((state :AppState) => state.municipalities.hasMunicipalities)
    
    const dispatch = useDispatch()

    const toggleNavigation = () => {
        dispatch(actionNavigationToggle(!menuExpanded))
    }
    const toggleMobileNavigation = () => {
        dispatch(actionMobileNavigationToggle(!mobileMenuExpanded))
    }

    return (
        <div className={styles.root}>
            {contextbar}

            <AppBar
                position="fixed"
                className={classNames(styles.appBar, {
                    [styles.appBarShift]: menuExpanded,
                })}
            >
                <Toolbar style={{ paddingTop: '8px', paddingBottom: '8px', background: headerBackgroundColor }}>
                    <Grid container alignItems="center">
                        <Hidden smDown implementation="css">
                            <Grid item>
                                <IconButton
                                    onClick={toggleNavigation}
                                    className={classNames(styles.menuButton, {
                                        [styles.hide]: menuExpanded,
                                    })}
                                    color="inherit"
                                    edge="start"
                                    aria-label="Menu"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp implementation="css">
                            <Grid item>
                                <IconButton
                                    onClick={toggleMobileNavigation}
                                    className={classNames(styles.menuButton, {
                                        [styles.hide]: mobileMenuExpanded,
                                    })}
                                    color="inherit"
                                    edge="start"
                                    aria-label="Menu"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs>
                            {pageTitle}
                            {toolbar}
                        </Grid>
                        {showMunicipalities && hasMunicipalities && <Grid item xs className={styles.municipalityMenu} >
                            <MunicipalityMenu ></MunicipalityMenu>
                        </Grid>}
                    </Grid>
                </Toolbar>
            </AppBar>

            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    className={classNames(styles.drawer, {
                        [styles.drawerOpen]: menuExpanded,
                        [styles.drawerClose]: !menuExpanded,
                    })}
                    classes={{
                        paper: classNames({
                            [styles.drawerOpen]: menuExpanded,
                            [styles.drawerClose]: !menuExpanded,
                        }),
                    }}
                    open={menuExpanded}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div className={styles.toolbar}>
                        <IconButton onClick={toggleNavigation}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    {navigation && navigation}
                </Drawer>
            </Hidden>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    className={classNames(styles.drawer, {
                        [styles.drawerOpen]: mobileMenuExpanded,
                        [styles.drawerClose]: !mobileMenuExpanded,
                    })}
                    classes={{
                        paper: classNames({
                            [styles.drawerOpen]: mobileMenuExpanded,
                            [styles.drawerClose]: !mobileMenuExpanded,
                        }),
                    }}
                    open={mobileMenuExpanded}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div className={styles.toolbar}>
                        <IconButton onClick={toggleMobileNavigation}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    {navigation && navigation}
                </Drawer>
            </Hidden>

            <div className={styles.content}>
                <div className={styles.toolbar} />
                <div className={styles.contentChildren}>{props.children}</div>
            </div>
        </div>
    )
}

export default LayoutDefault
