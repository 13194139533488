import React from "react";

const Spinner: React.FC = () => {
  return (
    <div className="index-spinner-container">
      <svg
        className="index-svg-circle-spinner  index-svg-circle-spinner-static"
        viewBox="0 0 64 64"
      >
        <circle cx="32" cy="32" r="20" className="index-spinner-path"></circle>
        <circle
          cx="32"
          cy="32"
          r="20"
          className="index-loading-spinner"
        ></circle>
      </svg>
    </div>
  );
};

export default Spinner;
