import { AnyAction } from "redux"
import { Product } from "models/product"

export const ACTION_PRODUCT_GET = "ACTION_PRODUCT_GET"
export const ACTION_PRODUCT_GET_SUCCESS = "ACTION_PRODUCT_GET_SUCCESS"
export const ACTION_PRODUCT_UPDATE = "ACTION_PRODUCT_UPDATE"
export const ACTION_PRODUCT_UPDATE_SUCCESS = "ACTION_PRODUCT_UPDATE_SUCCESS"
export const ACTION_LOAD_PRODUCTS = "ACTION_LOAD_PRODUCTS"
export const ACTION_LOAD_PRODUCTS_SUCCESS = "ACTION_LOAD_PRODUCTS_SUCCESS"
export const ACTION_PRODUCT_SUBMIT_FORM = "ACTION_PRODUCT_SUBMIT_FORM"
export const ACTION_PRODUCT_SUBMIT_FORM_SUCCESS = "ACTION_PRODUCT_SUBMIT_FORM_SUCCESS"
export const ACTION_PRODUCT_SUBMIT_FORM_ERROR = "ACTION_PRODUCT_SUBMIT_FORM_ERROR"
export const ACTION_UPDATE_FILTER = "ACTION_UPDATE_FILTER"
export const ACTION_PRODUCT_ADD = "ACTION_PRODUCT_ADD"
export const ACTION_PRODUCT_ADD_SUCCESS = "ACTION_PRODUCT_ADD_SUCESS"
export const ACTION_NAVIGATE_TO_PRODUCT = "ACTION_NAVIGATE_TO_PRODUCT"

export const actionLoadProducts = (): AnyAction => ({
    type: ACTION_LOAD_PRODUCTS,
})

export const actionLoadProductsSuccess = (products: Product[]): AnyAction => ({
    type: ACTION_LOAD_PRODUCTS_SUCCESS,
    products,
})

export const actionProductGet = (productId: number | undefined): AnyAction => {
    return {
        type: ACTION_PRODUCT_GET,
        productId,
    }
}

export const actionProductGetSuccess = (product: Product): AnyAction => {
    return {
        type: ACTION_PRODUCT_GET_SUCCESS,
        product,
    }
}

export const actionProductUpdate = (product: Product): AnyAction => {
    return {
        type: ACTION_PRODUCT_UPDATE,
        product,
    }
}

export const actionProductUpdateSuccess = (product: Product): AnyAction => {
    return {
        type: ACTION_PRODUCT_UPDATE_SUCCESS,
        product,
    }
}

export const actionProductSubmitForm = (formValue: any): AnyAction => ({
    type: ACTION_PRODUCT_SUBMIT_FORM,
    formValue,
})

export const actionProductSubmitFormSuccess = (response: any): AnyAction => ({
    type: ACTION_PRODUCT_SUBMIT_FORM_SUCCESS,
    response,
})

export const actionProductSubmitFormError = (): AnyAction => ({
    type: ACTION_PRODUCT_SUBMIT_FORM_ERROR,
})

export const actionSetFilter = (filter: any): AnyAction => ({
    type: ACTION_UPDATE_FILTER,
    filter,
})

export const actionProductAdd = (product: Product): AnyAction => {
    return {
        type: ACTION_PRODUCT_ADD,
        product,
    }
}

export const actionProductAddSuccess = (product: Product): AnyAction => {
    return {
        type: ACTION_PRODUCT_ADD_SUCCESS,
        product,
    }
}

export const actionNavigateToProduct = (productId: string): AnyAction => {
    return {
        type: ACTION_NAVIGATE_TO_PRODUCT,
        productId,
    }
}
