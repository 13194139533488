import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import { DateTime } from "luxon";
import Spinner from "common/spinner";
import ContractClient from "clients/contract-client";
import { Audit } from "../../models/audit";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    marginBottom: "10px",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    fontSize: 12,
    fontWeight: 200,
    width: "240px",
  },
  avatar: {
    backgroundColor: green[500],
  },
  cardContent: {
    padding: "0 10px 0 10px",
    paddingBottom: 0,
  },
}));

type ContractAuditProps = {
  contractId: string;
};

const ContractAudit: React.FC<ContractAuditProps> = ({ contractId }) => {
  const styles = useStyles();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState<Audit[]>([]);

  const getData = async () => {
    setIsLoading(true);
    const items = await ContractClient.getContractAudit(contractId).toPromise();
    setData(items);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Card className={styles.root} variant="outlined">
        <Spinner />
      </Card>
    );
  }

  return (
    <>
      {data.map((item, index) => {
        let type = "productChanges";
        let added = false;
        let updated = false;
        let deleted = false;
        if (item.fields && item.fields.some((el: any) => el.columnName === "testInvoiceCreated")) {
          type = "testInvoiceCreated";
        } else if (item.fields && item.fields.some((el: any) => el.columnName === "invoicePublished")) {
          type = "invoicePublished";
        } else if (item.fields && item.fields.some((el: any) => el.columnName === "invoiceCreated")) {
          type = "invoiceCreated";
        } else {
          if (item.fields.some((el: any) => !el.newValue)) {
            deleted = true;
          }
          if (item.fields.some((el: any) => !el.oldValue)) {
            added = true;
          }
          if (item.fields.some((el: any) => !!el.newValue && !!el.oldValue)) {
            updated = true;
          }
        }

        const title = !!item.userName ? item.userName : 'Unknown';
        const subheader = !!item.auditDate ? DateTime.fromISO(item.auditDate).toFormat("yyyy-MM-dd HH:mm:ss") : '';

        return (
          <Card className={styles.root} variant="outlined" key={index}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={styles.avatar}>
                  <OfflineBoltIcon />
                </Avatar>
              }
              title={title}
              subheader={subheader}
            />
            <CardContent className={styles.cardContent}>
              {type === "productChanges" && (
                <Typography className={styles.title}>
                  {added && `Product ${item.entityName} added`}
                  {updated && `Product ${item.entityName} updated`}
                  {deleted && `Product ${item.entityName} removed`}
                </Typography>
              )}

              {item.fields.map((field: any, index: number) => {
                if (type === "testInvoiceCreated") {
                  return (
                    <div key={index}>
                      <Typography className={styles.title}>
                        Test invoices were created
                      </Typography>
                    </div>
                  );
                }

                if (type === "invoicePublished") {
                  return (
                    <div key={index}>
                      <Typography className={styles.title}>
                        Invoices were published
                      </Typography>
                    </div>
                  );
                }

                if (type === "invoiceCreated") {
                  return (
                    <div key={index}>
                      <Typography className={styles.title}>
                        Invoices were created
                      </Typography>
                    </div>
                  );
                }

                if (type === "productChanges" && updated) {
                  return (
                    <div key={index}>
                      <Typography className={styles.text}>
                        {field.columnName}{": "}
                        {`${field.oldValue} -> ${field.newValue}`}
                      </Typography>
                    </div>
                  );
                }
              })}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export { ContractAudit };
