import { ActionReducer, Reducers, makeReducer } from 'shared-components/src/utils/make-reducer'
import { AnyAction } from 'redux'
import { ACTION_CUBIT_SNACKBAR_SHOW } from './cubit-snackbar-actions'

export type CubitSnackbarState = {
    messages?: string[]
    variant?: 'success' | 'warning' | 'error' | 'info';
    timestamp?: number
}

type CubitSnackbarReducer = ActionReducer<CubitSnackbarState, AnyAction>

const showCubitSnackbar: CubitSnackbarReducer = (state, action) => {
    state.messages = action.messages;
    state.variant = action.variant;
    state.timestamp = Date.now();
    return state;
}

const reducers: Reducers<CubitSnackbarState> = {
    [ACTION_CUBIT_SNACKBAR_SHOW]: showCubitSnackbar,
}

export const cubitSnackbarReducer = makeReducer<CubitSnackbarState>(reducers, {})
