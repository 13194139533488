import React from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import { actionGoBack } from "../../redux/app/app-actions";
import { useDispatch } from "react-redux";
import { Truncate } from "common/truncate/truncate";

export type TitleToolbarProps = {
  title: string;
  enableGoBack?: boolean;
  enableClose?: boolean;
  onClose?: () => any;
};
const TitleToolbar: React.FC<TitleToolbarProps> = props => {
  const { title, enableGoBack = true, enableClose = false, onClose } = props;

  const dispatch = useDispatch();

  const handleGoBack = () => {
    dispatch(actionGoBack());
  };

  // const handleClose = () => {
  // }

  return (
    <Grid container spacing={2} alignItems="center">
      {enableGoBack ? (
        <Grid item>
          <IconButton onClick={handleGoBack} color="inherit">
            <KeyboardBackspaceIcon />
          </IconButton>
        </Grid>
      ) : null}
      {enableClose ? (
        <Grid item>
          <IconButton
            onClick={() => (onClose ? onClose() : () => {})}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      ) : null}
      <Grid item xs>
        <Typography variant="h6">
          <Truncate>{title}</Truncate>
        </Typography>
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};

export default TitleToolbar;
