export const FILE_NAME = "FileName"
export const FILE_TYPE = "FileType"
export const SENT_DATE = "SentDate"
export const ORDER_FROM = "OrderFrom"
export const ORDER_TO = "OrderTo"
export const ORDER_SUM = "Sum"
export const ORDER_COUNT = "OrderCount"
export const LAST_SENT_FILES_HEADER = "LastSentFiles"
export const TEXT_MUNICIPALITY = "Municipality"
export const GENERATED_DATE = "GENERATED_DATE"
