import React, { useState } from "react";
import { makeStyles, Theme, TextField, Grid } from "@material-ui/core";
import { tr } from "../../utils/translations/translate";
import { TEXT_CODE, TEXT_DESCRIPTION, TEXT_PERCENTAGE } from "../../utils/translations/keys";
import { ValidationResult } from "../../models/validation-result";
import { VatCode } from "../../models/vatCode";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      flexGrow: 1
    },
    formContainer: {
      padding: "30px"
    },
    buttons: {
      marginTop: theme.spacing(5)
    },
    auditGrid: {
      padding: theme.spacing(1),
      height: "1000px",
      overflow: "auto"
    },
    priceField: {
      display: "flex",
      alignItems: "center"
    },
    calendarIcon: {
      color: "#999",
      fontSize: "1.3rem"
    },
  };
});

type AddVatFormProps = {
  validationResult: ValidationResult,
  vat: VatCode,
  onVatUpdated: (VatCode) => void
}

export const AddVatForm: React.FC<AddVatFormProps> = props => {
  const { onVatUpdated, vat, validationResult } = props;
  const styles = useStyles();

  const [ updateCount, setUpdateCount ] = useState(0);

  const forceUpdate = () => {
    return setUpdateCount(updateCount => updateCount + 1);
  }

  const changeCode = (value: any) => {
    removeValidation('code');
    vat.vatCode = value;
    onVatUpdated(vat);
  };

  const changeDescription = (value: any) => {
    vat.description = value;
    onVatUpdated(vat);
  }

  const changePercentage = (value: any) => {
    removeValidation('percentage');
    vat.vatPercentage = value;
    onVatUpdated(vat);
  }

  const isValid = (fieldId: string) => {
    const messages = (validationResult.messages || []).filter(x => x.fieldId === fieldId);
    const message = messages.length > 0 ? messages[0] : null;
    const text = message?.translationCode ? tr(message.translationCode) : '';
    return { error: !!message, helpText: text }
  }

  const removeValidation = (fieldId: string) => {
    const indexToDelete = (validationResult.messages || []).findIndex(x => x.fieldId === fieldId);
    if (indexToDelete > -1) {
      validationResult.messages.splice(indexToDelete, 1);
    }
    forceUpdate();
  }

  const isValidName = isValid('code');
  const isValidPrice = isValid('percentage');

  return (
    <form>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={4} className="bold">
          {tr(TEXT_CODE)}
        </Grid>
        <Grid item xs={8}>
          <TextField error={isValidName.error} helperText={isValidName.helpText} required defaultValue={vat.vatCode} onChange={item => changeCode(item.target.value)}></TextField>
        </Grid>
      </Grid>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={4} className="bold">
          {tr(TEXT_DESCRIPTION)}
        </Grid>
        <Grid item xs={8}>
          <TextField onChange={item => changeDescription(item.target.value)} defaultValue={vat.description}></TextField>
        </Grid>
      </Grid>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={4} className="bold">
          {tr(TEXT_PERCENTAGE)}
        </Grid>
        <Grid item xs={8} className={styles.priceField}>
          <TextField error={isValidPrice.error} helperText={isValidPrice.helpText} type="number" onChange={item => changePercentage(item.target.value)} defaultValue={vat.vatPercentage}></TextField>
          &nbsp;%
        </Grid>
      </Grid>
    </form>
  );
}
