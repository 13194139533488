import { Button, FormControl, Grid, InputAdornment, makeStyles, MenuItem, Paper, Select, TextField, Theme, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { FileSelection } from "./komtek-file-selection"
import KomtekClient from "clients/komtek-upload-client"
import Spinner from "common/spinner"
import {
    TEXT_IMPORT_DATE,
    TEXT_IMPORT_KOMTEK_FILES,
    TEXT_SELECT_CONTRACT_FILE,
    TEXT_SELECT_INVOICE_FILE,
    TEXT_KOMTEK_IMPORT,
    TEXT_MUNICIPALITY_NUMBER,
    TEXT_KOMTEK_IMPORT_TITLE,
    TEXT_STATUS,
    TEXT_DONE,
    TEXT_IMPORTING,
    TEXT_PERIOD,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { ContractDialog } from "pages/contract-page/contract-dialog"
import { useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import { DateTime } from "luxon"
import { ShortDateFormat } from "common/constants/dateFormats"

const useStyles = makeStyles((theme: Theme) => {
    return {
        uploadContainerGrid: {
            width: "70%",
        },
    }
})

enum ImportStatus {
    None = 0,
    Importing,
    Done,
    Error,
}

export const KomtekFileUpload = props => {
    const { styles } = props

    const localStyle = useStyles()

    const onMunicipalityChange = val => {
        setMunicipality(val)
    }

    const [contractFile, setContractFile] = useState("")
    const [contractFileName, setContractFileName] = useState("")
    const [invoiceFile, setInvoiceFile] = useState("")
    const [invoiceFileName, setInvoiceFileName] = useState("")
    const [municipality, setMunicipality] = useState("")
    const [importingStatus, setImportingStatus] = useState(ImportStatus.None)
    const [error, setError] = useState("")
    const [resultMessage, setResultMessage] = useState("")
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState(1)
    const [lastImportDate, setLastImportDate] = useState<Date | null>(null)

    const periodConfigurations = useSelector((state: AppState) => state.config.periodConfigurations)

    const getPeriods = () => {
        const today = new Date();
        const year = today.getUTCFullYear();
        const periodConfig = periodConfigurations?.find(x => x.year === year)
        return periodConfig?.periods ?? []
    }

    useEffect(() => {
        const periods = getPeriods()
        const today = new Date();
        
        //find and set current period
        for (const period of periods) {
            if(period.startDate !== undefined && today > new Date(period.startDate) && 
                period.endDate !== undefined && today < new Date(period.endDate)){
                setSelectedPeriod(period.periodNumber)
                break
            }
        }
    }, [periodConfigurations])
    
    useEffect(() => {
        getStatus()
    }, [importingStatus])

    const getStatus = async () => {
        const result = await KomtekClient.getStatus().toPromise()

        if(result){
            if (result.error) {
                setError(result.error)
            } else if(result.message) {
                setResultMessage(result.message)
                setLastImportDate(result.lastImported)
            }
        }
    }


    const handleUploadKomtek = async () => {
        setIsImportDialogOpen(false)
        setImportingStatus(ImportStatus.Importing)

        let formData = new FormData()
        formData.append("file", contractFile, contractFileName)

        await KomtekClient.upload(formData).toPromise()

        formData = new FormData()
        formData.append("file", invoiceFile, invoiceFileName)

        await KomtekClient.upload(formData).toPromise()

        const result = await KomtekClient.import({
            contractFile: contractFileName,
            invoiceFile: invoiceFileName,
            municipalityNumber: municipality,
            periodNumber: selectedPeriod.toString()
        }).toPromise()

        if (result && result.error) {
            setError(result.error)
            setImportingStatus(ImportStatus.Error)
        } else {
            setResultMessage(result.message)
            setLastImportDate(result.lastImported)
            setImportingStatus(ImportStatus.Done)
        }
    }

    const onSetContractInputValue = e => {
        setContractFile(e.target.files[0])
        setContractFileName(e.target.files[0].name)
    }

    const onSetInvoiceInputValue = e => {
        setInvoiceFile(e.target.files[0])
        setInvoiceFileName(e.target.files[0].name)
    }

    const handleOnPeriodChange = e => {
        setSelectedPeriod(parseInt(e.target.value))
    }

    return (
        <Paper className={styles.paper}>
            <h2>{tr(TEXT_KOMTEK_IMPORT)}</h2>
            <Grid container spacing={5} direction="row" className={localStyle.uploadContainerGrid}>
                <Grid item xs={12}>
                    <FileSelection
                        onSetInputValue={onSetContractInputValue}
                        fileName={contractFileName}
                        styles={styles}
                        buttonText={tr(TEXT_SELECT_CONTRACT_FILE)}
                        id="contract-button-file"
                    ></FileSelection>
                </Grid>
                <Grid item xs={12}>
                    <FileSelection
                        onSetInputValue={onSetInvoiceInputValue}
                        fileName={invoiceFileName}
                        styles={styles}
                        buttonText={tr(TEXT_SELECT_INVOICE_FILE)}
                        id="invoice-button-file"
                    ></FileSelection>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{tr(TEXT_PERIOD)}</Typography>
                    <FormControl variant="standard" className={styles.periodsSelector}>
                        <Select
                            value={selectedPeriod}
                            onChange={handleOnPeriodChange}
                            disabled={importingStatus === ImportStatus.Importing}
                        >
                            {getPeriods().map((p, index) => (
                                <MenuItem key={index} value={p.periodNumber}>
                                    <span>
                                    {tr(TEXT_PERIOD)} {p.periodNumber}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                    <TextField
                        label={tr(TEXT_MUNICIPALITY_NUMBER)}
                        type="number"
                        onChange={e => onMunicipalityChange(e.target.value)}
                        value={municipality}
                    ></TextField>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={importingStatus === ImportStatus.Importing}
                        onClick={() => setIsImportDialogOpen(true)}
                    >
                        {tr(TEXT_IMPORT_KOMTEK_FILES)}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        {tr(TEXT_STATUS)}:{" "}
                        {importingStatus === ImportStatus.Importing
                            ? tr(TEXT_IMPORTING)
                            : importingStatus === ImportStatus.Done
                            ? tr(TEXT_DONE) + resultMessage
                            : importingStatus === ImportStatus.Error
                            ? error
                            : resultMessage}
                    </Typography>
                    {importingStatus === ImportStatus.Importing && <Spinner></Spinner>}
                </Grid>
                <Grid item xs={6}>
                <Typography>
                        Last import date: {lastImportDate ? DateTime.fromJSDate(new Date(lastImportDate)).toFormat(ShortDateFormat) : ''}
                    </Typography>
                </Grid>
            </Grid>
            <ContractDialog
                title={tr(TEXT_KOMTEK_IMPORT_TITLE)}
                open={isImportDialogOpen}
                handleClose={() => setIsImportDialogOpen(false)}
                handleOk={handleUploadKomtek}
                okText={tr(TEXT_IMPORT_KOMTEK_FILES)}
            ></ContractDialog>
        </Paper>
    )
}
