import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import LayoutDefault from "common/layout/layout-default"
import TitleToolbar from "common/title-toolbar/title-toolbar"
import Navigation from "common/navigation/navigation"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_CONTRACT,
    TEXT_CREATE_CREDIT_NOTE,
    TEXT_CREATE_CREDIT_NOTE_FOR_LINES,
    TEXT_INVOICE_LINE_SELECTED,
    TEXT_EDIT_CONTRACT_PRODUCTS,
    TEXT_SAVE,
    TEXT_PRODUCTS_TO_UPDATE,
    TEXT_SYNC_ERP,
    TEXT_NOT_INVOICED_LINES,
} from "../../utils/translations/keys"
import { RouteComponentProps } from "@reach/router"
import { makeStyles, Theme, Button, Grid } from "@material-ui/core"
import { ContractMainInfo } from "./contract-main-info"
import { ContractProducts } from "./contract-products"
import { ContractInvoices } from "./contract-invoices"
import { ContractAudit } from "./contract-audit"
import Spinner from "common/spinner"
import { AppState } from "../../redux/app/app-store"
import { actionLoadRefunds } from "../../redux/refund/refund-actions"
import { InvoiceLine } from "models/invoice"
import { Contract, ContractProduct } from "models/contract"
import ContractClient from "clients/contract-client"
import { RefundDialog } from "./refund-dialog"
import { getMatrikkelNr } from "utils/contract-utils"
import { CreateInvoiceDialog } from "./create-invoice-dialog"
import { ContractProductsEdit } from "./contract-products-edit"
import { ContractProductUpdateSet } from "../../models/contract-product-update-set"
import {
    actionContractGet,
    actionLoadContractInvoices,
    actionUpdateContractCustomTerms,
    actionUpdateContractSerialNumberSuccess,
    actionUpdateSelectedContractInvoices,
} from "../../redux/contract/contract-actions"

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        auditGrid: {
            padding: theme.spacing(1),
            overflow: "auto",
        },
        createInvoiceButton: {
            marginRight: "1rem",
            background: "white",
        },
        button: {
            marginLeft: "1rem",
        },
    }
})

export const ContractDetailsPage: React.FC<
    RouteComponentProps<{
        contractId: string
        location: any
    }>
> = props => {
    const { contractId = "" } = props

    const [updateCount, setUpdateCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false)
    const [refundDialogOpen, setRefundDialogOpen] = useState(false)
    const [createInvoiceOpen, setCreateInvoiceOpen] = useState(false)
    const [isCreditNoteModeEnabled, setCreditNoteModeEnabled] = useState(false)
    const [productsEditMode, setProductsEditMode] = useState(false)
    const [productsUpdateSet, setProductsUpdateSet] = useState([] as ContractProductUpdateSet[])
    const [productsUpdateSetInvalid, setProductsUpdateSetInvalid] = useState(false)
    const forceUpdate = () => {
        return setUpdateCount(updateCount => updateCount + 1)
    }

    const isEditMode = productsEditMode || isCreditNoteModeEnabled
    const dispatch = useDispatch()
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
    const contract = useSelector((state: AppState) => state.contracts.selectedContract)
    const invoiceLines = useSelector((state: AppState) => state.contracts.selectedContractInvoiceLines)
    const selectedInvoiceLinesCount = useSelector((state: AppState) => state.contracts.selectedContractInvoiceLines.filter(x => x.isSelected).length)
    const unSentInvoiceLines = useSelector((state: AppState) => state.contracts.selectedContractUnsentInvoiceLines)
    const isEditProductsAllowed = tenantConfig?.isEditProductsInContractAllowed || false

    const getData = async () => {
        await loadInvoices()
    }

    const loadContract = () => {
        dispatch(actionContractGet(contractId))
    }

    const loadInvoices = async () => {
        dispatch(actionLoadContractInvoices(contractId))
    }

    const updateProductsUpdateSet = (productsToUpdate: ContractProductUpdateSet[]) => {
        setProductsUpdateSetInvalid(false)
        productsToUpdate.forEach(x => {
            if (x.quantity < 0 || x.numerator < 0 || x.denominator < 1) {
                setProductsUpdateSetInvalid(true)
            }
        })
        setProductsUpdateSet(productsToUpdate)
        forceUpdate()
    }

    useEffect(() => {
        setIsLoading(true)
        loadContract()
        getData()
        setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUseCustomTermChanged = (useCustomTerms: boolean) => {
        dispatch(
            actionUpdateContractCustomTerms(contract.id, useCustomTerms, contract.periodType, contract.selectedPeriods)
        )
    }

    const handleSelectedPeriodTypeChanged = (periodType: string) => {
        dispatch(
            actionUpdateContractCustomTerms(contract.id, contract.useCustomTerms, periodType, contract.selectedPeriods)
        )
    }

    const handleSelectedPeriodsChanged = (selectedPeriods: number[]) => {
        dispatch(
            actionUpdateContractCustomTerms(contract.id, contract.useCustomTerms, contract.periodType, selectedPeriods)
        )
    }

    const handleSerialNumberChanged = (serialNumber: string) => {
        dispatch(actionUpdateContractSerialNumberSuccess(serialNumber))
    }

    const handleCreateInvoiceClose = () => {
        clearSelectedInvoiceLines()
        setCreateInvoiceOpen(false)
    }

    const clearSelectedInvoiceLines = () => {
        dispatch(actionUpdateSelectedContractInvoices(invoiceLines.map(x => ({ id: x.id,
             isSelected: false, 
             lineNo: x.lineNo})), tenantConfig?.isRefundSingleInvoiceLineEnabled ?? false))
    }

    const handleCreateInvoiceOk = async () => {
        setCreateInvoiceOpen(false)
        await loadInvoices()
    }

    const handleRunRefund = () => {
        setRefundDialogOpen(true)
    }

    const saveProducts = async () => {
        setIsLoading(true)
        await ContractClient.updateProducts(contractId, productsUpdateSet).toPromise()
        setProductsEditMode(false)
        setProductsUpdateSet([] as ContractProductUpdateSet[])
        loadContract()
        setIsLoading(false)
        await loadInvoices()
    }

    const syncContractWithERP = async () => {
        setIsLoading(true)
        const item = await ContractClient.syncContractWithErp(contractId).toPromise()
        loadContract()
        setIsLoading(false)
        await loadInvoices()
    }

    const handleRunRefundClose = () => {
        clearSelectedInvoiceLines()
        setRefundDialogOpen(false)
        setCreditNoteModeEnabled(false)
    }

    const handleRunRefundOk = (andCopy: boolean) => {
        setRefundDialogOpen(false)
        dispatch(actionLoadRefunds())
        if (andCopy) {
            setCreateInvoiceOpen(true)
        }

        loadInvoices()
        setCreditNoteModeEnabled(false)
    }

    const handleRefundMode = () => {
        setCreditNoteModeEnabled(true)
    }

    const beginProductsEditMode = () => {
        setProductsEditMode(true)
    }

    const endProductsEditMode = () => {
        setProductsEditMode(false)
        setProductsUpdateSet([] as ContractProductUpdateSet[])
    }

    const getContractMatrikkelNumber = (contract: Contract | null) => {
        return !!contract
            ? `${!!contract.cityId ? `${String(contract.cityId).padStart(4, "0")}- ` : ""}${getMatrikkelNr(
                  contract.gnr,
                  contract.bnr,
                  contract.fnr,
                  contract.snr
              )}`
            : tr(TEXT_CONTRACT)
    }

    const styles = useStyles()

    const renderEditProductButtons = () => {
        return (
            <>
                {!productsEditMode && (
                    <>
                        <Button
                            variant="outlined"
                            color="inherit"
                            className={styles.button}
                            onClick={beginProductsEditMode}
                        >
                            {tr(TEXT_EDIT_CONTRACT_PRODUCTS)}
                        </Button>
                    </>
                )}

                {productsEditMode && (
                    <>
                        <Button
                            variant="outlined"
                            color="inherit"
                            className={styles.button}
                            onClick={saveProducts}
                            disabled={productsUpdateSet.length === 0 || productsUpdateSetInvalid}
                        >
                            {tr(TEXT_SAVE)}
                        </Button>
                    </>
                )}
            </>
        )
    }

    const renderRefundButtons = () => {
        return isCreditNoteModeEnabled ? (
            <Button
                variant="outlined"
                color="inherit"
                className={styles.button}
                onClick={handleRunRefund}
                disabled={selectedInvoiceLinesCount === 0}
            >
                {tr(TEXT_CREATE_CREDIT_NOTE_FOR_LINES)}
            </Button>
        ) : (
            <Button
                variant="outlined"
                color="inherit"
                className={styles.button}
                onClick={handleRefundMode}
            >
                {tr(TEXT_CREATE_CREDIT_NOTE)}
            </Button>
        )
    }

    const renderSyncWithErpButton = () => {
        return (
            <>
                <Button
                    variant="outlined"
                    color="inherit"
                    className={styles.button}
                    onClick={syncContractWithERP}
                    disabled={unSentInvoiceLines?.length === 0}
                >
                    {tr(TEXT_SYNC_ERP)}
                </Button>
            </>
        )
    }

    const toolbar = (
        <>
            {!isEditMode && (
                <TitleToolbar title={getContractMatrikkelNumber(contract)} enableGoBack={true}>
                    {tenantConfig?.isContractBasedInvoicing && renderSyncWithErpButton()}
                    {isEditProductsAllowed && renderEditProductButtons()}
                    {renderRefundButtons()}
                </TitleToolbar>
            )}
            {isCreditNoteModeEnabled && (
                <TitleToolbar
                    title={`${selectedInvoiceLinesCount} ${tr(TEXT_INVOICE_LINE_SELECTED)}`}
                    enableClose={true}
                    enableGoBack={false}
                    onClose={handleRunRefundClose}
                >
                    {renderRefundButtons()}
                </TitleToolbar>
            )}

            {productsEditMode && (
                <TitleToolbar
                    title={`${productsUpdateSet.length} ${tr(TEXT_PRODUCTS_TO_UPDATE)}`}
                    enableClose={true}
                    enableGoBack={false}
                    onClose={endProductsEditMode}
                >
                    {renderEditProductButtons()}
                </TitleToolbar>
            )}
        </>
    )

    const sentInvoices = invoiceLines.filter(x => !!x.transferDate)

    return (
        <LayoutDefault
            navigation={<Navigation />}
            toolbar={toolbar}
            headerBackgroundColor={isEditMode ? "#757575" : ""}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {contract && (
                        <Grid container className={styles.root} spacing={2}>
                            <Grid item xs={9}>
                                <ContractMainInfo
                                    handleUseCustomTermChanged={handleUseCustomTermChanged}
                                    handleSelectedPeriodTypeChanged={handleSelectedPeriodTypeChanged}
                                    handleSelectedPeriodsChanged={handleSelectedPeriodsChanged}
                                    handleSerialNumberChanged={handleSerialNumberChanged}
                                />
                                {contract.residentialInfo && (
                                    <>
                                        {!productsEditMode && (
                                            <ContractProducts
                                                residentialInfo={contract.residentialInfo}
                                                isProductBasedContract={contract.isProductBasedContract}
                                            />
                                        )}
                                        {productsEditMode && (
                                            <ContractProductsEdit
                                                residentialInfo={contract.residentialInfo}
                                                isProductBasedContract={contract.isProductBasedContract}
                                                onProductsUpdated={updateProductsUpdateSet}
                                            />
                                        )}
                                    </>
                                )}
                                {unSentInvoiceLines?.length > 0 && (
                                    <ContractInvoices
                                        title={tr(TEXT_NOT_INVOICED_LINES)}
                                        invoiceLines={unSentInvoiceLines}
                                        isLoadingInvoices={isLoadingInvoices}
                                    />
                                )}
                                {sentInvoices?.length > 0 && (
                                    <ContractInvoices
                                        title={tr("TEXT_HOUSINGUNIT_INVOICE_LINES")}
                                        invoiceLines={sentInvoices}
                                        isLoadingInvoices={isLoadingInvoices}
                                        refundMode={isCreditNoteModeEnabled}
                                    />
                                )}
                            </Grid>
                            <Grid className={styles.auditGrid}>
                                <ContractAudit contractId={contractId} />
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            {!!contract && (
                <>
                    <RefundDialog
                        contract={contract}
                        invoiceLines={invoiceLines.filter(x => x.isSelected)}
                        open={refundDialogOpen}
                        handleClose={handleRunRefundClose}
                        handleOk={handleRunRefundOk}
                    ></RefundDialog>
                    <CreateInvoiceDialog
                        contract={contract}
                        open={createInvoiceOpen}
                        handleClose={handleCreateInvoiceClose}
                        handleOk={handleCreateInvoiceOk}
                    ></CreateInvoiceDialog>
                </>
            )}
        </LayoutDefault>
    )
}
