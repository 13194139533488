import { CreateInvoicesRequest } from "models/create-invoice"
import { InvoiceCreatePeriod } from "models/invoice-create-period"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost } from "services/httpService"

export default class InvoiceClient {
    public static getCreateInvoicePeriod(contractId: string, refundId: string): Observable<InvoiceCreatePeriod> {
        return httpGet<InvoiceCreatePeriod>(
            apiUrl(`/Invoices/create-invoice-period?contractId=${contractId}&refundedInvoiceId=${refundId}`)
        )
    }
    public static createInvoiceLines(request: CreateInvoicesRequest): Observable<void> {
        return httpPost<void>(apiUrl(`/Invoices/create-invoice-lines`), request)
    }
}
