import { combineEpics } from "redux-observable"
import { EMPTY, Observable, ObservedValueOf, of, OperatorFunction } from "rxjs"
import { catchError, flatMap, tap, withLatestFrom, filter } from "rxjs/operators"
import { AnyAction } from "redux"
import { actionShowError, ACTION_GO_BACK } from "./app-actions"
import { productEpics } from "../../redux/product/product-epics"
import { refundEpics } from "../../redux/refund/refund-epics"
import { invoiceFileEpics } from "../../redux/invoice-file/invoice-file-epics"
import { vatCodeEpics } from "../../redux/vatCode/vatCode-epics"
import { contractEpics } from "../../redux/contract/contract-epics"
import { authEpics } from "../../redux/auth/auth-epics"
import { configEpics } from "../../redux/config/config-epics"
import { municipalityEpics } from "../../redux/municipality/municipality-epics"
import { periodStatisticsEpics } from "../../redux/dashboard/period-statistics-epics"
import { billingConfigEpics } from "../../redux/billing-config/billing-config-epics"
import { invoiceStatisticsEpics } from "../../redux/dashboard/invoice-statistics-epics"
import { billingJobEpics } from "../../redux/billing-job/billing-job-epics"

export const defaultCatchError = (
    message?: string
): OperatorFunction<AnyAction, ObservedValueOf<AnyAction> | AnyAction> =>
    catchError((error): Observable<AnyAction> => {
        console.error(error)
        return of(actionShowError(message))
    })

export const logEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        withLatestFrom(state$),
        tap(([action, state]) => console.log(action.type, { action, state })),
        flatMap(() => EMPTY)
    )

export const goBackEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter(x => x.type === ACTION_GO_BACK),
        flatMap(() => {
            window.history.back()
            return EMPTY
        })
    )

export const appEpics = combineEpics(
    logEpic,
    goBackEpic,
    authEpics,
    productEpics,
    contractEpics,
    refundEpics,
    invoiceFileEpics,
    vatCodeEpics,
    configEpics,
    municipalityEpics,
    periodStatisticsEpics,
    billingConfigEpics,
    invoiceStatisticsEpics,
    billingJobEpics
)
