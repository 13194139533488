import { Grid, Theme, makeStyles } from "@material-ui/core";
import { InvoicePeriodStatisticProduct } from "models/invoice-period-statistic";
import React, { useState } from "react";
import { TEXT_PRODUCT_NUMBER } from "utils/translations/keys";
import { tr } from "utils/translations/translate";

const useStyles = makeStyles((theme: Theme) => {
    return {
      container: {
        margin: "8px",
      },
      header: {
        padding: "0 62px 0 24px"
      },
      detailsHeader: {
        paddingRight: "38px"
      },
      numericColumn: {
        textAlign: "right"
      },
      expansionPanel: {
        borderRadius: "0!important",
        boxShadow: "none",
        background: "transparent",
        margin: "6px 0 0 0",
        "&::before": {
          display: "none"
        },
        "&.Mui-expanded": {
          margin: "6px 0 0 0"
        },
        "& > .MuiExpansionPanelSummary-root": {
          background: "white",
          "&.Mui-expanded": {
            minHeight: "initial",
            margin: "initial"
          },
          "& > .MuiExpansionPanelSummary-content.Mui-expanded": {
            margin: 0
          }
        },
        "& > .MuiCollapse-container": {
          marginTop: "2px",
          background: "white"
        }
      },
      tableRow: {
        "&:last-child td": {
          borderBottom: 0
        },
        "&:hover": {
          cursor: "pointer"
        }
      },
      sendToErp: {
        marginTop: "1rem"
      },
      subtotals: {
        borderTop: "1px #f0f0f0 solid",
        paddingTop: "0.25rem",
        marginTop: "0.25rem"
      }
    };
  });

export const ProductTable : React.FC<{products: InvoicePeriodStatisticProduct[], isRefund: boolean, showSubtotals: boolean}> = props => {

    const styles = useStyles();
    const {products, isRefund, showSubtotals } = props
    const totalNet = products.reduce((sum, x) => sum + x.invoiceNetTotal, 0);
    const totalVat = products.reduce((sum, x) => sum + x.invoiceVatTotal, 0);
    const total = products.reduce((sum, x) => sum + x.invoiceTotal, 0);

    return (
      <>
        <Grid container className={styles.detailsHeader} style={{ marginBottom: "0.5rem" }}>
          <Grid item xs={1}>
            <strong>{tr(TEXT_PRODUCT_NUMBER)}</strong>
          </Grid>
          <Grid item xs={2}>
            {!isRefund && <strong>{tr("TEXT_PRODUCTS_INVOICED")}</strong>}
            {isRefund && <strong>{tr("TEXT_PRODUCTS_REFUNDED")}</strong>}
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            <strong>{tr("TEXT_UNIT_PRICE")}</strong>
          </Grid>
          <Grid item xs={1} className={styles.numericColumn}>
            {!isRefund && <strong>{tr("TEXT_INVOICES_PRODUCT_NUMBER")}</strong>}
            {isRefund && <strong>{tr("TEXT_REFUNDS_NUMBER")}</strong>}
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            <strong>{tr("TEXT_NETAMOUNT")}</strong>
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            <strong>{tr("TEXT_VATAMOUNT")}</strong>
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            <strong>{tr("TEXT_AMOUNT")}</strong>
          </Grid>
        </Grid>
        {products.map((product, index: number) =>
          <Grid container key={product.productName + index} className={styles.detailsHeader}>
            <Grid item xs={1}>
              {product.productNumber}
            </Grid>
            <Grid item xs={2}>
              {product.productName}
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {product.productPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
            <Grid item xs={1} className={styles.numericColumn}>
              {product.invoiceCount}
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {product.invoiceNetTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {product.invoiceVatTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {product.invoiceTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
          </Grid>
        )}
        {showSubtotals && <Grid container className={`${styles.subtotals} ${styles.detailsHeader}`}>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            {totalNet.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            {totalVat.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
          </Grid>
          <Grid item xs={2} className={styles.numericColumn}>
            {total.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
          </Grid>
        </Grid>
        }
      </>
    )
  }