export type Product = {
    name: string
    description: string
    price: number
    type: { label: string; value: number }
    validFrom: string
    validTo: string
    vatCode: string
    vatPercentage: number
    status: { label: string; value: number }
    productId: number
    vatCodeId: string
    productBillingType: string
    id: string
    periodType: string
    selectedPeriods: number[]
    needsInvoicesUpdate: boolean
    priceType: number
    otherPriceTypeValue: string
    perTimePriceTypeValue: number
}

export type ProductPeriodSelection = {
    label: string
    value: number
}

export enum ProductPriceType {
    FixedPrice = "1",
    PerKm = "2",
    PerTime = "3",
    PerOther = "4",
}

export enum ProductPerTimeValues {
    TenMinutes = "10",
    FifteenMinutes = "15",
    HalfAnHourMinutes = "30",
    OneHourMinutes = "60",
}
