import { BillingJobProgress } from "models/billing-jobs"
import { AnyAction } from "redux"

export const ACTION_LOAD_INVOICE_UPDATE_PROGRESS = "ACTION_LOAD_INVOICE_UPDATE_PROGRESS"
export const ACTION_LOAD_INVOICE_UPDATE_PROGRESS_SUCCESS = "ACTION_LOAD_INVOICE_UPDATE_PROGRESS_SUCCESS"

export const actionLoadInvoiceUpdateProgressSuccess = (InvoiceUpdateProgress: BillingJobProgress): AnyAction => ({
    type: ACTION_LOAD_INVOICE_UPDATE_PROGRESS_SUCCESS,
    InvoiceUpdateProgress,
})

export const actionLoadInvoiceUpdateProgress = (): AnyAction => ({
    type: ACTION_LOAD_INVOICE_UPDATE_PROGRESS,
})
