import * as keys from "./keys"
export const en: { [index: string]: string } = {}

en[keys.TEXT_FIXED_PRICE] = "Fixed price"
en[keys.TEXT_PER_KM] = "Per km"
en[keys.TEXT_PER_TIME] = "Per time"
en[keys.TEXT_PER_OTHER] = "Per other"
en[keys.TEXT_10_MINUTES] = "10 minutes"
en[keys.TEXT_15_MINUTES] = "15 minutes"
en[keys.TEXT_30_MINUTES] = "30 minutes"
en[keys.TEXT_ONE_HOUR] = "1 hour"
en[keys.TEXT_PRICE_TYPE] = "Price type"
en[keys.TEXT_PRICE_PER_TIME_RESOLUTION] = "Resolution"
en[keys.TEXT_OTHER_PRICE_TYPE_VALUE] = "Unit"
en[keys.TEXT_CHANGES_SAVED_SUCCESSFULLY] = "Changes saved successfully"
