import { Grid } from "@material-ui/core"
import React from "react"
import { Field } from "redux-form"
import {
    TEXT_REFERENCE_CODE,
    TEXT_RESPONSIBLE,
    TEXT_SALESMAN,
    TEXT_UNIT_CODE,
    TEXT_VOUCHER_TYPE,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"

export const AgressoConfigControls = props => {
    const { fieldWidth, editMode } = props

    console.log(fieldWidth)
    return (
        <Grid container spacing={4} direction="row">
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="agressoFileConfiguration.referenceCodeDr"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_REFERENCE_CODE)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="agressoFileConfiguration.responsible"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_RESPONSIBLE)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="agressoFileConfiguration.salesman"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_SALESMAN)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="agressoFileConfiguration.voucherType"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_VOUCHER_TYPE)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="agressoFileConfiguration.unitCode"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_UNIT_CODE)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
        </Grid>
    )
}
