import { InvoiceStatisticState } from "models/invoice-statistic"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import { ACTION_LOAD_INVOICE_STATISTICS_SUCCESS } from "./invoice-statistics-actions"

type InvoiceStatisticReducer = ActionReducer<InvoiceStatisticState>

const loadInvoiceStatisticsSuccess: InvoiceStatisticReducer = (state, action) => {
    return {
        ...state,
        invoiceStatistic: action.statistics,
    }
}

const reducers: Reducers<InvoiceStatisticState> = {
    [ACTION_LOAD_INVOICE_STATISTICS_SUCCESS]: loadInvoiceStatisticsSuccess,
}

const initialState = {
    invoiceStatistic: {},
}

export const invoiceStatisticReducer = makeReducer<InvoiceStatisticState>(reducers, initialState)
