import {
    Grid,
} from "@material-ui/core"
import React from "react"
import { Field } from "redux-form"
import {
    TEXT_SERVICE_URL,
    TEXT_USER_NAME,
    TEXT_USER_PASSWORD,
    TEXT_APPLICATION_ID,
    TEXT_CLIENT_ID,
    TEXT_DOMAIN,
    TEXT_PRODUCT_GROUP,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"


export const IsyPaConfigControls = props => {
    const { fieldWidth, editMode } = props


    return <Grid container spacing={4} direction="row">
    <Grid item xs={fieldWidth}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.serviceUrl"
            label={tr(TEXT_SERVICE_URL)}
            component={BillingTextFieldAdapter}
        />
    </Grid>
    <Grid item xs={8}></Grid>
    <Grid item xs={4}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.userName"
            label={tr(TEXT_USER_NAME)}
            component={BillingTextFieldAdapter}
        />
    </Grid>
    <Grid item xs={4}>
        {editMode && (
            <Field
                editMode={editMode}
                name="isyPaConfiguration.password"
                label={tr(TEXT_USER_PASSWORD)}
                component={BillingTextFieldAdapter}
                type="password"
            />
        )}
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={fieldWidth}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.applicationId"
            label={tr(TEXT_APPLICATION_ID)}
            component={BillingTextFieldAdapter}
        ></Field>
    </Grid>
    <Grid item xs={8}></Grid>
    <Grid item xs={fieldWidth}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.clientId"
            label={tr(TEXT_CLIENT_ID)}
            component={BillingTextFieldAdapter}
            type="number"
        ></Field>
    </Grid>
    <Grid item xs={8}></Grid>
    <Grid item xs={fieldWidth}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.domain"
            label={tr(TEXT_DOMAIN)}
            component={BillingTextFieldAdapter}
        ></Field>
    </Grid>
    <Grid item xs={8}></Grid>
    <Grid item xs={fieldWidth}>
        <Field
            editMode={editMode}
            name="isyPaConfiguration.productGroup"
            label={tr(TEXT_PRODUCT_GROUP)}
            component={BillingTextFieldAdapter}
        ></Field>
    </Grid>
    <Grid item xs={8}></Grid>
</Grid>
}