import { BillingConfigurationState } from "models/billing-configuration"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import { ACTION_BILLING_CONFIG_GET_SUCCESS } from "./billing-config-action"

type BillingConfigReducer = ActionReducer<BillingConfigurationState>

const getBillingConfigSuccess: BillingConfigReducer = (state, action) => {
    return {
        ...state,
        billingConfig: action.billingConfig,
    }
}

const reducers: Reducers<BillingConfigurationState> = {
    [ACTION_BILLING_CONFIG_GET_SUCCESS]: getBillingConfigSuccess,
}

const initialState = {
    billingConfig: {},
}

export const billingConfigReducer = makeReducer<BillingConfigurationState>(reducers, initialState)
