import { Municipality } from "models/municipality"
import { AnyAction } from "redux"

export const MUNICIPALITY_LOAD_SUCCESS = "MUNICIPALITY_LOAD_SUCCESS"
export const MUNICIPALITY_LOAD = "MUNICIPALITY_LOAD"
export const MUNICIPALITY_CHANGE = "MUNICIPALITY_CHANGE"

export const actionMunicipalityLoadSuccess = (municipalities: Municipality[]): AnyAction => ({
    type: MUNICIPALITY_LOAD_SUCCESS,
    municipalities,
})

export const actionMunicipalityLoad = (): AnyAction => ({
    type: MUNICIPALITY_LOAD,
})

export const actionChangeMunicipality = (municipality: Municipality | undefined): AnyAction => ({
    type: MUNICIPALITY_CHANGE,
    municipality,
})
