/**
 * Returns new sub array from array
 *
 * @example arraySection([1, 2, 3, 4, 5], 3, 2) // [3]
 * @example arraySection([1, 2, 3, 4, 5], 3, 2, true) // [3, 4]
 * @example arraySection([1, 2, 3, 4, 5], 2, 4) // [3, 4]
 * @example arraySection([1, 2, 3, 4, 5], 5, 2) // [3, 4, 5]
 */
export const arraySection = (array: any[], start: number, end: number, includeEnd = false): any[] => {
    const endOffset = includeEnd ? 1 : 0
    return [...array].slice(Math.min(start, end), Math.max(start, end) + endOffset)
}
