import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import moment from "moment"

export const isCurrentPeriod = (period: InvoicePeriodStatistic) => {
    const start = moment(period.startDate)
    const end = moment(period.endDate)
    const now = moment()

    return now >= start && now <= end
}
