import React from 'react'
import { Link, LinkProps } from '@reach/router'
import List from '@material-ui/core/List'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PersonIcon from '@material-ui/icons/Person'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Settings'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GavelIcon from '@material-ui/icons/Gavel';
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles } from '@material-ui/core/styles'

import classNames from 'classnames'
import { tr } from 'utils/translations/translate'
import {
    TEXT_DASHBOARD,
    TEXT_PRODUCTS,
    TEXT_CONTRACT,
    TEXT_LANGUAGE,
    TEXT_LOGOUT,
    //TEXT_SETTINGS,
    TEXT_USER,
    TEXT_SETTINGS
} from 'utils/translations/keys'
import { TEXT_REFUNDS} from 'pages/refund-page/translations/keys'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import config from 'config'
import { Language } from 'shared-components/src/settings/language/language.enum'
import { actionSettingsSetLanguage } from 'shared-components/src/settings/language/language-actions'
import { Button, Typography } from '@material-ui/core'
import { actionAuthLogout } from '../../redux/auth/auth-actions'
import { Protected } from 'common/protected/protected'
import { Permission } from 'common/enums/permission'
import { RotateLeft } from '@material-ui/icons'
import { AppState } from 'redux/app/app-store'

interface AdapterLinkProps extends LinkProps<{}> {
    active: string
}

const useStyles = makeStyles((theme: Theme) => ({
    navigation: {
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        flexFlow: 'column nowrap',
        overflow: 'hidden',
    },
    navigationLinks: {
        flex: 'auto',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    navigationItem: {
        padding: `${theme.spacing(1)}px  ${theme.spacing(3)}px`,
    },
    activeLink: {
        borderRight: `4px solid ${theme.palette.primary.main}`,

        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
        },
    },
    navigationIcon: {
        minWidth: '2rem',
    },
    navigationText: {
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    navigationTextHidden: {
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    userProfileItem: {
        padding: theme.spacing(1),
    },
    languageLabel: {
        paddingRight: theme.spacing(1),
    },
}))

const AdapterExactLink = React.forwardRef<HTMLAnchorElement, AdapterLinkProps>((props, ref) => (
    <Link
        ref={ref as any}
        {...props}
        getProps={({ isCurrent }) =>
            isCurrent
                ? {
                      className: `${props.className} ${props.active} Mui-selected`,
                  }
                : {}
        }
    />
))
// const AdapterPartialLink = React.forwardRef<HTMLAnchorElement, AdapterLinkProps>((props, ref) => (
//     <Link
//         ref={ref as any}
//         {...props}
//         getProps={({ isPartiallyCurrent }) =>
//             isPartiallyCurrent
//                 ? {
//                       className: `${props.className} ${props.active} Mui-selected`,
//                   }
//                 : {}
//         }
//     />
// ))

const Navigation: React.FC = props => {
    const styles = useStyles()

    const dispatch = useDispatch()
    const language = useSelector((state: { settings: { language: Language } }) => state.settings.language)

    const menuExpanded = useSelector((state: { navigation: any }) => state.navigation.menuExpanded)
    const mobileMenuExpanded = useSelector((state: { navigation: any }) => state.navigation.mobileMenuExpanded)
    const expanded = menuExpanded || mobileMenuExpanded

    const user = useSelector((state: AppState) => state.auth.user)

    const [userProfileMenuAnchorEl, setUserProfileMenuAnchorEl] = React.useState(null)

    const userProfileButtonClickHandler = (event: any) => {
        setUserProfileMenuAnchorEl(event.currentTarget)
    }

    const settingsMenuCloseHandler = () => {
        setUserProfileMenuAnchorEl(null)
    }

    const languageChangeHandler = (event: any, language: Language) => {
        if (!language) {
            return
        }
        dispatch(actionSettingsSetLanguage(language))

        // TODO use this in settings epic?
        localStorage.setItem(config.localStorageLangKey, language)

        settingsMenuCloseHandler()
    }

    const logout = () => dispatch(actionAuthLogout())

    return (
        <div className={styles.navigation}>
            <div className={styles.navigationLinks}>
                <List disablePadding>
                    <Protected p={Permission.ProductBilling}>
                        <ListItem
                            button
                            component={AdapterExactLink}
                            to="/"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_DASHBOARD)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.ProductBilling}>
                        <ListItem
                            button
                            component={AdapterExactLink}
                            to="/products"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <BusinessCenterIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_PRODUCTS)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.ProductBilling}>
                        <ListItem
                            button
                            component={AdapterExactLink}
                            to="/contracts"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <GavelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_CONTRACT)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>

                    <Protected p={Permission.ProductBilling}>
                        <ListItem
                            button
                            component={AdapterExactLink}
                            to="/refunds"
                            className={styles.navigationItem}
                            active={styles.activeLink}
                        >
                            <ListItemIcon className={styles.navigationIcon}>
                                <RotateLeft />
                            </ListItemIcon>
                            <ListItemText
                                primary={tr(TEXT_REFUNDS)}
                                className={classNames(styles.navigationText, {
                                    [styles.navigationTextHidden]: !expanded,
                                })}
                            />
                        </ListItem>
                    </Protected>
                </List>
            </div>

            <Divider />

            <List disablePadding>
                <Protected p={Permission.ProductBilling}>
                    <ListItem
                        button
                        component={AdapterExactLink}
                        to="/config"
                        className={styles.navigationItem}
                        active={styles.activeLink}
                    >
                        <ListItemIcon className={styles.navigationIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={tr(TEXT_SETTINGS)}
                            className={classNames(styles.navigationText, {
                                [styles.navigationTextHidden]: !expanded,
                            })}
                        />
                    </ListItem>
                </Protected>

                <ListItem button className={styles.navigationItem} onClick={userProfileButtonClickHandler}>
                    <ListItemIcon className={styles.navigationIcon}>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={tr(TEXT_USER)}
                        className={classNames(styles.navigationText, {
                            [styles.navigationTextHidden]: !expanded,
                        })}
                    />
                </ListItem>

                <Menu
                    id="simple-menu"
                    anchorEl={userProfileMenuAnchorEl}
                    keepMounted
                    open={Boolean(userProfileMenuAnchorEl)}
                    onClose={settingsMenuCloseHandler}
                    MenuListProps={{ disablePadding: true }}
                >
                    <Grid container className={styles.userProfileItem} justify="center">
                        <Grid item>
                            <Typography variant="subtitle1">{user && user.name}</Typography>
                        </Grid>
                    </Grid>

                    <Divider></Divider>

                    <Grid container className={styles.userProfileItem} alignItems="center" justify="center">
                        <Grid item className={styles.languageLabel}>
                            {tr(TEXT_LANGUAGE)}:
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup size="small" value={language} exclusive onChange={languageChangeHandler}>
                                <ToggleButton key={1} value={Language.English}>
                                    EN
                                </ToggleButton>
                                <ToggleButton key={0} value={Language.Norwegian}>
                                    NO
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>

                    <Divider></Divider>

                    <Grid container className={styles.userProfileItem}>
                        <Grid item xs={12}>
                            <Button fullWidth={true} onClick={logout}>
                                {tr(TEXT_LOGOUT)}
                            </Button>
                        </Grid>
                    </Grid>
                </Menu>

                {/* <Protected p={Permission.PageSettings}>
                    <ListItem
                        button
                        component={AdapterPartialLink}
                        to="/settings"
                        className={styles.navigationItem}
                        active={styles.activeLink}
                    >
                        <ListItemIcon className={styles.navigationIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={tr(TEXT_SETTINGS)}
                            className={classNames(styles.navigationText, {
                                [styles.navigationTextHidden]: !expanded,
                            })}
                        />
                    </ListItem>
                </Protected> */}
            </List>
        </div>
    )
}

export default Navigation
