import { makeStyles, Paper, Table, TableBody, TableCell, TableRow, Theme } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { navigate } from "@reach/router";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/app/app-store";
import { formatString } from "utils/general-utils";
import { tr } from "utils/translations/translate";

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      padding: "4px",
      marginTop: "1rem",

    },
    tableRow: {
      "&:last-child td": {
        borderBottom: 0
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    title: {
      margin: 0
    },
    arrow: {
      fontSize: "1rem",
      color: "#999"
    }
  };
});

export const PeriodYearsBlock = () => {
  const styles = useStyles();

  const periodConfigs = useSelector((state: AppState) => state.config.periodConfigurations);

  if (!periodConfigs) {
    return (<span></span>);
  }

  return (
    <Paper className={styles.paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell><h3 className={styles.title}>{tr("TEXT_TERMS")}</h3></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {periodConfigs.map(yearConfig =>
            <TableRow hover className={styles.tableRow} key={`period_${yearConfig.year}`} onClick={() => navigate(`/period-config/${yearConfig.year}`)}>
             <TableCell style={{ width: "30%" }}>{yearConfig.year}</TableCell>
             <TableCell>{Object.keys(yearConfig.periods).length > 0 ? formatString(tr("TEXT_PERIODCONFIG_TERMS"), Object.keys(yearConfig.periods).length) : tr("TEXT_PERIODCONFIG_NOTERMS")}</TableCell>
             <TableCell align="right"><ArrowForwardIos className={styles.arrow} /></TableCell>
           </TableRow>
          )}          
        </TableBody>
      </Table>
    </Paper>
  );
}