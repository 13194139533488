import React, { useState } from "react";
import LayoutDefault from "common/layout/layout-default";
import { tr } from "../../utils/translations/translate";
import {
  TEXT_FILTER,
  TEXT_FILTER_REFUNDS_PLACEHOLDER,
  TEXT_REFUNDS,
  TEXT_REFUNDS_PUBLISH,
  TEXT_REFUND_LINE_SELECTED,
  TEXT_PUBLISH_REFUNDS,
  TEXT_REFUNDS_DELETE,
  TEXT_REFUNDS_OK,
  TEXT_REFUNDS_DELETE_DIALOG_TITLE,
} from "./translations/keys";
import Navigation from "common/navigation/navigation";
import { RouteComponentProps } from "@reach/router";

import {
  makeStyles,
  Theme,
  TextField,
  InputAdornment,
  IconButton,
  Button
} from "@material-ui/core";
import { includes, filter } from "lodash";
import { Clear } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/app/app-store";
import TitleToolbar from "common/title-toolbar/title-toolbar";
import { RefundTable } from "./refund-table";
import { ContractDialog } from "pages/contract-page/contract-dialog";
import { actionRefundDelete, actionLoadRefunds} from "../../redux/refund/refund-actions";
import { Refund, RefundLine } from "models/refund";
import RefundClient from 'clients/refund-client';

const useStyles = makeStyles((theme: Theme) => ({
  refundFilter: {
    margin: theme.spacing(3, 0, 4, 0)
  }
}));

export type RefundSelectedLineType = {
  InvoiceId: string;
  Selected: boolean;
}

const RefundPage: React.FC<RouteComponentProps> = () => {
  
  const styles = useStyles();
  const [filterText, setFilterText] = useState('');
  const [refundSelectionMode, setRefundSelectionMode] = useState(false);
  const [selectedLines, setSelectedLines] = useState<RefundSelectedLineType[]>([]);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const selectedLinesCount = selectedLines.length; 
  const dispatch = useDispatch();

  const allRefunds: Refund[] =
    useSelector((state: AppState) => state.refunds.items) || {};

  const refundLines: RefundLine[] = [];

  for (let index = 0; index < allRefunds.length; index++) {
    
    const r = allRefunds[index];
    
    for (let index = 0; index < r.lines.length; index++) {
      const l = r.lines[index];

      refundLines.push({
                  id : r.id,
                  refundDate: r.refundDate,
                  orderNumber: r.orderNumber,
                  identification: r.identification,
                  customerName: r.customerName,
                  caseNumber: r.caseNumber,
                  address: l.address,
                  appartmentNumber: l.appartmentNumber,
                  customerId: r.customerId,
                  amount: l.amount,
                  lineNumber: l.lineNumber,
                  message: r.message,
                  refundResult: r.refundResult,
                  selected: false
              })
    }
  }

  const refunds = filterText
    ? filter(refundLines, (el) => {
        
      const filterTextCompare = filterText.toUpperCase();
      return (
          includes(el.refundDate?.toString().toUpperCase(), filterTextCompare) ||
          includes(el.appartmentNumber?.toString(), filterTextCompare) ||
          includes(el.customerId?.toString(), filterTextCompare) ||
          includes(el.customerName?.toString(), filterTextCompare) ||
          includes(el.caseNumber?.toString(), filterTextCompare) ||
          includes(el.identification?.toString(), filterTextCompare) ||
          includes(el.amount?.toString(), filterTextCompare) ||
          includes(el.address?.toUpperCase(), filterTextCompare) ||
          includes(el.lineNumber?.toString().toUpperCase(), filterTextCompare) ||
          includes(el.orderNumber?.toString().toUpperCase(), filterTextCompare)
        );
      })
      : refundLines;

  const handleRunRefund = () => {
    
    if(refundSelectionMode)
    {
      setRefundDialogOpen(true);
    }
    else
    {
      setRefundSelectionMode(true);
    }
  };

  const handleRunRefundDelete = () => {

    if(refundSelectionMode)
    {
      setDeleteDialogOpen(true);
    }
    else
    {
      setRefundSelectionMode(true);
    }
  }

  const handleDeleteRefundClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleRunRefundClose = () => {
    setRefundDialogOpen(false);
  };

  const handleRunRefundOk = async () => {
    
    const publishIds = { publishRefundIds: Array.from(selectedLines, x => x.InvoiceId)}
    
    const refundsResult = await RefundClient.publish(publishIds).toPromise();
    if(refundsResult){
      let selectedRefundLines: Array<RefundSelectedLineType> = [];
      publishIds.publishRefundIds.forEach(selectedRefundId => {
        var refundLineNotRefund = refundsResult.find(f => selectedRefundId === f.id );
        if(refundLineNotRefund) {
          selectedRefundLines.push({Selected: true, InvoiceId: selectedRefundId})
        }
      });
      dispatch(actionLoadRefunds());
      setSelectedLines(selectedRefundLines);
      setRefundDialogOpen(false);
    }
  };

const handleCheckedChange = (row: any) => {
  const updatedSelectedLines: RefundSelectedLineType[] = [...selectedLines];

  const foundLineIndex = updatedSelectedLines.findIndex(
    (line: RefundSelectedLineType) => line.InvoiceId === row.id
  );

  if (foundLineIndex === -1) {
      updatedSelectedLines.push({
      InvoiceId: row.id,
      Selected: true
    })
  }
  else
  {
    updatedSelectedLines.splice(foundLineIndex, 1);
  }

  setSelectedLines(updatedSelectedLines);
  setRefundSelectionMode(updatedSelectedLines.length > 0);
}

const handleAllCheckedChange = () => {
  var updatedSelectedLines = selectedLines.length === 0 ? refunds.map(x => ({
    InvoiceId: x.id,
    Selected: true
  })) : [];

  setSelectedLines(updatedSelectedLines);
  setRefundSelectionMode(updatedSelectedLines.length > 0);
}

const handleDeleteRefunds = () => {
  
  selectedLines.forEach(el => {
    dispatch(actionRefundDelete(el.InvoiceId));
  });

  setSelectedLines([]);
  setRefundSelectionMode(false);
  setDeleteDialogOpen(false);
}

const toolbar = (
    <TitleToolbar
      title={refundSelectionMode ? 
        `${selectedLinesCount} ${tr(TEXT_REFUND_LINE_SELECTED)}`: tr(TEXT_REFUNDS)
      }
      enableGoBack={!refundSelectionMode}
      enableClose={refundSelectionMode}
      onClose={() => setRefundSelectionMode(false)}
    >
      
      {refundSelectionMode ? 
      <Button
        variant="text"
        color="inherit"
        onClick={handleRunRefundDelete}
        disabled={!refundSelectionMode}
      >
         {tr(TEXT_REFUNDS_DELETE)}
      </Button> :  <></>}

      <Button
        variant="text"
        color="inherit"
        onClick={handleRunRefund}
        disabled={!refundSelectionMode || selectedLinesCount === 0}
      >
         {tr(TEXT_REFUNDS_PUBLISH)}
      </Button>
    </TitleToolbar>
  );
      return  (
      <LayoutDefault navigation={<Navigation />} toolbar={toolbar} 
      headerBackgroundColor={refundSelectionMode ? "#757575" : ""} 
      >
      <TextField
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
        label={tr(TEXT_FILTER)}
        placeholder={tr(TEXT_FILTER_REFUNDS_PLACEHOLDER)}
        margin="normal"
        variant="outlined"
        className={styles.refundFilter}
        InputProps={{
          endAdornment: filterText && (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setFilterText('')}>
                <Clear fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <RefundTable 
        refunds={refunds}
        onCheckAll={handleAllCheckedChange}
        onCheckedChanged={handleCheckedChange} 
        selectedLines={selectedLines} />

      <ContractDialog
        title={tr(TEXT_PUBLISH_REFUNDS)}
        open={refundDialogOpen}
        handleClose={handleRunRefundClose}
        handleOk={handleRunRefundOk}
        okText={tr(TEXT_REFUNDS_OK)}
        
      >
      </ContractDialog>
      <ContractDialog
        title={tr(TEXT_REFUNDS_DELETE_DIALOG_TITLE)}
        open={deleteDialogOpen}
        handleClose={handleDeleteRefundClose}
        handleOk={handleDeleteRefunds}
        okText={tr(TEXT_REFUNDS_OK)}
      >
        </ContractDialog>
    </LayoutDefault>)
};

export { RefundPage };
