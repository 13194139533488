import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs";
import { flatMap, map, withLatestFrom } from "rxjs/operators";
import { AnyAction } from "redux";
import {
    ActionLoadRefundsType, actionLoadRefundSuccess,
    ActionRefundPublishType, actionRefundPublishSuccess,
    ActionRefundDeleteType, actionRefundDeleteSuccess
} from "./refund-actions";
import { defaultCatchError } from "../../redux/app/app-epics";
import RefundClient from "clients/refund-client";

export const loadRefundsEpic = (
    action$: Observable<AnyAction>,
    state$: Observable<any>
) => {
    return action$.pipe(
        ofType(ActionLoadRefundsType),
        withLatestFrom(state$),
        flatMap(([action, state]): Observable<AnyAction> =>
            RefundClient.getQueuedRefunds().pipe(
                map(refunds => actionLoadRefundSuccess(refunds)),
                defaultCatchError()
            )
        )
    );
};

export const publishRefund = (
    action$: Observable<AnyAction>
): Observable<AnyAction> =>
    action$.pipe(
        ofType(ActionRefundPublishType),
        flatMap(
            (action): Observable<AnyAction> => {
                return RefundClient.publish(action.refundPublish).pipe(
                    map(() =>
                        actionRefundPublishSuccess(action.refundPublish)
                    ),
                    defaultCatchError()
                )
            }
        )
    );

export const deleteRefund = (
    action$: Observable<AnyAction>
): Observable<AnyAction> =>
    action$.pipe(
        ofType(ActionRefundDeleteType),
        flatMap(
            (action): Observable<AnyAction> => {
                return RefundClient.deleteQueuedRefund(action.refundId).pipe(
                    map(() =>
                        actionRefundDeleteSuccess(action.refundId)
                    ),
                    defaultCatchError()
                )
            }
        )
    );

export const refundEpics = combineEpics(
    loadRefundsEpic,
    publishRefund,
    deleteRefund
);