import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { filter, flatMap, map, withLatestFrom } from "rxjs/operators";
import { AnyAction } from "redux";
import {
    ACTION_VATCODE_GET,
    actionVatCodeGetSuccess,
    ACTION_LOAD_VATCODES,
    actionLoadVatCodesSuccess
} from "./vatCode-actions";
import { defaultCatchError } from "../../redux/app/app-epics";
import VatCodeClient from "clients/vatcode-client";

export const getVatCode = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    filter(x => x.type === ACTION_VATCODE_GET),
    flatMap(
      (action): Observable<AnyAction> =>
        VatCodeClient.getVatCode(action.id).pipe(
          map(vatCodes => actionVatCodeGetSuccess(vatCodes)),
          defaultCatchError()
        )
    )
    );

export const loadVatCodesEpic = (
    action$: Observable<AnyAction>,
    state$: Observable<any>
    ) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_VATCODES),
        withLatestFrom(state$),
        flatMap(
        ([action, state]): Observable<AnyAction> =>
          VatCodeClient.getAllVatCodes().pipe(
            map(vatCodes => actionLoadVatCodesSuccess(vatCodes)),
            defaultCatchError()
            )
        )
    );
    };
  
export const vatCodeEpics = combineEpics(
    getVatCode,
    loadVatCodesEpic
);