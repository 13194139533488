import { makeStyles, Theme } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    tableHead: {
        fontWeight: 500,
        border: "none",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75rem",
        lineHeight: "1.3125rem",
        padding: "14px 20px 8px 16px",
    },
    checkBoxCell: {
        width: "25px",
    },
    invoiceRow: {
        border: "none",
        overflow: "word-wrap",
        padding: "10px",
    },
    cellPadding: {
        padding: "10px",
    },
    cellEmpty: {
        borderBottom: "0",
        background: "#e0e0e0",
        padding: "1px 0 0 0",
    },
    checkbox: {
        padding: 0,
    },
    h3: {
        paddingLeft: "15px",
    },
    lineH: {
        fontWeight: 600,
    },
    accentBG: {
        backgroundColor: "#f7feff",
    },
    invoiceGroupEnd: {
        borderTop: "2px solid #e0e0e0",
    },
    refund: {
        backgroundColor: "#edd8d7",
    },
    markedForRefund: {
        backgroundColor: "#fff2e5",
    },
    refundLines: {
        backgroundColor: "#edd8d7",
    },
    quantityInput: {
        width: "45px",
        height: "24px",
        fontSize: "14px",
        padding: "5px",
        textAlign: "center",
        fontWeight: 500,
        border: "2px solid #0c7182",
        lineHeight: "24px",
    },
    contractsTitle: {
        padding: theme.spacing(2),
        background: "white",
        width: "100%",
        display: "inline-block",
        boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    },
    alignRight: {
        textAlign: "right",
    },
}))
