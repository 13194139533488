import { BillingConfiguration } from "models/billing-configuration"
import { AnyAction } from "redux"

export const ACTION_BILLING_CONFIG_GET = "ACTION_BILLING_CONFIG_GET"
export const ACTION_BILLING_CONFIG_GET_SUCCESS = "ACTION_BILLING_CONFIG_GET_SUCCESS"

export const actionBillingConfigGet = (): AnyAction => {
    return {
        type: ACTION_BILLING_CONFIG_GET,
    }
}

export const actionBillingConfigGetSuccess = (billingConfig: BillingConfiguration): AnyAction => {
    return {
        type: ACTION_BILLING_CONFIG_GET_SUCCESS,
        billingConfig,
    }
}
