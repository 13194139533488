import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LayoutDefault from "common/layout/layout-default"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_PRODUCTS,
    TEXT_FILTER,
    TEXT_FILTER_PRODUCT_PLACEHOLDER,
    TEXT_ADD_PRODUCT,
} from "../../utils/translations/keys"
import Navigation from "common/navigation/navigation"
import { RouteComponentProps } from "@reach/router"
import { ProductTable } from "./product-table"
import { makeStyles, Theme, TextField, InputAdornment, IconButton, Button } from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import { includes, filter } from "lodash"
import { AppState } from "../../redux/app/app-store"
import { actionLoadProducts, actionSetFilter } from "../../redux/product/product-actions"
import TitleToolbar from "../../common/title-toolbar/title-toolbar"
import { navigate } from "@reach/router"
import InvoiceUpdateProgressNotification from "common/notifications/invoice-update-progress"
import {
    TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_1,
    TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_2,
} from "common/notifications/translations/keys"
import { formatString } from "utils/general-utils"
import BillingJobClient from "clients/billing-job-client"
import { actionLoadInvoiceUpdateProgress } from "../../redux/billing-job/billing-job-actions"

const useStyles = makeStyles((theme: Theme) => ({
    productFilter: {
        margin: theme.spacing(3, 0, 4, 0),
    },
}))

const ProductPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const filterText = useSelector((state: AppState) => state.products.filter) || ""
    const products = useSelector((state: AppState) => state.products.items) || {}
    const tenantConfigs = useSelector((state: AppState) => state.config.tenantConfig);
    const invoiceUpdateResponse = useSelector((state: AppState) => state.invoiceUpdateState.invoiceUpdateState)

    const filteredProducts = filterText
        ? filter(products, el => {
              return (
                  includes(el.name.toLowerCase(), filterText.toLowerCase()) ||
                  includes(el.description.toLowerCase(), filterText.toLowerCase())
              )
          })
        : products

    const setFilterText = (filter: any) => {
        dispatch(actionSetFilter(filter))
    }


    useEffect(() => {
        dispatch(actionLoadInvoiceUpdateProgress())
        dispatch(actionLoadProducts())    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateInvoices = async () => {
        await BillingJobClient.updateInvoices({}).toPromise()
        dispatch(actionLoadInvoiceUpdateProgress())
    }

    const addNewProduct = () => {
        navigate("/products/add")
    }

    const toolbar = (
        <TitleToolbar title={tr(TEXT_PRODUCTS)} enableGoBack={false}>
            <Button variant="outlined" color="inherit" onClick={addNewProduct}>
                {tr(TEXT_ADD_PRODUCT)}
            </Button>
        </TitleToolbar>
    )
    
    return (
        <LayoutDefault navigation={<Navigation />} toolbar={toolbar} showMunicipalities={true}>
            {tenantConfigs?.isInvoiceUpdateJobEnabled && invoiceUpdateResponse?.hasProductUpdates && <InvoiceUpdateProgressNotification invoiceUpdateResponse={invoiceUpdateResponse} updateInvoices={updateInvoices}
                notificationTexts={[
                    formatString(
                        tr(TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_1),
                        invoiceUpdateResponse?.updatedProductCount
                    ),
                    tr(TEXT_PRODUCTS_INVOICE_UPDATE_NOTIFICATION_PART_2),
                ]}
            ></InvoiceUpdateProgressNotification>}
            <TextField
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                label={tr(TEXT_FILTER)}
                placeholder={tr(TEXT_FILTER_PRODUCT_PLACEHOLDER)}
                margin="normal"
                variant="outlined"
                className={styles.productFilter}
                InputProps={{
                    endAdornment: filterText && (
                        <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setFilterText("")}>
                                <Clear fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <ProductTable products={filteredProducts} showProductChanged={tenantConfigs?.isInvoiceUpdateJobEnabled} />
        </LayoutDefault>
    )
}

export { ProductPage }
