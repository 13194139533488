import { PeriodConfig } from "models/period-config";
import { InvoicingSystemType, TenantConfig } from "models/tenant-config";
import { Observable } from "rxjs";
import { apiUrl, httpGet, httpPut2 } from "services/httpService";

export default class ConfigClient {
  public static getTenantConfig(): Observable<TenantConfig> {
    return httpGet<TenantConfig>(apiUrl(`/Config/TenantConfig`));
  }
  public static updateTenantConfig(tenantConfig: TenantConfig): Observable<TenantConfig> {
    return httpPut2<TenantConfig>(apiUrl(`/Config/TenantConfig`), tenantConfig);
  }
  public static getPeriodConfigurations(): Observable<PeriodConfig[]> {
    return httpGet<PeriodConfig[]>(apiUrl(`/Config/PeriodConfigurations`));
  }
  public static updatePeriodConfiguration(periodConfig: PeriodConfig): Observable<void> {
    return httpPut2(apiUrl(`/Config/PeriodConfiguration`), periodConfig);
  }
  public static getSystemConfigurationTemplate(invoicingSystemType: InvoicingSystemType): Observable<string> {
    return httpGet<string>(apiUrl(`/Config/${invoicingSystemType}/GetTemplate`));
  }
}