import { Button, makeStyles, Theme, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        fileName:{
            padding: 10
        },
        button: {
            width: 210
        }
    }
    
})

export const FileSelection: any = props => {

    const localStyles = useStyles()

    const { onSetInputValue, fileName, styles, buttonText, id } = props
    return (
        <div className={localStyles.container}>
            <div>
                <input
                    accept=".csv"
                    className={styles.input}
                    id={id}
                    type="file"
                    onChange={onSetInputValue}
                    //ref={uploadRef}
                    title=""
                    value=""
                />
                <label htmlFor={id}>
                    <Button variant="contained" color="primary" component="span" className={localStyles.button}>
                        {buttonText}
                    </Button>
                </label>
            </div>
            <div className={localStyles.fileName}>
                <Typography>{fileName}</Typography>
            </div>
        </div>
    )
}
