import { includes } from 'lodash'

/**
 * Removes primitive values from array
 *
 * @example arrayRemove([1, 2, 3, 4, 5], [3, 2]) // [1, 4, 5]
 */
export const arrayRemove = (array: any[], itemsToRemove: any[]): any[] => {
    return array.filter(item => !includes(itemsToRemove, item))
}
