import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { Provider } from 'react-redux'
import { createAppStore } from './redux/app/app-store';

export const store = createAppStore()

const render = (AppComponent: React.ComponentType) => {
    return ReactDOM.render(
        <Provider store={store}>
            <AppComponent />
        </Provider>,
        document.getElementById('root'),
    )
}

render(App)
