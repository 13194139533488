import { KomtekUpload, KomtekUploadStatus } from "models/komtek-upload"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost } from "services/httpService"

export default class KomtekClient {
    public static upload(request: any): Observable<string> {
        return httpPost<string>(apiUrl(`/import/upload-file`), request)
    }

    public static import(request: KomtekUpload): Observable<KomtekUploadStatus> {
        return httpPost<KomtekUploadStatus>(apiUrl("/import/import-komtek"), request)
    }

    public static getStatus(): Observable<KomtekUploadStatus> {
        return httpGet<KomtekUploadStatus>(apiUrl("/import/import-status"))
    }
}
