import { combineEpics, ofType } from "redux-observable"
import { Observable } from "rxjs"
import { filter, flatMap, map, withLatestFrom, mergeMap } from "rxjs/operators"
import { AnyAction } from "redux"
import {
    actionProductGetSuccess,
    ACTION_PRODUCT_GET,
    ACTION_LOAD_PRODUCTS,
    actionLoadProductsSuccess,
    ACTION_NAVIGATE_TO_PRODUCT,
} from "./product-actions"
import { Product } from "models/product"
import { mapProduct } from "utils"
import ProductClient from "clients/product-client"
import { defaultCatchError } from "../../redux/app/app-epics"
import { navigate } from "@reach/router"
import { EMPTY, of, from } from "rxjs"

export const getProduct = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        filter(x => x.type === ACTION_PRODUCT_GET),
        flatMap(
            (action): Observable<AnyAction> =>
                ProductClient.getProduct(action.productId).pipe(
                    map((product: Product) => actionProductGetSuccess(mapProduct(product))),
                    defaultCatchError()
                )
        )
    )

export const loadProductsEpic = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_LOAD_PRODUCTS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                ProductClient.getProducts().pipe(
                    map(products => actionLoadProductsSuccess(products)),
                    defaultCatchError()
                )
        )
    )
}

export const navigateToProduct = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        ofType(ACTION_NAVIGATE_TO_PRODUCT),
        flatMap(action => {
            navigate(`/products/${action.productId}`, { replace: true })
            return EMPTY
        })
    )

export const productEpics = combineEpics(getProduct, loadProductsEpic, navigateToProduct)
