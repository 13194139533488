import { FormControlLabel, Grid, Radio } from "@material-ui/core"
import React from "react"
import { Field, FieldArray } from "redux-form"
import {
    TEXT_BUILDING_NUMBER,
    TEXT_CONFIGURATION,
    TEXT_CUSTOM_VALUE,
    TEXT_EMPTY,
    TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH,
    TEXT_SERIAL_NUMBER,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"
import { KomtekFieldConfigType } from "models/tenant-config"

const renderFieldConfig = inputData => {
    const { fieldWidth, formValues, renderRadioGroup, editMode, renderCheckbox } = inputData

    return inputData.fields.map((fieldConfig, index) => {
        return (
            <React.Fragment key={fieldConfig}>
                <Grid item xs={fieldWidth}>
                    <>
                        <h3>
                            {formValues.komtekFileConfiguration.fieldConfigurations[index].fieldName}{" "}
                            {tr(TEXT_CONFIGURATION)}
                        </h3>
                        <Field name={`${fieldConfig}.fieldConfigType`} component={renderRadioGroup}>
                            <FormControlLabel
                                label={tr(TEXT_EMPTY)}
                                control={<Radio />}
                                value={KomtekFieldConfigType.Default}
                                disabled={!editMode}
                            ></FormControlLabel>
                            <FormControlLabel
                                label={tr(TEXT_BUILDING_NUMBER)}
                                control={<Radio />}
                                value={KomtekFieldConfigType.BuildingNumber}
                                disabled={!editMode}
                            ></FormControlLabel>
                            <FormControlLabel
                                label={tr(TEXT_SERIAL_NUMBER)}
                                control={<Radio />}
                                value={KomtekFieldConfigType.SerialNumber}
                                disabled={!editMode}
                            ></FormControlLabel>
                            <FormControlLabel
                                label={tr(TEXT_CUSTOM_VALUE)}
                                control={<Radio />}
                                value={KomtekFieldConfigType.CustomValue}
                                disabled={!editMode}
                            ></FormControlLabel>
                        </Field>
                    </>
                </Grid>
                <Grid item xs={8}></Grid>
                {(formValues.komtekFileConfiguration.fieldConfigurations[index].fieldConfigType ===
                    KomtekFieldConfigType.CustomValue ||
                    formValues.komtekFileConfiguration.fieldConfigurations[index].fieldConfigType ===
                        KomtekFieldConfigType.SerialNumber) && (
                    <>
                        <Grid item xs={fieldWidth}>
                            {formValues.komtekFileConfiguration.fieldConfigurations[index].fieldConfigType ===
                                KomtekFieldConfigType.CustomValue && (
                                <>
                                    <Field
                                        editMode={
                                            formValues.komtekFileConfiguration.fieldConfigurations[index]
                                                .fieldConfigType === KomtekFieldConfigType.CustomValue
                                        }
                                        name={`${fieldConfig}.customValue`}
                                        component={BillingTextFieldAdapter}
                                    ></Field>
                                </>
                            )}
                            {formValues.komtekFileConfiguration.fieldConfigurations[index].fieldConfigType ===
                                KomtekFieldConfigType.SerialNumber && (
                                <>
                                    <Field
                                        editMode={editMode}
                                        name={`${fieldConfig}.overrideDefault`}
                                        component={renderCheckbox}
                                        label={tr(TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH)}
                                    />
                                    {formValues.komtekFileConfiguration.fieldConfigurations[index].overrideDefault && (
                                        <Field
                                            editMode={
                                                formValues.komtekFileConfiguration.fieldConfigurations[index]
                                                    .overrideDefault
                                            }
                                            name={`${fieldConfig}.defaultOverrideValue`}
                                            component={BillingTextFieldAdapter}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={8}></Grid>
                    </>
                )}
            </React.Fragment>
        )
    })
}

export const KomtekFieldConfiguration = props => {
    const { fieldWidth, editMode, renderRadioGroup, formValues, renderCheckbox } = props
    return (
        <>
            <Grid container spacing={4} direction="row">
                <FieldArray
                    name="komtekFileConfiguration.fieldConfigurations"
                    component={renderFieldConfig}
                    fieldWidth={fieldWidth}
                    formValues={formValues}
                    renderRadioGroup={renderRadioGroup}
                    renderCheckbox={renderCheckbox}
                    editMode={editMode}
                ></FieldArray>
            </Grid>
        </>
    )
}
