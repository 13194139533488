import config from "config"
import { en } from "./languages/en"
import { nb } from "./languages/nb"
import { en as enRefundTranslations } from "../../pages/refund-page/translations/en"
import { nb as nbRefundTranslations } from "../../pages/refund-page/translations/nb"
import { nb as nbInvoiceFileTranslations } from "../../pages/dashboard-page/translations/nb"
import { en as enInvoiceFileTranslations } from "../../pages/dashboard-page/translations/en"
import { en as enNotificationTranslations } from "../../common/notifications/translations/en"
import { nb as nbNotificationTranslations } from "../../common/notifications/translations/nb"
import { en as enProductTranslations } from "../../pages/product-page/translations/en"
import { nb as nbProductTranslations } from "../../pages/product-page/translations/nb"

import { Language } from "shared-components/src/settings/language/language.enum"

export const strings: any = {}

strings[Language.English] = Object.assign(
    {},
    en,
    enRefundTranslations,
    enInvoiceFileTranslations,
    enNotificationTranslations,
    enProductTranslations
)
strings[Language.Norwegian] = Object.assign(
    {},
    nb,
    nbRefundTranslations,
    nbInvoiceFileTranslations,
    nbNotificationTranslations,
    nbProductTranslations
)

const getLang = (): string => {
    const lang = localStorage.getItem(config.localStorageLangKey)
    return lang !== null ? lang : config.language
}

export const tr = (key: string, params?: Array<any>): string => {
    const lang = getLang()

    try {
        const translatedString = strings[lang][key] || ""
        // eslint-disable-next-line
        return eval("`" + translatedString + "`")
    } catch (e) {
        console.log("translation error", { key, params })
    }

    return ""
}
