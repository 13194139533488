import React, { useState, useEffect } from "react";
import { tr } from "../../utils/translations/translate";
import {
  TEXT_PREVIOUS_VERSIONS,
  TEXT_VERSION,
  TEXT_NAME,
  TEXT_VALID_FROM,
  TEXT_VALID_TO,
  TEXT_PRICE,
  TEXT_NOT_ANY
} from "../../utils/translations/keys";
import {
  makeStyles,
  Theme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { DateTime } from "luxon";
import Spinner from "common/spinner";
import ProductClient from "clients/product-client";
import { ShortDateFormat } from "common/constants/dateFormats";
import { useSelector } from "react-redux";
import { AppState } from "redux/app/app-store";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2)
  },
  table: {
    marginTop: theme.spacing(3)
  },
  tableHead: {
    fontWeight: 600
  },
  previousVersionHeader : {
    '&:first-letter' : {
      textTransform: 'capitalize'
    }
  }
}));

type ProductHistoryProps = {
  productId: string;
};

const ProductHistory: React.FC<ProductHistoryProps> = ({ productId }) => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const product = useSelector((state: AppState) => state.products.items.find(el => el.id === productId))
  
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      if(productId){
        const versions = await ProductClient.getVersions(productId).toPromise();
        setData(versions);
      }
      setIsLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (isLoading) {
    return (
      <Paper className={styles.paper}>
        <Spinner/>
      </Paper>
    );
  }

  return (
    <Paper className={styles.paper}>
      {!isLoading && (!data || data.length === 0) ? (
        <h3>{tr(TEXT_NOT_ANY)} {tr(TEXT_PREVIOUS_VERSIONS)}</h3>
      ) : (
        <>
          <h3 className={styles.previousVersionHeader}>{(tr(TEXT_PREVIOUS_VERSIONS))}</h3>
          <Table className={styles.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_VERSION)}
                </TableCell>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_VALID_FROM)}
                </TableCell>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_VALID_TO)}
                </TableCell>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_NAME)}
                </TableCell>
                <TableCell className={styles.tableHead} align="right">
                  {tr(TEXT_PRICE)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.revision}>
                  <TableCell>{row.revision}</TableCell>
                  <TableCell>
                    {DateTime.fromISO(row.validFrom).toFormat(ShortDateFormat)}
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(row.validTo).toFormat(ShortDateFormat)}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Paper>
  );
};

export { ProductHistory };
