import * as keys from './keys'
export const nb: { [index: string]: string } = {}

nb[keys.TEXT_FILTER_REFUNDS_PLACEHOLDER] = 'Filtrer etter kunde, saksnummer, adresse';
nb[keys.TEXT_REFUNDS] = 'Kreditnota';
nb[keys.TEXT_REFUND_LINE_SELECTED] = 'valgte';
nb[keys.TEXT_FILTER] = 'Filtrer';
nb[keys.TEXT_REFUNDS_PUBLISH] = 'send valgte til utbetaling';
nb[keys.TEXT_REFUNDS_LINE_SELECTED] = 'valgte';
nb[keys.TEXT_PUBLISH_REFUNDS] = 'Send til utbetaling';
nb[keys.TEXT_PUBLISH_SELECTED_REFUNDS] = ' Send valgte til utbetaling';
nb[keys.TEXT_REFUNDS_DELETE] = 'Slett valgte';
nb[keys.TEXT_REFUNDS_OK] = 'Ok';
nb[keys.TEXT_REFUNDS_DATE_CREATED] = 'Dato opprettet';
nb[keys.TEXT_REFUNDS_ORDERNUMBER] = 'Kreditnota nummer';
nb[keys.TEXT_REFUNDS_IDENTIFICATION] = 'Gnr/Bnr/Fnr/Snr';
nb[keys.TEXT_REFUNDS_ADDRESS] = 'Adresse';
nb[keys.TEXT_REFUNDS_APPARTMENT_NUMBER] = 'Leilighetsnummer';
nb[keys.TEXT_REFUNDS_CUSTOMER_ID] = 'Kundeid';
nb[keys.TEXT_REFUNDS_CUSTOMER_NAME] = 'Kunde';
nb[keys.TEXT_REFUNDS_AMOUNT] = 'Sum';
nb[keys.TEXT_REFUNDS_CASE_NUMBER] = 'Tilsyn';
nb[keys.TEXT_REFUNDS_DELETE_DIALOG_TITLE] = 'Slett utbetaling'
nb[keys.TEXT_REFUNDS_TOTAL_AMOUNT] = 'Sum alle linjer'