export const getFractionString = (numerator: number, denominator: number) => {
    if (numerator === 0 || denominator === 0) {
        return "0"
    }

    if (Math.abs(numerator / denominator - Math.floor(numerator / denominator)) < Number.EPSILON) {
        return (numerator / denominator).toString()
    }
    return `${numerator}/${denominator}`
}
