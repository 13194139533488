import { Field, reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import * as React from 'react'
import { actionAuthLogin } from '../../redux/auth/auth-actions'
import { LoginRequest } from '../../models/auth-types'
import { Button, Grid, makeStyles, Theme, Paper, Divider } from '@material-ui/core'
import { CubitTextFieldAdapter } from 'shared-components/src/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { tr } from 'utils/translations/translate'
import { TEXT_LOGIN, TEXT_PASSWORD, TEXT_E_MAIL, TEXT_WELCOME_TO, TEXT_CUBIT_BILLING } from 'utils/translations/keys'
import { grey } from '@material-ui/core/colors'
import { RouteComponentProps } from '@reach/router'

type FormProps = {
    handleSubmit: any
}
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: `radial-gradient(circle at top, ${theme.palette.primary.main} 35%, #0c7282 150%)`,
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%',
        minHeight: '100%',
        overflow: 'auto'
    },
    mainGrid: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        padding: theme.spacing(2),
    },

    welcomeTo: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.h4.fontSize,
        fontWeight: 300,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h5.fontSize,
        },
    },
    appName: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'uppercase',
        letterSpacing: '2px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },

    loginContainer: {
        width: '100%',
        maxWidth: '444px',
        padding: theme.spacing(0, 4, 4, 4),
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2, 2, 2),
        },
    },

    loginTitle: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 300,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },

    loginDivider: {
        margin: theme.spacing(0, -4),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, -2),
        },
    },

    filledInputRoot: {
        '& .MuiInputBase-root': {
            borderRadius: 0,
            border: `1px solid ${grey[400]}`,
        },
    },

    loginButtonRoot: {
        width: '100%',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 300,
    },
    centerAlignFlexItem: {
        margin: 'auto',
    },
}))

const Form = (props: FormProps) => {
    const { handleSubmit } = props
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <Grid container className={styles.mainGrid}>
                <Grid item className={styles.centerAlignFlexItem}>
                    <Grid container justify="center" alignContent="center">
                        <Grid item xs={12} className={styles.welcomeTo}>
                            {tr(TEXT_WELCOME_TO)}
                        </Grid>
                        <Grid item xs={12} className={styles.appName}>
                            {tr(TEXT_CUBIT_BILLING)}
                        </Grid>
                        <Grid item>
                            <Paper className={styles.loginContainer} elevation={20}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} justify="center" alignContent="center">
                                        <Grid item className={styles.loginTitle}>
                                            {tr(TEXT_LOGIN)}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={4} justify="center" alignContent="center">
                                        <Grid item xs={12}>
                                            <Divider className={styles.loginDivider}></Divider>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                name="email"
                                                label={tr(TEXT_E_MAIL)}
                                                variant="filled"
                                                autoFocus={true}
                                                classes={{ root: styles.filledInputRoot }}
                                                component={CubitTextFieldAdapter}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="password"
                                                label={tr(TEXT_PASSWORD)}
                                                variant="filled"
                                                classes={{ root: styles.filledInputRoot }}
                                                component={CubitTextFieldAdapter}
                                                type="password"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                classes={{ root: styles.loginButtonRoot }}
                                            >
                                                {tr(TEXT_LOGIN)}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const LoginForm = reduxForm({
    form: 'LoginForm',
})(Form)

export const LoginPage: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch()
    const onSubmit = (data: LoginRequest) => dispatch(actionAuthLogin(data))
    return <LoginForm onSubmit={onSubmit} />
}
