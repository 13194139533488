import LayoutDefault from "common/layout/layout-default";
import Navigation from "common/navigation/navigation";
import Spinner from "common/spinner";
import { TenantConfig } from "models/tenant-config";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/app/app-store";
import { actionTenantConfigSave } from "../../redux/config/config-action";
import { TenantConfigBlock } from "./tenant-config-block";
import { PeriodYearsBlock } from "./period-years-block";
import TitleToolbar from "common/title-toolbar/title-toolbar";
import { tr } from "utils/translations/translate";

export const ConfigPage = () => {

  const dispatch = useDispatch();
  const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig);

  const toolbar = (
    <TitleToolbar enableGoBack={false}
      title={ `${tr("TEXT_TENANT_CONFIGURATION")}`}
    ></TitleToolbar>
  );

  return (
    <LayoutDefault navigation={<Navigation />} toolbar={toolbar} showMunicipalities={true}>
      {tenantConfig &&
        <TenantConfigBlock initialValues={tenantConfig} onSubmit={(data: any) => dispatch(actionTenantConfigSave(data as TenantConfig))}></TenantConfigBlock>
      }
      {!tenantConfig &&
        <Spinner />
      }
      <PeriodYearsBlock></PeriodYearsBlock>
    </LayoutDefault>
  );
}