export const TEXT_FIXED_PRICE = "FIXED_PRICE"
export const TEXT_PER_KM = "PER_KM"
export const TEXT_PER_TIME = "PER_TIME"
export const TEXT_PER_OTHER = "PER_OTHER"
export const TEXT_10_MINUTES = "TEXT_10_MINUTES"
export const TEXT_15_MINUTES = "TEXT_15_MINUTES"
export const TEXT_30_MINUTES = "TEXT_30_MINUTES"
export const TEXT_ONE_HOUR = "TEXT_ONE_HOUR"
export const TEXT_PRICE_TYPE = "TEXT_PRICE_TYPE"
export const TEXT_PRICE_PER_TIME_RESOLUTION = "TEXT_PRICE_PER_TIME_RESOLUTION"
export const TEXT_OTHER_PRICE_TYPE_VALUE = "TEXT_OTHER_PRICE_TYPE_VALUE"
export const TEXT_CHANGES_SAVED_SUCCESSFULLY = "TEXT_CHANGES_SAVED_SUCCESSFULLY"
