import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, makeStyles, Theme, Typography, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DashboardClient from "clients/dashboard-client";
import Spinner from "common/spinner";
import { InvoicePeriodStatistic } from "models/invoice-period-statistic";
import moment from "moment";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { tr } from "utils/translations/translate";
import { isCurrentPeriod } from "./dashboard-helper";
import { ProductTable } from "./period-products-table";
import { AppState } from "redux/app/app-store";
import { useSelector } from "react-redux";
import { TEXT_GENERATE_DIFF_FILE } from "utils/translations/keys";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      margin: "8px",
    },
    header: {
      padding: "0 62px 0 24px"
    },
    detailsHeader: {
      paddingRight: "38px"
    },
    numericColumn: {
      textAlign: "right"
    },
    expansionPanel: {
      borderRadius: "0!important",
      boxShadow: "none",
      background: "transparent",
      margin: "6px 0 0 0",
      "&::before": {
        display: "none"
      },
      "&.Mui-expanded": {
        margin: "6px 0 0 0"
      },
      "& > .MuiExpansionPanelSummary-root": {
        background: "white",
        "&.Mui-expanded": {
          minHeight: "initial",
          margin: "initial"
        },
        "& > .MuiExpansionPanelSummary-content.Mui-expanded": {
          margin: 0
        }
      },
      "& > .MuiCollapse-container": {
        marginTop: "2px",
        background: "white"
      }
    },
    tableRow: {
      "&:last-child td": {
        borderBottom: 0
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    sendToErp: {
      marginTop: "1rem"
    },
    subtotals: {
      borderTop: "1px #f0f0f0 solid",
      paddingTop: "0.25rem",
      marginTop: "0.25rem"
    }
  };
});

export const Period: React.FC<{ 
  period: InvoicePeriodStatistic, 
  index: number, 
  handleSendToErpDialogOpen: any, 
  setIsLoading: any, 
  isResendEnabled?: boolean, 
  refresh: any, 
  isLoading: boolean,
  generateDiffFile: any }> = props => {
  
  const styles = useStyles();
    const {period, index, handleSendToErpDialogOpen, setIsLoading, isResendEnabled, refresh, isLoading, generateDiffFile } = props
    const isSplitProductsView = !!period.productsInvoiced && period.productsInvoiced.length > 0;
    const hasRefunds = !!period.productsRefunded && period.productsRefunded.length > 0;
    const showInvoiceSubtotals = true; //hasRefunds && (period.productsInvoiced || []).length > 1;
    const showRefundSubtotals = true; // (period.productsRefunded || []).length > 1;
    const downloadInvoiceSummary = async (statistic: InvoicePeriodStatistic) => {
      setIsLoading(true);
      const fileResult = await DashboardClient.getInvoiceSummary(statistic.year, statistic.period);
      saveAs(fileResult, `${statistic.year}-${statistic.period}_invoice_summary.xlsx`);
      setIsLoading(false);
    }

    const configuration = useSelector((state: AppState) => state.billingConfiguration.billingConfig)

    const isGetReportDisabled = (period: InvoicePeriodStatistic) => {
      return period.invoiceCount === 0;
    }

    const isSendToErpDisabled = (period: InvoicePeriodStatistic) => {
      return period.invoiceCount == null || period.invoiceCount === 0 || ((period.invoicesNotTransferred == null || period.invoicesNotTransferred === 0) && !isResendEnabled);
    }

    return (
      <ExpansionPanel className={styles.expansionPanel} defaultExpanded={isCurrentPeriod(period)} key={index}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={1}>
              {period.period}
            </Grid>
            <Grid item xs={1}>
              {!!period.startDate ? moment(period.startDate).format("DD.MM.yyyy") : '-'}
            </Grid>
            <Grid item xs={2}>
              {!!period.endDate ? moment(period.endDate).format("DD.MM.yyyy") : '-'}
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {period.invoiceCount}
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {period.invoiceNetTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {period.invoiceVatTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
            <Grid item xs={2} className={styles.numericColumn}>
              {period.invoiceTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })} kr
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {period != null && period.invoiceCount as number > 0 &&
            <div style={{ width: "100%" }}>
              {isSplitProductsView && <>
                <div className={`${hasRefunds ? "mb-3" : ""}`}>
                  <ProductTable products={period.productsInvoiced || []} isRefund={false} showSubtotals={showInvoiceSubtotals} /></div>
                {hasRefunds &&
                  <div><ProductTable products={period.productsRefunded || []} isRefund={true} showSubtotals={showRefundSubtotals} /></div>}
              </>}
              {!isSplitProductsView && <>
                <div><ProductTable products={period.products} isRefund={false} showSubtotals={false} /></div>
              </>}
              <Grid
                container
                justify="flex-end"
                className={styles.sendToErp}
                spacing={1}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {configuration.isDiffFilesEnabled &&
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => generateDiffFile(period)}>
                        {tr(TEXT_GENERATE_DIFF_FILE)}
                      </Button>
                    </Grid>}
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isGetReportDisabled(period)}
                        onClick={() => downloadInvoiceSummary(period)}
                      >
                        {tr("TEXT_DOWNLOAD_INVOICE_SUMMARY")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => refresh(period)}
                      >
                        {tr("TEXT_REFRESH_PERIOD_STATISTICS")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSendToErpDisabled(period)}
                        onClick={() => handleSendToErpDialogOpen(period)}>
                        {tr("TEXT_SEND_TO_ERP")} ({!props.isResendEnabled ? (period.invoicesNotTransferred ?? 0) : (period.invoiceCount ?? 0)})
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>}
          {period == null || (period.invoiceCount as number === 0 &&
            <div style={{ display: "flex", flexGrow: 4 }}>
              <Grid
                container
                justify="flex-end"
                className={styles.sendToErp}
                spacing={1}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => refresh(period)}
                      >
                        {tr("TEXT_REFRESH_PERIOD_STATISTICS")}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
}