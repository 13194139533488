import { combineEpics } from "redux-observable"
import { catchError, filter, flatMap, map, withLatestFrom } from "rxjs/operators"
import { AnyAction } from "redux"
import {
    ACTION_AUTH_INIT,
    ACTION_AUTH_LOGIN,
    ACTION_AUTH_LOGIN_SUCCESS,
    ACTION_AUTH_LOGOUT,
    actionAuthInitialized,
    actionAuthLoginError,
    actionAuthLoginSuccess,
} from "./auth-actions"
import { EMPTY, Observable, of } from "rxjs"
import { getSessionKey, removeSessionKey, setSessionKey } from "utils"
import { actionLoadProducts } from "../product/product-actions"
import { actionGetLastTestInfo } from "../contract/contract-actions"
import { navigate } from "@reach/router"
import { actionLoadRefunds } from "../refund/refund-actions"
import { actionLoadVatCodes } from "../vatCode/vatCode-actions"
import AuthClient from "clients/auth-client"
import { actionMunicipalityLoad } from "../../redux/municipality/municipality-actions"
import { removeMunicipality } from "utils/municipality/municipalityUtil"

export const authLoginEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN),
        flatMap(
            (action): Observable<AnyAction> =>
                AuthClient.login(action.request).pipe(
                    map(data => {
                        setSessionKey(data.session.sessionKey)
                        return actionAuthLoginSuccess(data)
                    }),
                    catchError(error => of(actionAuthLoginError()))
                )
        )
    )

export const authLoginSuccessEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN_SUCCESS),
        flatMap((action): Observable<AnyAction> => {
            return of(actionMunicipalityLoad())
        })
    )

export const authLogoutEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGOUT),
        flatMap((action): Observable<AnyAction> => {
            removeSessionKey()
            removeMunicipality()
            navigate("/")
            return EMPTY
        })
    )

export const authInitEpic = (action$: Observable<AnyAction>, state$: Observable<any>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_INIT),
        withLatestFrom(state$),
        flatMap(([action, state]): Observable<AnyAction> => {
            const sessionKey = getSessionKey()

            if (sessionKey === undefined) {
                return of(actionAuthInitialized())
            } else {
                return AuthClient.login({ sessionKey }).pipe(
                    map(data => actionAuthLoginSuccess(data)),
                    catchError(error => of(actionAuthInitialized()))
                )
            }
        })
    )

export const authEpics = combineEpics(authInitEpic, authLoginSuccessEpic, authLoginEpic, authLogoutEpic)
