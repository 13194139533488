import React, { useEffect, useState } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles,
    Theme,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    NativeSelect,
    Tooltip,
} from "@material-ui/core"
import { tr } from "utils/translations/translate"
import { InvoiceLine } from "models/invoice"
import { Address, Contract } from "models/contract"
import Spinner from "common/spinner"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import RefundClient from "clients/refund-client"
import WarningIcon from "@material-ui/icons/Warning"
import { getFractionString } from "utils/invoice/invoice-utils"
import { actionSetLastRefundedLines } from "../../redux/refund/refund-actions"
import classNames from "classnames"
import { toShortDate } from "utils/time/timeUtil"
import { RefundedInvoiceLine } from "models/selected-line-type"
import { getAddressFormatted } from "./contract-helper"

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        marginTop: theme.spacing(1),
    },
    tableHead: {
        fontWeight: 600,
        borderBottom: "none",
        paddingLeft: 0,
    },
    tableColumn: {
        borderBottom: "none",
        paddingLeft: 0,
        verticalAlign: "middle",
    },
    dialogTitle: {
        paddingBottom: "0",
    },
    titleCase: {
        textTransform: "capitalize",
    },
    invoiceReceiverSelect: {
        display: "inline-block",
        width: "300px",
        verticalAlign: "middle",
    },
    infoField: {
        lineHeight: "32px",
        verticalAlign: "middle",
        display: "inline-block",
        width: "128px",
    },
    alignRight: {
        textAlign: "right",
    },
    sharedLine: {
        backgroundColor: "#fff2e5",
    },
}))

type RefundDialogProps = {
    contract: Contract
    invoiceLines: InvoiceLine[]
    open: boolean
    handleOk: (andCopy: boolean) => void
    handleClose: () => void
}
export const RefundDialog: React.FC<RefundDialogProps> = props => {
    const { open, handleClose, handleOk, invoiceLines: lines, contract } = props

    const styles = useStyles()
    const products = useSelector((state: AppState) => state.products.items) || {}

    const [isRefundLoading, setIsRefundLoading] = useState(false)
    const [createCreditNoteDisabled, setCreateCreditNoteDisabled] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (open) setCreateCreditNoteDisabled(false)
    }, [open])

    const handleRunRefundOk = async (andCopy = false) => {
        setCreateCreditNoteDisabled(true)

        const selectedLines = lines.filter(x => x.isSelected)
        var linesToRefund = selectedLines.map(x => ({
            InvoiceId: x.invoiceId,
            LineNumber: x.lineNo,
            Quantity: x.quantity,
        }))

        if (!andCopy) {
            setIsRefundLoading(true)
            await RefundClient.refund({
                linesToRefund,
            })

            setIsRefundLoading(false)
        }

        dispatch(actionSetLastRefundedLines(selectedLines))

        handleOk(andCopy)
    }

    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle className={styles.dialogTitle}>{tr("TEXT_CREDIT_NOTE")}</DialogTitle>
            <DialogContent style={{ width: "80vw", height: "60vh" }}>
                {isRefundLoading && <Spinner />}
                {!isRefundLoading && (
                    <>
                        <div>
                            <strong className={styles.infoField}>{tr("TEXT_INVOICE_RECEIVER")}: </strong>
                            <span className={`${styles.titleCase}`}>
                                {contract.ownerFullName?.toLowerCase() ||
                                    contract.invoiceRecipientFullName?.toLowerCase()}
                            </span>
                        </div>
                        <div>
                            <strong className={styles.infoField}>{tr("TEXT_INVOICE_ADDRESS")}: </strong>
                            <span>{`${getAddressFormatted("-", contract.address)}`}</span>
                        </div>
                        <Table className={styles.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_TERMIN")}</TableCell>
                                    <TableCell className={styles.tableHead}></TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_CASE_NUMBER")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_INVOICE_DATE")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_PRODUCT")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_FRACTION")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_PRICE")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_MVA")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_TERM_SHARE")}</TableCell>
                                    <TableCell className={styles.tableHead}>{tr("TEXT_SUM")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lines.map(line => {
                                    const product = products.find(x => x.id === line.productId)
                                    const termShare =
                                        line.type === 3 || line.wasOneOfInvoice ? "1" : `1/${line.periodCount}`
                                    const activityNumber =
                                        line.relatedInvoices.length > 0 && line.caseNumber
                                            ? `${getFractionString(line.numerator, line.denominator)} - ${
                                                  line.caseNumber
                                              }`
                                            : line.caseNumber

                                    return (
                                        <TableRow key={line.id + line.lineNo}>
                                            <TableCell className={styles.tableColumn}>{line.period}</TableCell>
                                            <TableCell className={styles.tableColumn}>
                                                {line.relatedInvoices.length > 0 && (
                                                    <Tooltip
                                                        placement="left-start"
                                                        title={
                                                            <React.Fragment>
                                                                {getFractionString(line.numerator, line.denominator)}{" "}
                                                                {tr("TEXT_SHARED_INVOICE")}: <br />
                                                                <div style={{ whiteSpace: "pre-line" }}>
                                                                    {line.relatedInvoices
                                                                        .map(i => i.invoiceRecipient)
                                                                        .join("\n")}
                                                                </div>
                                                                {tr("TEXT_SHARED_REFUND_TXT")}
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <WarningIcon
                                                            style={{ color: "#aaa", cursor: "pointer" }}
                                                        ></WarningIcon>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell className={styles.tableColumn}>{activityNumber}</TableCell>
                                            <TableCell className={styles.tableColumn}>
                                                {toShortDate(line.createdDate) || "-"}
                                            </TableCell>
                                            <TableCell className={styles.tableColumn}>{product?.name || "-"}</TableCell>
                                            <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                                {getFractionString(line.numerator, line.denominator)}
                                            </TableCell>
                                            <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                                {line.price.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                            </TableCell>
                                            <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                                {line.mvaCode ? line.mvaCode + " %" : "-"}
                                            </TableCell>
                                            <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                                {termShare}
                                            </TableCell>
                                            <TableCell className={classNames(styles.tableColumn, styles.alignRight)}>
                                                {line.lineSum.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {tr("TEXT_CANCEL")}
                </Button>
                <Button
                    onClick={() => handleRunRefundOk()}
                    disabled={createCreditNoteDisabled}
                    variant="outlined"
                    color="primary"
                    autoFocus
                >
                    {tr("TEXT_CREATE_CREDIT_NOTE")}
                </Button>
                <Button
                    onClick={() => handleRunRefundOk(true)}
                    disabled={createCreditNoteDisabled}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    {tr("TEXT_CREATE_CREDIT_NOTE_AND_COPY")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
