import { MunicipalityState } from "models/municipality"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import { MUNICIPALITY_CHANGE, MUNICIPALITY_LOAD_SUCCESS } from "./municipality-actions"

type MunicipalityReducer = ActionReducer<MunicipalityState>

const loadMunicipalitySuccess: MunicipalityReducer = (state, action) => {
    return {
        ...state,
        items: action.municipalities,
        hasMunicipalities: action.municipalities !== undefined && action.municipalities.length > 0,
    }
}

const changeMunicipality: MunicipalityReducer = (state, action) => {
    return { ...state, selectedMunicipality: action.municipality }
}

const reducers: Reducers<MunicipalityState> = {
    [MUNICIPALITY_LOAD_SUCCESS]: loadMunicipalitySuccess,
    [MUNICIPALITY_CHANGE]: changeMunicipality,
}

const initialState = {
    items: [],
    selectedMunicipality: null,
    hasMunicipalities: false,
}

export const municipalityReducer = makeReducer<MunicipalityState>(reducers, initialState)
