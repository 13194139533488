export const TEXT_REFUNDS = 'Refunds';
export const TEXT_FILTER_REFUNDS_PLACEHOLDER = 'RefundFilterPlaceHolder';
export const TEXT_REFUND_LINE_SELECTED = 'RefundLineSelected';
export const TEXT_FILTER = 'RefundsFilterText';
export const TEXT_REFUNDS_PUBLISH = 'PublishRefunds';
export const TEXT_REFUNDS_LINE_SELECTED = 'RefundsLineSelected';
export const TEXT_PUBLISH_REFUNDS = 'PublishRefunds';
export const TEXT_PUBLISH_SELECTED_REFUNDS = 'PublishSelectedRefunds';
export const TEXT_REFUNDS_DELETE = 'DeleteRefunds';
export const TEXT_REFUNDS_OK = 'RefundsOk';
export const TEXT_REFUNDS_DATE_CREATED = 'RefundsDateCreated';
export const TEXT_REFUNDS_CASE_NUMBER = 'RefundsCaseNumber';
export const TEXT_REFUNDS_ORDERNUMBER = 'RefundsOrderNumber';
export const TEXT_REFUNDS_IDENTIFICATION = 'RefundsIdentification';
export const TEXT_REFUNDS_ADDRESS = 'RefundsAddress';
export const TEXT_REFUNDS_APPARTMENT_NUMBER = 'RefundAppartmentNumber';
export const TEXT_REFUNDS_CUSTOMER_ID = 'RefundCustomerId';
export const TEXT_REFUNDS_CUSTOMER_NAME = 'RefundCustomerName';
export const TEXT_REFUNDS_AMOUNT = 'RefundAmount';
export const TEXT_REFUNDS_TOTAL_AMOUNT = 'RefundsTotalAmount';
export const TEXT_REFUNDS_DELETE_DIALOG_TITLE = 'DeleteDialogTitle';