import React from 'react'

import styles from './cubit-table.module.css'
import './cubit-table-sticky-header.css'

import TableHead from '@material-ui/core/TableHead'

import TableSortLabel from '@material-ui/core/TableSortLabel'
import { CubitTableHeaderProps } from './cubit-table.types'
import { CubitCheckbox } from '../cubit-inputs/cubit-checkbox/cubit-checkbox'
import classnames from 'classnames'

const CubitTableHeader: React.FC<CubitTableHeaderProps> = props => {
    const { columns, select, sorting } = props

    const sortClickHandler = (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        sorting && sorting.onSort(event, id)
    }

    const renderSelectAllColumn = () =>
        select ? (
            <th className={styles.checkboxCell}>
                <CubitCheckbox
                    indeterminate={select.selectedCount > 0 && select.selectedCount < select.totalSelectableCount}
                    checked={select.selectedCount > 0 && select.selectedCount === select.totalSelectableCount}
                    onChange={select.onSelectAll}
                />
            </th>
        ) : null

    const renderDefinedColumns = () =>
        columns.map((column, index) => (
            <th
                key={index}
                className={classnames(styles.tableCell, styles.tableHeaderCell, column.headerClassName, styles.defaultPadding)}
                style={{ textAlign: column.align, width: column.width }}
            >
                {sorting && column.sortable !== false ? (
                    <TableSortLabel
                        active={sorting.by === column.key}
                        direction={sorting.direction}
                        onClick={sortClickHandler(column.key)}
                    >
                        {column.headerLabel}
                    </TableSortLabel>
                ) : (
                    column.headerLabel
                )}
            </th>
        ))

    const renderColumns = () => (
        <>
            {renderSelectAllColumn()}
            {renderDefinedColumns()}
        </>
    )

    return (
        <TableHead>
            <tr className={classnames(styles.tableHeaderRow, 'tableHeaderRowSticky')}>{renderColumns()}</tr>
        </TableHead>
    )
}

export { CubitTableHeader }
