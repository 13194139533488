import { applyMiddleware, combineReducers, compose, createStore, AnyAction } from "redux"
import { createEpicMiddleware } from "redux-observable"
import { appEpics } from "./app-epics"
import { navigationReducer, NavigationState } from "../../redux/navigation/navigation-reducer"
import { cubitTableReducer, CubitTableState } from "shared-components/src/cubit-table/cubit-table-reducer"
import { settingsReducer, SettingsState } from "shared-components/src/settings/settings-reducer"
//import { AppDataState, appReducer } from "./app-reducer"
import { ACTION_AUTH_LOGOUT } from "../../redux/auth/auth-actions"
import { reducer as formReducer } from "redux-form"
import { FormStateMap } from "models/form-state"
import { contractReducer, ContractState } from "../../redux/contract/contract-reducer"
import { RefundState, refundReducer } from "../../redux/refund/refund-reducer"
import { invoiceFileReducer, InvoiceFileState } from "../../redux/invoice-file/invoice-file-reducer"
import { vatCodeReducer, VatCodeState } from "../../redux/vatCode/vatCode-reducer"
import { cubitSnackbarReducer, CubitSnackbarState } from "../../redux/cubit-snackbar/cubit-snackbar-reducer"
import { authReducer, AuthState } from "../../redux/auth/auth-reducer"
import { productReducer, ProductState } from "../../redux/product/product-reducer"
import { configReducer, ConfigState } from "../config/config-reducer"
import { MunicipalityState } from "models/municipality"
import { municipalityReducer } from "../../redux/municipality/municipality-reducer"
import { InvoicePeriodStatisticState } from "models/invoice-period-statistic"
import { invoicePeriodStatisticReducer } from "../dashboard/period-statistics-reducer"
import { BillingConfigurationState } from "models/billing-configuration"
import { billingConfigReducer } from "../../redux/billing-config/billing-config-reducer"
import { InvoiceStatisticState } from "models/invoice-statistic"
import { invoiceStatisticReducer } from "../../redux/dashboard/invoice-statistics-reducers"
import { InvoiceUpdateState } from "models/billing-jobs"
import { billingJobReducer } from "../../redux/billing-job/billing-job-reducers"

export type AppState = {
    //appData: AppDataState;
    auth: AuthState
    navigation: NavigationState
    settings: SettingsState
    table: CubitTableState
    snackBar: CubitSnackbarState
    form: FormStateMap
    products: ProductState
    contracts: ContractState
    refunds: RefundState
    invoiceFiles: InvoiceFileState
    vatCodes: VatCodeState
    config: ConfigState
    municipalities: MunicipalityState
    invoicePeriodStatistics: InvoicePeriodStatisticState
    billingConfiguration: BillingConfigurationState
    invoiceStatistic: InvoiceStatisticState
    invoiceUpdateState: InvoiceUpdateState
}

const mainReducer = combineReducers<AppState>({
    //appData: appReducer,
    auth: authReducer,
    navigation: navigationReducer,
    settings: settingsReducer,
    table: cubitTableReducer,
    snackBar: cubitSnackbarReducer,
    form: formReducer,
    products: productReducer,
    contracts: contractReducer,
    refunds: refundReducer,
    invoiceFiles: invoiceFileReducer,
    vatCodes: vatCodeReducer,
    config: configReducer,
    municipalities: municipalityReducer,
    invoicePeriodStatistics: invoicePeriodStatisticReducer,
    billingConfiguration: billingConfigReducer,
    invoiceStatistic: invoiceStatisticReducer,
    invoiceUpdateState: billingJobReducer,
})

const rootReducer = (state: AppState, action: AnyAction) => {
    if (action.type === ACTION_AUTH_LOGOUT) {
        state = {} as AppState
    }
    return mainReducer(state, action)
}

const initialState = {}

export const createAppStore = () => {
    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const epicMiddleware = createEpicMiddleware()
    // @ts-ignore
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(epicMiddleware)))
    epicMiddleware.run(appEpics)
    return store
}
