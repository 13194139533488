import React, { useEffect, useRef, useState } from "react"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { useDispatch, useSelector, useStore } from "react-redux"
import { AppState } from "redux/app/app-store"
import { Municipality } from "models/municipality"
import { actionChangeMunicipality } from "../../redux/municipality/municipality-actions"
import ReceiptIcon from "@material-ui/icons/Receipt"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { tr } from 'utils/translations/translate'
import { TEXT_MUNICIPALITY } from "pages/dashboard-page/translations/keys"
import { actionClearContracts } from "../../redux/contract/contract-actions"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        paper: {
            marginRight: theme.spacing(2),
            
        },
        button: {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            width: "100%",
            textTransform: 'none',
        },
        buttonContents: {
            display: "flex",
            justifyContent: "space-between",
            width: "inherit"
        },
        menuPaper: {
            width: 300
        }
    })
)

export default function MunicipalityMenu() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const municipalities = useSelector((state: AppState) => state.municipalities.items)
    const selectedMunicipality = useSelector((state: AppState) => state.municipalities.selectedMunicipality)
    const hasMunicipalities = useSelector((state: AppState) => state.municipalities.hasMunicipalities)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    const handleMenuClick = (event: any) => {
        console.log(event.target.value)
        dispatch(actionChangeMunicipality(municipalities.find(x => parseInt(x.value) === event.target.value)))
        dispatch(actionClearContracts())
        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault()
            setOpen(false)
        }
    }

    const prevOpen = useRef(open)

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    return hasMunicipalities ? (
        <div>
            {selectedMunicipality && (
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button}
                    variant="outlined"
                >
                    <div className={classes.buttonContents}>
                        <ReceiptIcon></ReceiptIcon>
                        <Typography>({selectedMunicipality.value}) {selectedMunicipality.name} {tr(TEXT_MUNICIPALITY)}</Typography>
                        <ArrowDropDownIcon></ArrowDropDownIcon>
                    </div>
                </Button>
            )}

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start">
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper className={classes.menuPaper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {municipalities.map((item: Municipality, index: number) => (
                                        <MenuItem key={index} onClick={handleMenuClick} value={item.value}>
                                            ({item.value}) {item.name} {tr(TEXT_MUNICIPALITY)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    ) : (
        <></>
    )
}
