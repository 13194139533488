import { AnyAction } from "redux"
import { Observable } from "rxjs"
import { flatMap, map } from "rxjs/operators"
import { defaultCatchError } from "../app/app-epics"
import { ACTION_BILLING_CONFIG_GET, actionBillingConfigGetSuccess } from "./billing-config-action"
import { combineEpics, ofType } from "redux-observable"
import DashboardClient from "clients/dashboard-client"

export const getBillingConfig = (action$: Observable<AnyAction>): Observable<AnyAction> =>
    action$.pipe(
        ofType(ACTION_BILLING_CONFIG_GET),
        flatMap(
            (action): Observable<AnyAction> =>
                DashboardClient.getConfiguration().pipe(
                    map(billingConfig => actionBillingConfigGetSuccess(billingConfig)),
                    defaultCatchError()
                )
        )
    )

export const billingConfigEpics = combineEpics(getBillingConfig)
