import { AnyAction } from "redux"
import { TenantConfig } from "models/tenant-config"
import { PeriodConfig } from "models/period-config"

export const ACTION_TENANTCONFIG_GET = "ACTION_TENANTCONFIG_GET"
export const ACTION_TENANTCONFIG_GET_SUCCESS = "ACTION_TENANTCONFIG_GET_SUCCESS"
export const ACTION_TENANTCONFIG_SAVE = "ACTION_TENANTCONFIG_SAVE"
export const ACTION_TENANTCONFIG_SAVE_SUCCESS = "ACTION_TENANTCONFIG_SAVE_SUCCESS"

export const ACTION_PERIODCONFIGURATIONS_GET = "ACTION_PERIODCONFIGURATIONS_GET"
export const ACTION_PERIODCONFIGURATIONS_GET_SUCCESS = "ACTION_PERIODCONFIGURATIONS_GET_SUCCESS"
export const ACTION_INVOICESYSTEMTEMPLATE_GET = "ACTION_PERIODCONFIGURATIONS_GET"

export const actionTenantConfigGet = (): AnyAction => {
    return {
        type: ACTION_TENANTCONFIG_GET,
    }
}

export const actionTenantConfigGetSuccess = (tenantConfig: TenantConfig): AnyAction => {
    return {
        type: ACTION_TENANTCONFIG_GET_SUCCESS,
        tenantConfig,
    }
}

export const actionTenantConfigSave = (tenantConfig: TenantConfig): AnyAction => {
    return {
        type: ACTION_TENANTCONFIG_SAVE,
        tenantConfig,
    }
}

export const actionTenantConfigSaveSuccess = (tenantConfig: TenantConfig): AnyAction => {
    return {
        type: ACTION_TENANTCONFIG_SAVE_SUCCESS,
        tenantConfig,
    }
}

export const actionPeriodConfigurationsGet = (): AnyAction => {
    return {
        type: ACTION_PERIODCONFIGURATIONS_GET,
    }
}

export const actionPeriodConfigurationsGetSuccess = (periodConfigs: PeriodConfig[]): AnyAction => {
    return {
        type: ACTION_PERIODCONFIGURATIONS_GET_SUCCESS,
        periodConfigs,
    }
}
