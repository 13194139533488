import React, { useEffect } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import Info from '@material-ui/icons/Info'
import Close from '@material-ui/icons/Close'
import Warning from '@material-ui/icons/Warning'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { IconButton, makeStyles, Snackbar, SnackbarContent } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { CubitSnackbarState } from '../../redux/cubit-snackbar/cubit-snackbar-reducer'
import { amber, green } from '@material-ui/core/colors'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'left',
        paddingRight: 5,
        flexDirection: 'column'
    },
}))

const variantIcon: { [key: string]: React.ComponentType<SvgIconProps> } = {
    success: CheckCircle,
    warning: Warning,
    error: ErrorIcon,
    info: Info,
}

export const CubitSnackbarWrapper: React.FC<{ snackbar: CubitSnackbarState }> = props => {
    const { snackbar } = props
    const [open, setOpen] = React.useState(false)

    const variant = snackbar.variant || 'success';
    const styles = useStyles()
    const Icon = variantIcon[variant]

    useEffect(() => {
        if (snackbar.messages) {
            setOpen(true)
        }
    }, [snackbar])

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={variant === 'error' ? undefined : 3000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={styles[variant]}
                message={
                    <span className={styles.message}>
                        {snackbar.messages?.map(message =>
                            <span>{message}</span>
                        )}
                    </span>
                }
                action={[
                    <IconButton key="close" color="inherit" onClick={handleClose}>
                        <Close className={styles.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    )
}
