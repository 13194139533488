import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import { InputType } from '../input-type.enum'

export type CubitTextFieldAdapterProps = { type: InputType.TextField } // type props are required to differenciate between different field adapters

const CubitTextFieldAdapter: React.FC<any> = (props: any) => {
    const {
        className,
        input: { name, value, onChange, onBlur, onFocus },
        options,
        formcontrolprops,
        multiline,
        customOnBlur,
        meta: { touched, error },
        ...rest
    } = props
    
    return (
        <FormControl>
            <FormGroup row>
                <TextField
                    className={className}
                    multiline={multiline}
                    error={touched && error ? true : false}
                    helperText={touched && error}
                    {...rest}
                    name={name}
                    onChange={(event: any) => onChange(event.target.value)}
                    onFocus={onFocus}
                    onBlur={(e:any) => {
                        if(customOnBlur) {
                            customOnBlur()
                        }
                        onBlur(e)
                    }}
                    value={value}
                />
            </FormGroup>
        </FormControl>
    )
}

export { CubitTextFieldAdapter }
