import { InvoiceUpdateState, JobStatus } from "models/billing-jobs"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import { ACTION_LOAD_INVOICE_UPDATE_PROGRESS_SUCCESS } from "./billing-job-actions"

type BillingJobReducer = ActionReducer<InvoiceUpdateState>

const loadInvoiceUpdateProgressSuccess: BillingJobReducer = (state, action) => {
    return {
        ...state,
        invoiceUpdateState: action.InvoiceUpdateProgress,
    }
}

const reducers: Reducers<InvoiceUpdateState> = {
    [ACTION_LOAD_INVOICE_UPDATE_PROGRESS_SUCCESS]: loadInvoiceUpdateProgressSuccess,
}

const initialState = {
    invoiceUpdateState: {
        hasUpdates: false,
        hasProductUpdates: false,
        hasPeriodUpdates: false,
        updatedProductCount: 0,
        updatedEntityTypes: [],
        billingJobState: {
            id: "",
            startTime: undefined,
            endTime: undefined,
            jobStatus: JobStatus.Unknown,
            progress: 0,
            errorMessage: undefined,
        },
    },
}

export const billingJobReducer = makeReducer<InvoiceUpdateState>(reducers, initialState)
