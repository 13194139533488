import config from '../../config'
import { Language } from '../../settings/language/language.enum'

const langMap: { [key: string]: string } = {
    [Language.Norwegian]: 'nb-no',
    [Language.English]: 'en-gb',
}

export const formatNumber = (number: number, maximumFractionDigits = 2): string => {
    const lang = localStorage.getItem(config.localStorageLangKey) || config.language

    return new Intl.NumberFormat(langMap[lang], { style: 'decimal', maximumFractionDigits }).format(number)
}

export const simpleFormatNumber = (number: number, maximumFractionDigits = 2): string => {
    return new Intl.NumberFormat('US', { style: 'decimal', maximumFractionDigits }).format(number)
}
