import React from "react";
import { RefundLine } from "models/refund";
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, makeStyles, Theme, Checkbox, Tooltip } from "@material-ui/core";
import { RefundSelectedLineType } from "./refund-page";
import { DateTime } from "luxon";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WarningIcon from '@material-ui/icons/Warning';
import { TEXT_REFUNDS_DATE_CREATED, TEXT_REFUNDS_ORDERNUMBER, TEXT_REFUNDS_IDENTIFICATION, TEXT_REFUNDS_ADDRESS, TEXT_REFUNDS_APPARTMENT_NUMBER, TEXT_REFUNDS_CUSTOMER_NAME, TEXT_REFUNDS_AMOUNT, TEXT_REFUNDS_CASE_NUMBER, TEXT_REFUNDS_TOTAL_AMOUNT } from "./translations/keys";
import { tr } from "utils/translations/translate";
import { actionRefundDelete } from "../../redux/refund/refund-actions";
import { useDispatch } from "react-redux";

type RefundTableProps = {
  refunds: RefundLine[],
  onCheckedChanged: any,
  onCheckAll: any,
  selectedLines: RefundSelectedLineType[],
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(1),
  },
  tableHead: {
    fontWeight: 600,
  },
  h3: {
    paddingLeft: "15px",
  },
  h4: {
    paddingLeft: "15px",
    marginBottom: "0",
  },
  cellCheckbox: {
    textAlign: "right",
  },
  totalRow: {
    borderTop: "2px solid #e0e0e0"
  },
  cellTotal: {
    fontWeight: 600,
    border: "0px"
  }
}));

export const RefundTable: React.FC<RefundTableProps> =
  ({ refunds, onCheckedChanged, onCheckAll, selectedLines }) => {

    const styles = useStyles();
    const dispatch = useDispatch();

    const isChecked = (row: any) => {
      const index = selectedLines.findIndex(x => x.InvoiceId === row.id)
      return index !== -1;
    }

    const isAllOrNoneChecked = () => {
      if (selectedLines.length === 0) {
        return true;
      }
      const foundUnSelected = refunds.findIndex(x => selectedLines.findIndex(y => y.InvoiceId === x.id) === -1);
      return foundUnSelected === -1;
    }

    const deleteRefund = (row: any) => {
      dispatch(actionRefundDelete(row.id));
    }

    const totalAmount = refunds.reduce((total, currentValue) => total = total + currentValue.amount,0)

    return (
      <>
        <Paper className={styles.paper}>
          <Table className={styles.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={`${styles.tableHead} ${styles.cellCheckbox}`}>
                  <Checkbox indeterminate={!isAllOrNoneChecked()} onChange={() => onCheckAll()}
                        checked={selectedLines.length > 0} />
                </TableCell>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_REFUNDS_DATE_CREATED)}
                </TableCell>
                <TableCell className={styles.tableHead}>
                  {tr(TEXT_REFUNDS_CASE_NUMBER)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_ORDERNUMBER)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_IDENTIFICATION)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_ADDRESS)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_APPARTMENT_NUMBER)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_CUSTOMER_NAME)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                  {tr(TEXT_REFUNDS_AMOUNT)}
                </TableCell>
                <TableCell className={styles.tableHead} >
                </TableCell>
                <TableCell className={styles.tableHead} >
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.map((row, i) => {
                return (
                  <TableRow key={row.id + row.lineNumber} >
                    <TableCell className={`${styles.cellCheckbox}`}>
                      <Checkbox onChange={() => onCheckedChanged(row)}
                        checked={isChecked(row)} />
                    </TableCell>
                    <TableCell>{DateTime.fromISO(row.refundDate?.toString())?.toISODate()}</TableCell>
                    <TableCell>{row.caseNumber}</TableCell>
                    <TableCell>{row.orderNumber}</TableCell>
                    <TableCell>{row.identification}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.appartmentNumber}</TableCell>
                    <TableCell>{row.customerName}</TableCell>
                    <TableCell>{row.amount} kr</TableCell>
                    <TableCell>{row.refundResult !== "Ok"  && <Tooltip title={row.message}><WarningIcon style={{ color: "red", cursor: "pointer" }}></WarningIcon></Tooltip>} </TableCell>
                    <TableCell>
                      <DeleteOutlineIcon style={{ color: "#aaa", cursor: "pointer" }} onClick={() => deleteRefund(row)}></DeleteOutlineIcon>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow className={styles.totalRow}>
              <TableCell className={styles.cellTotal} colSpan={7}>
                </TableCell>
                <TableCell className={`${styles.cellTotal}`}>
                  {tr(TEXT_REFUNDS_TOTAL_AMOUNT)}:
                </TableCell>
                <TableCell className={`${styles.cellTotal}`}>
                  {totalAmount.toLocaleString()} kr
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </>
    )
  }

