import { AnyAction } from "redux"
import { Observable, EMPTY, of } from "rxjs"
import { ACTION_LOAD_INVOICE_STATISTICS, actionLoadInvoiceStatisticsSuccess } from "./invoice-statistics-actions"
import { flatMap, map, withLatestFrom, mergeMap, concatMap, filter } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { defaultCatchError } from "../app/app-epics"
import DashboardClient from "clients/dashboard-client"

export const loadInvoiceStatistics = (action$: Observable<AnyAction>, state$: Observable<any>) => {
    return action$.pipe(
        ofType(ACTION_LOAD_INVOICE_STATISTICS),
        withLatestFrom(state$),
        flatMap(
            ([action, state]): Observable<AnyAction> =>
                DashboardClient.getInvoices().pipe(
                    map(result => actionLoadInvoiceStatisticsSuccess(result)),
                    defaultCatchError()
                )
        )
    )
}

export const invoiceStatisticsEpics = combineEpics(loadInvoiceStatistics)
