import React, { useState, useEffect } from "react";
import { tr } from "../../utils/translations/translate";
import { TEXT_DESCRIPTION, TEXT_NAME, TEXT_PRODUCT_NUMBER, TEXT_PRICE, TEXT_UPDATED } from "../../utils/translations/keys";
import {
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import { DateTime } from "luxon";
import Spinner from "common/spinner";
import ProductClient from "clients/product-client";
import { ShortDateTimeWithSecondsFormat } from "common/constants/dateFormats";
import { useSelector } from "react-redux";
import { AppState } from "redux/app/app-store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    marginBottom: "10px"
  },
  title: {
    fontSize: 14,
    fontWeight: 500
  },
  text: {
    fontSize: 13,
    fontWeight: 200,
    marginTop: "5px",
    width: "240px",
  },
  avatar: {
    backgroundColor: green[500]
  },
  cardContent: {
    padding: "0 15px 0 15px",
    paddingBottom: 0
  }
}));

type ProductAuditProps = {
  productId: string
}

const ProductAudit: React.FC<ProductAuditProps> = ({productId}) => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const product = useSelector((state: AppState) => state.products.items.find(el => el.id === productId))

  const getData = async () => {
    setIsLoading(true);

    if(productId){
      const items = await ProductClient.getAudit(productId).toPromise();
      setData(items);
    }
    
    setIsLoading(false);
  };

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (isLoading) {
    return <Spinner/>;
  }

  const translateColumnName = (columnName) => {
    switch (columnName) {
      case 'Name':
          return tr(TEXT_NAME)
      case 'Price':
         return tr(TEXT_PRICE)
      case 'Description':
        return tr(TEXT_DESCRIPTION)
      case 'ProductId':
        return tr(TEXT_PRODUCT_NUMBER)
      default:
        return columnName
    }
  }

  return (
    <>
      {data && data.map((item, index) => {
        return (
          <Card className={styles.root} variant="outlined" key={index}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={styles.avatar}>
                  <OfflineBoltIcon />
                </Avatar>
              }
              title={item.userName}
              subheader={DateTime.fromISO(item.auditDate).toFormat(ShortDateTimeWithSecondsFormat)}
            />
            <CardContent className={styles.cardContent}>
              {item.fields.map((field: any, index: number) => {
                return (
                  <div key={index}>
                    <Typography className={styles.title}>
                      {translateColumnName(field.columnName)} {tr(TEXT_UPDATED)}
                    </Typography>
                    <Typography className={styles.text} color="textSecondary">
                      {`${field.oldValue} -> ${field.newValue}`}
                    </Typography>
                  </div>
                );
              })}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export { ProductAudit };
