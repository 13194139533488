import { LOCAL_STORAGE_KEY_MUNICIPALITY } from "common/constants/constants"

function getMunicipality(): string | undefined {
    const municipality = localStorage.getItem(LOCAL_STORAGE_KEY_MUNICIPALITY)
    return municipality === null ? undefined : municipality
}

function removeMunicipality() {
    localStorage.removeItem(LOCAL_STORAGE_KEY_MUNICIPALITY)
}
function setMunicipality(municipality: string | undefined) {
    if (municipality) localStorage.setItem(LOCAL_STORAGE_KEY_MUNICIPALITY, municipality)
}

export { getMunicipality, removeMunicipality, setMunicipality }
