import { Audit } from "models/audit"
import { Product } from "models/product"
import { ProductVersion } from "models/product-version"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost, httpPut2 } from "services/httpService"
import { NewProductCreateResponse } from "../models/new-product-create-response"

export default class ProductClient {
    public static getProducts(): Observable<Product[]> {
        return httpGet<Product[]>(apiUrl(`/Products`))
    }
    public static getProduct(productId: string): Observable<Product> {
        return httpGet<Product>(apiUrl(`/Products/${productId}`))
    }
    public static addProduct(product: Product): Observable<NewProductCreateResponse> {
        return httpPost<NewProductCreateResponse>(apiUrl(`/Products`), product)
    }
    public static updateProduct(productId: string, product: Product): Observable<NewProductCreateResponse> {
        return httpPut2<NewProductCreateResponse>(apiUrl(`/Products/${productId}`), product)
    }
    public static getVersions(productId: string): Observable<ProductVersion[]> {
        return httpGet<ProductVersion[]>(apiUrl(`/Products/${productId}/versions`))
    }
    public static getAudit(productId: string): Observable<Audit[]> {
        return httpGet<Audit[]>(apiUrl(`/Products/${productId}/audit`))
    }
}
