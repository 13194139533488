import {
    Button,
    FormControl,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from "@material-ui/core"
import { CalendarToday } from "@material-ui/icons"
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import { navigate, RouteComponentProps } from "@reach/router"
import ConfigClient from "clients/config-client"
import LayoutDefault from "common/layout/layout-default"
import Navigation from "common/navigation/navigation"
import Spinner from "common/spinner"
import { InvoicePeriod, PeriodConfig } from "models/period-config"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionPeriodConfigurationsGet } from "../../redux/config/config-action"
import { tr } from "utils/translations/translate"
import { AppState } from "../../redux/app/app-store"

import Checkbox from "@material-ui/core/Checkbox"
import {
    TEXT_MAIN_PERIOD,
    TEXT_SELECT_PERIOD_COUNT,
    TEXT_PERIODCONFIG_TERMS,
    TEXT_TERMIN,
} from "utils/translations/keys"
import { formatString } from "utils/general-utils"
import InvoiceUpdateProgressNotification from "common/notifications/invoice-update-progress"
import {
    TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_1,
    TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_2,
} from "common/notifications/translations/keys"
import BillingJobClient from "clients/billing-job-client"
import { actionLoadInvoiceUpdateProgress } from "../../redux/billing-job/billing-job-actions"

const useStyles = makeStyles((theme: Theme) => {
    return {
        paper: {
            padding: "1rem",
        },
        strong: {
            fontWeight: 600,
        },
        title: {
            marginTop: 0,
            marginBottom: "0.5rem",
        },
        periodsSelector: {
            //marginLeft: "2rem",
            maxWidth: "190px",
            verticalAlign: "middle",
        },
        table: {
            marginTop: "0.75rem",
        },
        periodRow: {
            "& > th, & > td": {
                paddingLeft: 0,
            },
            "& > th": {
                fontWeight: 600,
            },
        },
        calendarIcon: {
            color: "#999",
            fontSize: "1.3rem",
        },
        buttons: {
            marginTop: theme.spacing(5),
        },
    }
})

export const PeriodConfigPage: React.FC<
    RouteComponentProps<{
        periodYear: string
    }>
> = props => {
    const styles = useStyles()
    const periodConfigs = useSelector((state: AppState) => state.config.periodConfigurations)
    const tenantConfigs = useSelector((state: AppState) => state.config.tenantConfig)
    const [periods, setPeriods] = useState<InvoicePeriod[]>([])
    const [hasChanged, setHasChanged] = useState(false)
    const [updateCount, setUpdateCount] = useState(0)

    const invoiceUpdateResponse = useSelector((state: AppState) => state.invoiceUpdateState.invoiceUpdateState)
    const dispatch = useDispatch()

    const periodYear = parseInt(props.periodYear!)

    const forceUpdate = () => {
        return setUpdateCount(updateCount => updateCount + 1)
    }

    const setPeriodsFromConfig = (periodYear: number) => {
        if (periodConfigs) {
            const periodConfig = periodConfigs.find(x => x.year === periodYear)
            if (periodConfig) {
                if (periodConfig.periods.length === 0) {
                    changePeriodCount(1)
                    setHasChanged(false)
                } else {
                    const periodArray = periodConfig.periods
                        .map(x => ({ ...x }))
                        .sort((a, b) => (a.periodNumber > b.periodNumber ? 1 : -1))
                    setPeriods(periodArray)
                }
            }
        }
    }

    
    useEffect(() => {
        const periodYear = parseInt(props.periodYear!)
        if (isNaN(periodYear)) {
            navigate("/config")
            return
        }
        setPeriodsFromConfig(periodYear)
    }, [periodConfigs])


    if (!periodConfigs) {
        return (
            <LayoutDefault navigation={<Navigation />}>
                <Spinner />
            </LayoutDefault>
        )
    }

    const changePeriodCount = (periodCount: number) => {
        if (periodCount > periods.length) {
            const addedPeriods = [...Array(periodCount - periods.length)].map(
                (_, index) => ({ periodNumber: periods.length + index + 1 } as InvoicePeriod)
            )
            setPeriods([...periods, ...addedPeriods])
        } else if (periodCount < periods.length) {
            const newPeriods = [...periods]
            newPeriods.splice(periodCount)
            setPeriods(newPeriods)
        }
        setHasChanged(true)
    }

    const save = async () => {
        const saveModel: PeriodConfig = {
            year: parseInt(props.periodYear!),
            periods,
            needsInvoicesUpdate: true,
        }
        await ConfigClient.updatePeriodConfiguration(saveModel).toPromise()
        dispatch(actionPeriodConfigurationsGet())
        setHasChanged(false)
        dispatch(actionLoadInvoiceUpdateProgress())
    }

    const cancel = () => {
        const periodYear = parseInt(props.periodYear!)
        setPeriodsFromConfig(periodYear)
        setHasChanged(false)
    }

    const onIsMainPeriodChanged = (period: InvoicePeriod, checked: boolean) => {
        period.isMainPeriod = checked
        setHasChanged(true)
        forceUpdate()
    }

    const periodConfig = periodConfigs.find(x => x.year === periodYear)

    if (!periodConfig) {
        return <span></span>
    }

    const updateInvoices = async () => {
        await BillingJobClient.updateInvoices({}).toPromise()
        dispatch(actionLoadInvoiceUpdateProgress())
    }

    const renderDatePicker = (periodDate: any, onChange: any) => {
        return (
            <KeyboardDatePicker
                format="dd.MM.yyyy"
                value={periodDate || null}
                onChange={onChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <CalendarToday className={styles.calendarIcon} />
                        </InputAdornment>
                    ),
                }}
                disabled={!periodConfig.isEditable}
            />
        )
    }

    return (
        <LayoutDefault navigation={<Navigation />}>
            {tenantConfigs?.isInvoiceUpdateJobEnabled && invoiceUpdateResponse?.hasPeriodUpdates && (
                <InvoiceUpdateProgressNotification
                    invoiceUpdateResponse={invoiceUpdateResponse}
                    updateInvoices={updateInvoices}
                    notificationTexts={[
                        tr(TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_1),
                        tr(TEXT_PERIODS_INVOICE_UPDATE_NOTIFICATION_PART_2),
                    ]}
                ></InvoiceUpdateProgressNotification>
            )}
            <Paper className={styles.paper}>
                <h3 className={styles.title}>{tr(TEXT_SELECT_PERIOD_COUNT)}</h3>
                <div>
                    <FormControl variant="standard" className={styles.periodsSelector}>
                        <Select
                            value={periods.length}
                            onChange={item => changePeriodCount(item.target.value as number)}
                            disabled={!periodConfig.isEditable}
                        >
                            {[...Array(20)].map((_, index) => (
                                <MenuItem key={index} value={index + 1}>
                                    <span>
                                        {index > 0
                                            ? formatString(tr(TEXT_PERIODCONFIG_TERMS), index + 1)
                                            : `${(index + 1).toString()} ${tr(TEXT_TERMIN).toLowerCase()}`}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <Table className={styles.table}>
                    <TableHead>
                        <TableRow className={styles.periodRow}>
                            <TableCell style={{ width: "7%" }}>{tr("TEXT_TERMIN")}</TableCell>
                            <TableCell>{tr("TEXT_START_DATE")}</TableCell>
                            <TableCell>{tr("TEXT_END_DATE")}</TableCell>
                            <TableCell>{tr("TEXT_LAST_POST_DATE")}</TableCell>
                            <TableCell align="center">{tr(TEXT_MAIN_PERIOD)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {periods.map(period => (
                            <TableRow key={period.periodNumber} className={styles.periodRow}>
                                <TableCell>{period.periodNumber}</TableCell>
                                <TableCell>
                                    {renderDatePicker(period.startDate, val => {
                                        period.startDate = val?.toFormat("yyyy-MM-dd") + "T00:00:00Z"
                                        setHasChanged(true)
                                        forceUpdate()
                                    })}
                                </TableCell>
                                <TableCell>
                                    {renderDatePicker(period.endDate, val => {
                                        period.endDate = val?.toFormat("yyyy-MM-dd") + "T00:00:00Z"
                                        setHasChanged(true)
                                        forceUpdate()
                                    })}
                                </TableCell>
                                <TableCell>
                                    {renderDatePicker(period.cutOffDate, val => {
                                        period.cutOffDate = val?.toFormat("yyyy-MM-dd")
                                        if (val) {
                                            period.cutOffDate += "T00:00:00Z"
                                        }
                                        setHasChanged(true)
                                        forceUpdate()
                                    })}
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={period.isMainPeriod}
                                        disabled={!periodConfig.isEditable}
                                        onChange={e => onIsMainPeriodChanged(period, e.target.checked)}
                                    ></Checkbox>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Grid container spacing={5} justify="flex-end" className={styles.buttons}>
                    <Grid item>
                        <Button onClick={cancel} disabled={!hasChanged || !periodConfig.isEditable}>
                            {tr("TEXT_CANCEL")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={save}
                            disabled={!hasChanged || !periodConfig.isEditable}
                        >
                            {tr("TEXT_SAVE")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </LayoutDefault>
    )
}
