import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'

const BillingDatePickerAdapter: React.FC<any> = (props: any) => {
    const {
        input,
        field,
        label,
        style,
        meta: { invalid, error },
        ...rest
    } = props

    const inputFormat = 'dd/MM/yyyy'
    const value = input.value || null

    return (
        <FormControl component="fieldset" style={style}>
            <KeyboardDatePicker
                margin="none"
                label={label}
                autoOk
                format={inputFormat}
                value={value}
                error={(input.value.invalid || (value && invalid)) && error ? true : false}
                helperText={(input.value.invalid || (value && invalid)) && error}
                onChange={(date: any) => input.onChange(date)}
                {...rest}
            />
        </FormControl>
    )
}

export default BillingDatePickerAdapter
