import { InvoiceStatistic } from "models/invoice-statistic";
import { Refund, RefundPublish } from "models/refund";
import { RequestResult } from "models/request-result";
import { RefundedInvoiceLine } from "models/selected-line-type";
import { Observable } from "rxjs";
import { apiUrl, httpDelete, httpGet, httpPost, httpPostAndFileResSimple } from "services/httpService";

export default class RefundClient {
  public static getQueuedRefunds(): Observable<Refund[]> {
    return httpGet<Refund[]>(apiUrl(`/Refunds`));
  }
  public static refund(request: { linesToRefund: RefundedInvoiceLine[] }): Promise<any> {
    return httpPostAndFileResSimple(apiUrl(`/Refunds`), request);
  }
  public static publish(request: RefundPublish): Observable<Refund[]> {
    return httpPost<Refund[]>(apiUrl(`/Refunds/publish`), request);
  }
  public static deleteQueuedRefund(id: string): Observable<void> {
    return httpDelete<void>(apiUrl(`/Refunds/${id}`));
  }
}