import { makeReducer } from "utils"
import { ActionReducer, Reducers } from "shared-components/src/utils/make-reducer"
import {
    ACTION_PRODUCT_GET,
    ACTION_PRODUCT_GET_SUCCESS,
    ACTION_PRODUCT_UPDATE,
    ACTION_PRODUCT_UPDATE_SUCCESS,
    ACTION_LOAD_PRODUCTS,
    ACTION_LOAD_PRODUCTS_SUCCESS,
    ACTION_UPDATE_FILTER,
    ACTION_PRODUCT_ADD_SUCCESS,
} from "./product-actions"
import { Product } from "models/product"
import { findIndex } from "lodash"

export type ProductState = {
    items: Product[]
    filter: string
}

type ProductReducer = ActionReducer<ProductState>

const getProduct: ProductReducer = state => state

const getProductSuccess: ProductReducer = (state, action) => {
    const index = findIndex(state.items, g => g.id === action.product.id)
    index < 0 ? state.items.push(action.product) : (state.items[index] = action.product)
    return state
}

const updateProduct: ProductReducer = state => state

const updateProductSuccess: ProductReducer = (state, action) => {
    const index = findIndex(state.items, g => g.id === action.product.id)
    index < 0 ? state.items.push(action.product) : (state.items[index] = action.product)
    return state
}

const addProductSuccess: ProductReducer = (state, action) => {
    const index = findIndex(state.items, g => g.id === action.product.id)
    index < 0 ? state.items.push(action.product) : (state.items[index] = action.product)
    return state
}

const loadProducts: ProductReducer = state => state

const loadProductsSuccess: ProductReducer = (state, action) => ({
    ...state,
    items: action.products,
})

const UpdateFilter: ProductReducer = (state, action) => ({
    ...state,
    filter: action.filter,
})

const reducers: Reducers<ProductState> = {
    [ACTION_PRODUCT_GET]: getProduct,
    [ACTION_PRODUCT_GET_SUCCESS]: getProductSuccess,
    [ACTION_PRODUCT_UPDATE]: updateProduct,
    [ACTION_PRODUCT_UPDATE_SUCCESS]: updateProductSuccess,
    [ACTION_LOAD_PRODUCTS]: loadProducts,
    [ACTION_LOAD_PRODUCTS_SUCCESS]: loadProductsSuccess,
    [ACTION_UPDATE_FILTER]: UpdateFilter,
    [ACTION_PRODUCT_ADD_SUCCESS]: addProductSuccess,
}

const initialState = { items: [], filter: "" }

export const productReducer = makeReducer<ProductState>(reducers, initialState)
