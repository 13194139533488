import * as keys from "./keys"
export const nb: { [index: string]: string } = {}

nb[keys.TEXT_FIXED_PRICE] = "Fastpris"
nb[keys.TEXT_PER_KM] = "Per km"
nb[keys.TEXT_PER_TIME] = "Per time"
nb[keys.TEXT_PER_OTHER] = "Definer annen enhet  "
nb[keys.TEXT_10_MINUTES] = "10 minutt"
nb[keys.TEXT_15_MINUTES] = "15 minutt"
nb[keys.TEXT_30_MINUTES] = "30 minutt"
nb[keys.TEXT_ONE_HOUR] = "1 time"
nb[keys.TEXT_PRICE_TYPE] = "Pristype"
nb[keys.TEXT_PRICE_PER_TIME_RESOLUTION] = "Tidsintervall"
nb[keys.TEXT_OTHER_PRICE_TYPE_VALUE] = "Enhet"
nb[keys.TEXT_CHANGES_SAVED_SUCCESSFULLY] = "Endringer er lagret"
