import { AnyAction } from "redux"
import { Contract, ContractListItem } from "models/contract"
import { ContractSearchItem } from "models/contract-search-item"
import { Invoice } from "models/invoice"
import { UpdateInvoiceIsSelectedType } from "./contract-reducer"

export const ACTION_LOAD_CONTRACTS = "ACTION_LOAD_CONTRACTS"
export const ACTION_LOAD_CONTRACTS_SUCCESS = "ACTION_LOAD_CONTRACTS_SUCCESS"
export const ACTION_SEARCH_CONTRACTS = "ACTION_SEARCH_CONTRACTS"
export const ACTION_SEARCH_CONTRACTS_SUCCESS = "ACTION_SEARCH_CONTRACTS_SUCCESS"
export const ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE = "ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE"
export const ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE_SUCCESS = "ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE_SUCCESS"
export const ACTION_CONTRACT_GET = "ACTION_CONTRACT_GET"
export const ACTION_CONTRACT_GET_SUCCESS = "ACTION_CONTRACT_GET_SUCCESS"
export const ACTION_GET_TEST_INFO = "ACTION_GET_TEST_INFO"
export const ACTION_GET_TEST_INFO_SUCCESS = "ACTION_GET_TEST_INFO_SUCCESS"
export const ACTION_UPDATE_CONTRACT_FILTER = "ACTION_UPDATE_CONTRACT_FILTER"
export const ACTION_UPDATE_CONTRACT_CUSTOM_TERMS = "ACTION_UPDATE_CONTRACT_CUSTOM_TERMS"
export const ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS = "ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS"
export const ACTION_UPDATE_CONTRACT_SERIAL_NUMBER = "ACTION_UPDATE_CONTRACT_SERIAL_NUMBER"
export const ACTION_UPDATE_CONTRACT_SERIAL_NUMBER_SUCCESS = "ACTION_UPDATE_CONTRACT_SERIAL_NUMBER_SUCCESS"
export const ACTION_CLEAR_CONTRACTS = "ACTION_CLEAR_CONTRACTS"
export const ACTION_LOAD_CONTRACT_INVOICES = "ACTION_LOAD_CONTRACT_INVOICES"
export const ACTION_LOAD_CONTRACT_INVOICES_SUCCESS = "ACTION_LOAD_CONTRACT_INVOICES_SUCCESS"
export const ACTION_UPDATE_CONTRACT_INVOICES_IS_SELECTED = "ACTION_UPDATE_CONTRACT_INVOICES_IS_SELECTED"

export const actionLoadContracts = (): AnyAction => ({
    type: ACTION_LOAD_CONTRACTS,
})

export const actionLoadContractsSuccess = (contracts: Contract[]): AnyAction => ({
    type: ACTION_LOAD_CONTRACTS_SUCCESS,
    contracts,
})

export const actionSearchContracts = (searchFilter: string): AnyAction => ({
    type: ACTION_SEARCH_CONTRACTS,
    searchFilter,
})

export const actionSearchContractsSuccess = (contracts: ContractListItem[]): AnyAction => ({
    type: ACTION_SEARCH_CONTRACTS_SUCCESS,
    contracts,
})

export const actionSearchContractsAutocomplete = (searchFilter: string): AnyAction => ({
    type: ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE,
    searchFilter,
})

export const actionSearchContractsAutocompleteSuccess = (results: ContractSearchItem[]): AnyAction => ({
    type: ACTION_SEARCH_CONTRACTS_AUTOCOMPLETE_SUCCESS,
    results,
})

export const actionContractGet = (contractId: string | undefined): AnyAction => {
    return {
        type: ACTION_CONTRACT_GET,
        contractId,
    }
}

export const actionContractGetSuccess = (contract: Contract): AnyAction => {
    return {
        type: ACTION_CONTRACT_GET_SUCCESS,
        contract,
    }
}

export const actionGetLastTestInfo = (): AnyAction => ({
    type: ACTION_GET_TEST_INFO,
})

export const actionGetLastTestInfoSuccess = (info: any): AnyAction => ({
    type: ACTION_GET_TEST_INFO_SUCCESS,
    info,
})

export const actionSetFilter = (filter: any): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_FILTER,
    filter,
})

export const actionUpdateContractCustomTerms = (
    contractId: any,
    useCustomTerms: boolean,
    periodType: string,
    selectedPeriods: number[]
): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_CUSTOM_TERMS,
    contractId,
    periodType,
    useCustomTerms,
    selectedPeriods,
})

export const actionUpdateContractCustomTermsSuccess = (contract: Contract): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_CUSTOM_TERMS_SUCCESS,
    contract,
})

export const actionUpdateContractSerialNumber = (contractId: string, serialNumber?: number): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_SERIAL_NUMBER,
    contractId,
    serialNumber,
})

export const actionUpdateContractSerialNumberSuccess = (serialNumber: string): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_SERIAL_NUMBER_SUCCESS,
    serialNumber,
})

export const actionClearContracts = (): AnyAction => ({
    type: ACTION_CLEAR_CONTRACTS,
})

export const actionLoadContractInvoices = (contractId: string | undefined): AnyAction => ({
    type: ACTION_LOAD_CONTRACT_INVOICES,
    contractId,
})

export const actionLoadContractInvoicesSuccess = (invoices: Invoice[]): AnyAction => ({
    type: ACTION_LOAD_CONTRACT_INVOICES_SUCCESS,
    invoices,
})

export const actionUpdateSelectedContractInvoices = (
    invoices: UpdateInvoiceIsSelectedType[],
    singleLineSelection: boolean
): AnyAction => ({
    type: ACTION_UPDATE_CONTRACT_INVOICES_IS_SELECTED,
    invoices,
    singleLineSelection,
})
