import { makeStyles, Paper, Theme } from "@material-ui/core";
import { AppState } from "../../redux/app/app-store";
import { InvoiceFile } from "models/invoice-file";
import React from "react";
import { useSelector } from "react-redux";
import { InvoiceFileTable } from "./invoice-file-table";
import { tr } from "../../utils/translations/translate";
import { LAST_SENT_FILES_HEADER } from "./translations/keys";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(1),
    width: "100%",
  },
  h2: {
    paddingLeft: "15px"
  },
  sent: {
    paddingLeft: "15px",
    marginTop: theme.spacing(3)
  }
}));

const InvoiceFileWidget: React.FC<any> = props => {

  const styles = useStyles();
  const invoiceFiles: InvoiceFile[] =
    useSelector((state: AppState) => state.invoiceFiles.items) || {};

  return (

    <Paper className={styles.paper}>
      <h2 className={styles.h2}>{tr(LAST_SENT_FILES_HEADER)}</h2>
      <InvoiceFileTable fileList={invoiceFiles} />
    </Paper>
  );
}

export { InvoiceFileWidget };
