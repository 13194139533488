import { makeReducer } from "utils";
import {
  ActionReducer,
  Reducers,
} from "shared-components/src/utils/make-reducer";
import {
  ActionLoadRefundsType,
  ActionLoadRefundsSuccessType,
  ActionRefundPublishSuccessType,
  ActionRefundDeleteType,
  ActionRefundDeleteSuccessType,
  ActionSetLastRefundedLines,
} from "./refund-actions";
import { Refund } from "models/refund";
import { InvoiceLine } from "models/invoice";

export type RefundState = {
  items: Refund[];
  lastRefundedLines?: InvoiceLine[];
};

type RefundReducer = ActionReducer<RefundState>;

const loadRefunds: RefundReducer = (state) => state;
const loadRefundsSuccess: RefundReducer = (state, action) => ({
  ...state,
  items: action.refunds,
});

const deleteRefunds: RefundReducer = (state) => state;

const deleteRefundsSuccess: RefundReducer = (state, action) => {

  let newItems = state.items.filter(refund => refund.id !== action.refundId);

  return {
    ...state,
    items: newItems
  }
};

const publishRefunds: RefundReducer = (state, action) => {

  let refundIds: string[] = action.refundPublish.publishRefundIds;

  let newItems = state.items.filter(refund => refundIds.findIndex(id => id === refund.id) === -1);
  return {
    ...state,
    items: newItems
  }
};

const setLastRefundedInvoiceLines: RefundReducer = (state, action) => ({
  ...state,
  lastRefundedLines: action.lastRefundedLines
});

const reducers: Reducers<RefundState> = {
  [ActionLoadRefundsType]: loadRefunds,
  [ActionLoadRefundsSuccessType]: loadRefundsSuccess,
  [ActionRefundDeleteType]: deleteRefunds,
  [ActionRefundDeleteSuccessType]: deleteRefundsSuccess,
  [ActionRefundPublishSuccessType]: publishRefunds,
  [ActionSetLastRefundedLines]: setLastRefundedInvoiceLines
};

const initialState = { items: [] };

export const refundReducer = makeReducer<RefundState>(reducers, initialState);
