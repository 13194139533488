import { BillingJobProgress, UpdateInvoicesRequest } from "models/billing-jobs"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost } from "services/httpService"

export default class BillingJobClient {
    public static updateInvoices(request: UpdateInvoicesRequest): Observable<void> {
        return httpPost<void>(apiUrl("/BillingJob/Start"), request)
    }

    public static getUpdateProgress(): Observable<BillingJobProgress> {
        return httpGet<BillingJobProgress>(apiUrl("/BillingJob/getProgress"))
    }
}
