import { InvoicePeriodStatisticState } from "models/invoice-period-statistic"
import { ActionReducer, makeReducer, Reducers } from "shared-components/src/utils/make-reducer"
import {
    ACTION_LOAD_PERIOD_STATISTICS_SUCCESS,
    ACTION_SET_IS_LOADING_IN_PROGRESS,
    ACTION_UPDATE_PERIOD_STATISTICS,
    ACTION_UPDATE_PERIOD_STATISTICS_SUCCESS,
} from "./period-statistics-actions"

type InvoicePeriodStatisticReducer = ActionReducer<InvoicePeriodStatisticState>

const loadInvoicePeriodStatisticsSuccess: InvoicePeriodStatisticReducer = (state, action) => {
    return {
        ...state,
        items: action.statistics,
    }
}

const updatePeriodStatisticsSuccess: InvoicePeriodStatisticReducer = (state, action) => {
    const index = state.items.findIndex(
        x => x.year === action.invoiceStatistic.year && x.period === action.invoiceStatistic.period
    )
    const newStatistics = [...state.items]
    newStatistics.splice(index, 1, action.invoiceStatistic)

    return {
        ...state,
        items: newStatistics,
        isUpdatingStatistics: false,
    }
}

const updatePeriodStatistics: InvoicePeriodStatisticReducer = (state, action) => {
    return {
        ...state,
        isUpdatingStatistics: true,
    }
}

const setIsLoadingInProgress: InvoicePeriodStatisticReducer = (state, action) => {
    return {
        ...state,
        isUpdatingStatistics: action.isInProgress,
    }
}

const reducers: Reducers<InvoicePeriodStatisticState> = {
    [ACTION_LOAD_PERIOD_STATISTICS_SUCCESS]: loadInvoicePeriodStatisticsSuccess,
    [ACTION_UPDATE_PERIOD_STATISTICS_SUCCESS]: updatePeriodStatisticsSuccess,
    [ACTION_UPDATE_PERIOD_STATISTICS]: updatePeriodStatistics,
    [ACTION_SET_IS_LOADING_IN_PROGRESS]: setIsLoadingInProgress,
}

const initialState = {
    items: [],
    isUpdatingStatistics: false,
}

export const invoicePeriodStatisticReducer = makeReducer<InvoicePeriodStatisticState>(reducers, initialState)
