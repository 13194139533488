export type BillingJobProgress = {
    billingJobState: BillingJob
    updatedEntityTypes: UpdatedEntityType[]
    updatedProductCount: number
    hasUpdates: boolean
    hasProductUpdates: boolean
    hasPeriodUpdates: boolean
}

export type BillingJob = {
    id: string
    startTime?: Date
    endTime?: Date
    jobStatus: JobStatus
    progress: number
    errorMessage?: string
}

export type InvoiceUpdateState = {
    invoiceUpdateState: BillingJobProgress
}

export enum JobStatus {
    Unknown = "Unknown",
    NotStarted = "NotStarted",
    Running = "Running",
    Finished = "Finished",
    Error = "Error",
}

export enum UpdatedEntityType {
    None = "None",
    Periods = "Periods",
    Products = "Products",
}

export type UpdateInvoicesRequest = {}
