import React from 'react'

export const Truncate: React.FC<{ maxWidth?: string }> = props => {
    const { maxWidth, children } = props

    return (
        <div
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxWidth || 'none',
            }}
        >
            {children}
        </div>
    )
}
