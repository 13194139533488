import { AnyAction } from "redux";
import { Refund, RefundPublish } from "models/refund";
import { InvoiceLine } from "models/invoice";

export const ActionLoadRefundsType = "ACTION_LOAD_REFUNDS";
export const ActionLoadRefundsSuccessType = "ACTION_LOAD_REFUNDS_SUCCESS";
export const ActionRefundPublishType = "ACTION_REFUND_PUBLISH";
export const ActionRefundPublishSuccessType = "ACTION_REFUND_PUBLISH_SUCCESS";
export const ActionRefundDeleteType = "ACTION_REFUND_DELETE";
export const ActionRefundDeleteSuccessType = "ACTION_REFUND_DELETE_SUCCESS";
export const ActionUpdateSelectedRefund = "UPDATE-SELECTED_REFUND";
export const ActionSetLastRefundedLines = "UPDATE_SET_LAST_REFUNDED_LINES";

export const actionLoadRefunds = (): AnyAction => ({
  type: ActionLoadRefundsType,
});

export const actionLoadRefundSuccess = (refunds: Refund[]): AnyAction => ({
  type: ActionLoadRefundsSuccessType,
  refunds: refunds,
});

export const actionRefundPublish = (refundPublish: RefundPublish): AnyAction => {
  return {
    type: ActionRefundPublishType,
    refundPublish,
  }
}

export const actionRefundPublishSuccess = (refundPublish: RefundPublish): AnyAction => {
  return {
    type: ActionRefundPublishSuccessType,
    refundPublish,
  }
}

export const actionRefundDelete = (refundId: string | undefined): AnyAction => {
  return {
    type: ActionRefundDeleteType,
    refundId,
  }
}

export const actionRefundDeleteSuccess = (refundId: string | undefined): AnyAction => {
  return {
    type: ActionRefundDeleteSuccessType,
    refundId,
  }
}

export const actionUpdateSelectedRefund = (refundId: string | undefined): AnyAction => {
  return {
    type: ActionUpdateSelectedRefund,
    refundId,
  }
}

export const actionSetLastRefundedLines = (lastRefundedLines: InvoiceLine[]): AnyAction => {
  return {
    type: ActionSetLastRefundedLines,
    lastRefundedLines
  }
}