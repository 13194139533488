import { VatCode } from "models/vatCode";
import { Observable } from "rxjs";
import { apiUrl, httpGet, httpPost } from "services/httpService";
import { NewVatCreateResponse } from "../models/new-vat-create-response";

export default class VatCodeClient {

  public static getAllVatCodes(): Observable<VatCode[]> {
    return httpGet<VatCode[]>(apiUrl(`/VatCode`));
  }

  public static getVatCode(id: string): Observable<VatCode> {
    return httpGet<VatCode>(apiUrl(`/VatCode/${id}`));
  }

  public static addVatCode(vat: VatCode): Observable<NewVatCreateResponse> {
    return httpPost<NewVatCreateResponse>(apiUrl(`/VatCode`), vat);
  }
}
