import React, { FC, useEffect, useRef, useState } from "react"
import LayoutDefault from "common/layout/layout-default"
import { tr } from "../../utils/translations/translate"
import { TEXT_CONTRACT, TEXT_FILTER, TEXT_FILTER_CONTRACT_PLACEHOLDER } from "../../utils/translations/keys"
import Navigation from "common/navigation/navigation"
import { RouteComponentProps } from "@reach/router"
import { ContractTable } from "./contract-table"
import { makeStyles, Theme, TextField, InputAdornment, IconButton } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../redux/app/app-store"
import TitleToolbar from "common/title-toolbar/title-toolbar"
import Spinner from "common/spinner"
import { actionClearContracts, actionSearchContracts, actionSearchContractsAutocomplete, actionSetFilter } from "../../redux/contract/contract-actions"
import moment from "moment"
import { Autocomplete } from "@material-ui/lab"
import { ContractSearchItem } from "models/contract-search-item"

const useStyles = makeStyles((theme: Theme) => ({
    contractFilter: {
        margin: theme.spacing(3, 0, 4, 0),
    },
    runTestButton: {
        margin: "0 20px 0 20px",
    },
    streetHousesCount: {
        position: "absolute",
        right: "1rem",
        color: "white",
        padding: "4px",
        textAlign: "center",
        width: "42px",
        borderRadius: "4px",
        background: "gray",
        display: "inline-block",
    },
}))

let lastAutoSearch = moment()

const ContractPage: FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const contracts = useSelector((state: AppState) => state.contracts.items) || {}
    const autoCompleteItems = useSelector((state: AppState) => state.contracts.autoCompleteContracts) || []
    const isLoadingAutocomplete = useSelector((state: AppState) => state.contracts.isLoadingAutocomplete)
    const filter = useSelector((state: AppState) => state.contracts.filter)
    const [autocompleteText, setAutocompleteText] = useState("")
    
    const updateFilterText = (val: string) => {
        lastAutoSearch = moment()
        setAutocompleteText(val)
        dispatch(actionSetFilter(val))
    }

    useEffect(() => {
        const autoCompleteTimeout = setTimeout(() => {
            if (moment() > lastAutoSearch.clone().add(500, "ms")) {
                doAutoComplete()
            }
        }, 501)

        return () => {
            clearTimeout(autoCompleteTimeout)
        }
    }, [autocompleteText])

    useEffect(() => {
      const close = document.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0]
          
        close.addEventListener("click", () => {
          setAutocompleteText("")
          dispatch(actionSetFilter(""))
          dispatch(actionClearContracts())
        })
      }, [])

    const doSearch = (filter: string) => {
        dispatch(actionSearchContracts(filter))
    }

    const doAutoComplete = () => {
        lastAutoSearch = moment()

        if (autocompleteText && autocompleteText.length > 1) {
            dispatch(actionSearchContractsAutocomplete(autocompleteText))
        }
    }

    const isLoading = useSelector((state: AppState) => state.contracts.isLoading) || false
    const toolbar = <TitleToolbar title={tr(TEXT_CONTRACT)} enableGoBack={false}></TitleToolbar>

    const getOptionLabel = option => {
        return option.value ? option.value : option
    }

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={toolbar} showMunicipalities={true}>
            <>
                <Autocomplete
                    freeSolo={true}
                    id="contractsFilter"
                    options={autoCompleteItems}
                    groupBy={option => tr(`TEXT_SEARCH_CATEGORY${option.category.toString()}`)}
                    getOptionLabel={option => getOptionLabel(option)}
                    style={{ width: "100%" }}
                    filterOptions={x => x}
                    inputValue={filter}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={tr(TEXT_FILTER_CONTRACT_PLACEHOLDER)}
                            placeholder={tr(TEXT_FILTER_CONTRACT_PLACEHOLDER)}
                            variant="outlined"
                            margin="normal"
                            className={styles.contractFilter}
                            onChange={e => updateFilterText(e.target.value)}
                        />
                    )}
                    renderOption={(option, { selected }) =>
                        isLoadingAutocomplete ? (
                            <Spinner />
                        ) : (
                            <React.Fragment>
                                {option.value}
                                {!!option.childValues && (
                                    <span className={styles.streetHousesCount}>{option.childValues.length}</span>
                                )}
                            </React.Fragment>
                        )
                    }
                    onChange={(event: any, option: ContractSearchItem | null) => {
                        if (option) {
                           doSearch(option.value ? option.value : option.toString());
                        }
                    }}
                />
                {isLoading ? <Spinner /> : contracts.length > 0 && <ContractTable contracts={contracts} />}
            </>
        </LayoutDefault>
    )
}

export { ContractPage }
